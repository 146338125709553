module.exports = {
  "FoodC_demand__Do_CKdn": "* 売り切れの商品を除く他の商品を購入",
  "FoodC_demand_Courier_Purchasing_eTAr": "「配達員購入代行」サービスについて",
  "FoodC_store_Store_Coupon_szoR": "店舗クーポン",
  "FoodC_sales_Confirmation_mTGd": "確認",
  "FoodC_demand__1_jqYB": "(1)アプリの案内に従ってお届け先住所、受取人、商品の詳細などの情報を入力しお支払いを完了すると、配達員がお客様のリクエストに従って指定、または付近のお店で商品を購入いたします。",
  "FoodC_education_1_Customers_Gpbb": "1. ご注文確定前に受取方法を選択できます。配達員は、お客様が選択した受取方法に従ってお届けします。",
  "FoodC_title_Upload_Image_VSGW": "画像をアップロードしてください",
  "FoodC_education__1_mJTG": "(1) 店舗がすでに注文受付を確定した場合、「注文キャンセル」機能でキャンセルについて店舗に相談できます。すでに注文引き渡し済みなどの理由で店舗がキャンセルに同意しなかった場合、無料で注文をキャンセルすることはできません。それでも注文をキャンセル、または商品の受取を拒否した場合は発生した費用はすべてお支払いいただきます。",
  "FoodC_order__2_UYRU": "（2） お支払いいただく注文金額は店舗の実売価格となります。表示価格が実売価格より低かった場合、追加での請求は致しません。表示価格が実売価格より高かった場合は、差額を返金いたします。",
  "FoodC_feedback_I_received_UNiy": "他の人の商品を受け取った",
  "FoodC_demand__2_ALrB": "(2) お支払いいただく注文金額は店舗の実売価格となります。表示価格が実売価格より低かった場合、追加での請求は致しません。表示価格が実売価格より高かった場合は、差額を返金いたします。",
  "FoodC_education_3_If_wduh": "3. 商品の問題、天候の問題など人為的なもの以外の原因で受取の問題が発生した場合は、プラットフォームが責任を負います。",
  "FoodC_seals_Outdoor_packaging_MGso": "商品受取時の包装状態",
  "FoodC_demand_2_How_DjaQ": "2. 配達員購入代行サービスの利用方法は？",
  "FoodC_RCOUPON_Coupon_reduction_PqSi": "特別割引クーポン",
  "FoodC_demand_3_What_zsYM": "3. 配達員購入代行の商品が売り切れだった場合は？",
  "FoodC_education_In_order_SRIb": "{{brand_name}}をご利用いただくお客様に、より良いご利用体験を提供するため、キャンセルに関するルールを設定させていただいております。",
  "FoodC_feedback_2_The_ntBT": "2. 全額払い戻しの場合、実際にお支払いいただいた金額を全額払い戻しいたします。ご注文時にご利用のクーポンはアカウントに返還されます。",
  "FoodC_sales_If_the_TsPm": "パッケージにテープや他の密封方法（ホチキスなど）がされていた場合、その止め具合を上記の選択肢から選択してください。この情報は店舗と配達員への事実確認にのみ使用されます。申立の結果には影響しません。",
  "FoodC_education__2_uVUK": "(2) 店舗の営業時間外、商品の在庫切れ、事故などの原因で店舗または配達員によって注文がキャンセルされた場合。ご注文代金はすべて払い戻しとなります。",
  "soda-d-i18n---没有更多了": "この他にはありません",
  "FoodC_demand__You_yjru": "※アプリ決済を選択された場合は、ご注文時に合計料金をお支払いいただきます。配達員はお店で商品を購入後、お届けに伺います。",
  "FoodOpD_status_Store_OCbV": "店舗",
  "FoodD_one_Please_try_VyNK": "しばらくしてから再度お試しください",
  "FoodC_demand__If_PNsp": "* 一つでも売り切れの商品があれば、すべての注文をキャンセル",
  "FoodC_Translation__brand_LAYp": "{{brand_name}}クーポン",
  "FoodC_demand__4_HJNa": "(4) 不正行為があった場合は厳正に対処いたします。",
  "sailing.c-api-intl.[提单页]_[平台券]": "DiDi Foodクーポン",
  "FoodC_demand__3_tQmA": "(3) 配達料金はアプリ画面の表示を基準とします。",
  "FoodC_education_3_Customers_Zoec": "3. 配達員から連絡が取りやすいよう、常に電話がつながる状態にしておいてください。",
  "FoodC_demand__In_XsxW": "* 売り切れ商品があった場合の返金方法は、上記規則に基づきます。",
  "FoodC_education__1_vwgm": "(1) 玄関先で受け取る",
  "FoodC_page_Contact_customer_rJbM": "カスタマーセンターへご連絡ください",
  "FoodC_education__1_qvVC": "(1) お客様が時間内に受け取らなかった",
  "FoodC_education_Meal_picking_ziun": "受取ルール",
  "FoodC_demand__1_EzSJ": "(1) 配達員が店頭で商品注文する前はキャンセル料不要です。",
  "FoodC_education_5_Cash_zBuY": "5. 現金支払いの場合は、十分な現金をご用意ください。",
  "sailing.c-api-intl.[提单页]_[商家券]": "店舗クーポン",
  "FoodC_feedback_Order_issues_wWOH": "お届けしたすべての商品が間違っていた場合",
  "FoodC_demand_4_Courier_ReDA": "4. 配達員購入代行の注文キャンセル規定",
  "FoodC_feedback_3_When_OcDb": "3. ご注文時に現金支払いを選択された場合、払戻が承認された後、払戻金額はクーポンの形でアカウントに返還されます。",
  "FoodC_description_Partial_meal_JWNU": "商品が足りなかった",
  "FoodC_Translation__unk_axrk": "{{brand_name}}クーポンを獲得しました！",
  "FoodC_demand__3_hmpb": "(3) 売り切れの際の返金規定",
  "FoodOpC_Notifications_Full_service_HBkd": "全てのサービス提供都市で使用可",
  "FoodC_education_1_If_iWDm": "1. お客様が上記のルールに沿ってご注文を受け取らなかったことによって、問題が発生した場合、まずお客様側の責任として対応します。具体的には、次のような場合があります（ただしこれらの例に限りません）。",
  "FoodC_demand_5_there_jISx": "５、購入代行店舗の情報についてご不明点がある場合は？",
  "FoodC_issues_Contact_customer_Anvy": "カスタマーセンターへ連絡",
  "FoodC_education_Principles_of_RKEk": "受取時の問題対応の原則",
  "FoodD_one_Try_again_qZka": "再試行",
  "FoodD_one_Service_not_eFvs": "サーバーに接続されていません",
  "FoodC_feedback_It_was_ZrMP": "受け取った全ての注文商品が間違っていた",
  "FoodC_education_Basic_requirements_hIbv": "ご注文受取の基本要件",
  "FoodC_demand__2_eSAk": "(2) 売り切れの場合、以下の対応方法からお選びいただけます。",
  "FoodC_description_Indicates_that_MWBQ": "配達員がご注文の商品を配達せず、何も受け取っていない場合",
  "FoodC_description_Indicates_that_fnET": "配達員から商品を受け取ったが、一部の商品が足りなかった場合",
  "FoodC_feedback_1_When_buWL": "1. 注文の一部が払い戻し対象の場合、対象商品に対して割引適用後の実際に支払った金額を払い戻しいたします。配達料金とチップは払い戻し対象外になります。",
  "FoodC_demand__If_qODk": "* 「売り切れの商品を除く他の商品を購入」を選択し、アプリ決済でお支払い済みの場合は、自動的に売り切れ商品の代金を返金いたします。",
  "FoodC_demand_If_you_SpKh": "購入代行のお店の店舗情報やメニューなどについてご不明点がある場合は、カスタマーセンターまでご連絡ください：  {{Customer_Service_Contact_Number}}",
  "FoodC_sales_Supplementary_information_ihyk": "追加情報",
  "FoodC_demand__3_cTVC": "(3) 店休、商品売り切れ、事故発生などの理由でお店または配達員がご注文をキャンセルした場合は全額返金いたします。",
  "FoodC_page_Sorry_the_tNDc": "申し訳ございませんが、操作中にエラーが検知されたため現在送信できません。カスタマーセンターにご連絡することをおすすめします。",
  "FoodC_education_In_the_nIgD": "以下のような注文キャンセルの場合は、お客様の責任となります。",
  "FoodC_demand__Let_qvly": "* まず配達員より連絡を受け、上記のどちらを選ぶか決める",
  "FoodC_education__2_KHhl": "(2) プラットフォームの規定時間内に受取地点に到着しなかった、お届け先住所の入力が間違っていた、受取番号が間違っていた、配達員の電話に応答しなかった、正当な理由なく商品の受け取りを拒否した場合は、ご注文代金をお支払いいただきます。",
  "FoodC_education_If_you_RTIV": "ご注文代金のお支払い完了後にキャンセルされる場合、以下のルールに従って対応いたします。",
  "FoodC_education_2_Receiving_gFZl": "2. お届け先住所、連絡先情報を正確に入力してください。",
  "FoodC_education__3_TDsa": "(3) お客様が商品の受取を拒否した",
  "FoodC_education_2_If_SoSk": "2. 配達員がプラットフォーム規定に従ってご注文のお届けを完了せず、それにより受取の問題が発生した場合は、まず配達員の責任として対応します。",
  "FoodC_education__unk_aOCD": "{{delivery_type}}プラットフォーム規則",
  "FoodC_demand_Purchasing_services_jshb": "配達員がお客様の代わりにお店で商品を購入し、お届けするサービスです。商品例：ピザ、ハンバーガー、紅茶、コーヒー等（実際の商品はアプリの表示をご確認ください）。配達員はご注文受付後、速やかに商品を購入しお届けに伺います。",
  "Global_Driver_Multiplier_new_interaction_Current_activities_vSRD": "このイベントはまだ開始されていません",
  "sailing.c-api-intl.[商家营销标签分类]_[菜品活动]": "商品割引キャンペーン",
  "FoodC_store_Store_voucher_NnMx": "店舗クーポンを獲得しました",
  "FoodC_education__2_jwSo": "(2) 玄関先に置く（備考欄に記入）",
  "sailing.c-api-intl.[商家营销标签分类]_[满X减Y]": "注文金額に応じた割引",
  "GDriver_5_Event_ended_gNeH": "このイベントは終了しています",
  "FoodB_optimization_See_more_rbCl": "詳細を見る",
  "FoodC_Translation_Sign_in_pljT": "ログインしてください",
  "FoodC_education_4_Customers_laxR": "4. 配達員が到着してから10分以内にご注文をお受け取り下さい。",
  "FoodC_demand__1_LhGa": "(1) アプリ上に掲載中の商品情報はDiDi Foodが独自調査したもので、一時的に売り切れていたり、提供終了していることもございます。これは通常、配達員が店舗到着後に判明いたします。その際、配達員はお客様が選択した方法に従って対応いたします。",
  "soda-b-homepage-i18n---登录": "ログイン",
  "sailing.act-api-intl.去使用": "使用する",
  "FoodC_title_Please_choose_yekD": "お届けした商品に問題があった場合",
  "FoodC_sales_Condition_of_lnwg": "商品受取時の封の状態（選択してください）",
  "FoodC_Translation_Successful_exchange_BVbd": "カートの{{coupon_name}}クーポン一覧よりご確認下さい",
  "FoodC_demand_1_What_aoLM": "1. 「配達員購入代行」サービスとは？",
  "FoodC_demand__You_bJcT": "※現金決済を選択された場合は、後払いとなります。商品を選択してご注文いただくと、配達員がお店で商品を購入いたします。料金はお届けの際に配達員に現金でお支払いください。",
  "FoodC_issues_Sorry_for_aHCW": "ご不便をおかけし大変申し訳ございません。カスタマーセンターが対応しますのでご連絡ください。",
  "FoodC_education__1_IIfh": "(1) 店舗の注文受付前に、注文詳細ページで「注文キャンセル」機能からキャンセルした場合",
  "FoodC_reasons_Enter_a_Pnzg": "具体的な理由を入力してください",
  "FoodC_sales_How_do_ylCn": "封の状態の判断方法",
  "FoodC_demand__2_TZJq": "(2) 購入後のキャンセルは、大幅な配達遅延がない限り全額お支払いいただきます。",
  "FoodC_education__4_QYXm": "(4) 現金支払い注文で、お客様が支払いを拒否した",
  "FoodC_education_In_the_MKeB": "以下のような注文キャンセルの場合は、お客様の責任になりません。",
  "FoodC_education__3_ltNM": "（3） 配達員の手配ができないなどプラットフォーム側の原因、または外部の原因により注文がキャンセルされた場合",
  "FoodC_education_In_order_AZCw": "{{brand_name}}をご利用いただくお客様に、より良いご利用体験を提供するため、受取に関するルールを設定させていただいております。ご注文の受取について問題が発生した場合は、以下のルールに従って対応いたします。",
  "FoodC_education__2_KZTZ": "(2) 配達員がお客様に連絡したが、連絡がつかなかった",
  "sailing.c-api-intl.推荐": "おすすめ順",
  "FoodC_education_Cancellation_Policy_fxrl": "キャンセルルール",
  "FoodC_description_The_entire_lTeJ": "商品が届かなかった",
  "您的订单还未完成，您可以联系骑手确认当前的具体配送位置。": "ご注文はまだ完了していません。配達員に連絡し現在置を確認してください。",
  "您所在的地区还未开通服务": "お客様の地域では現在ご利用いただけません",
  "可以把促销码换成钱吗？ ": "クーポンコードの現金化はできますか？",
  "不满意": "不満",
  "用户使用%s券应同时满足以下条件：": "%sクーポンをご使用の際は同時に以下の条件も満たしている必要があります。",
  "怎样才能获得%s券？": "%sクーポンを獲得するには？",
  "取消订单进度": "キャンセル申請状況",
  "4、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "4、以下のお客様都合によって発生した配達遅延や配達不能に関しては補填が適用されません。",
  "早间：%s-%s": "早朝：%s-%s",
  "跳过": "スキップ",
  "个人中心的“优惠券”": "DiDi Foodアカウントの「クーポン」。",
  "联系商家处理": "お店へ連絡",
  "用户的%s软件版本较低，可在升级%s软件版本后再进行支付。": "%sのバージョンが低すぎます。%sをアップデートすると支払いが可能になります。",
  "商家正在备餐": "調理中です",
  "1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "1、遅延補填は%sがお客様に提供しているサービスです。ご注文の商品（キャンセルした注文は除く）が予定配達時刻以内に配達されなかった場合、%sよりお詫びとしてクーポンを差し上げます。注文完了後×にてご確認できます。",
  "申请信息": "申請情報",
  "返回首页": "トップページへ戻る",
  "在极少数情况下，如果您对订单费用产生疑议，可以点击“帮助”联系我们，客服团队会尽快为您解答。": "お支払いに関するお問い合わせは、カスタマーセンターまでご連絡ください。",
  "我要注册！": "今すぐ登録",
  "你的位置还不在我们服务范围内。我们很快就会扩大我们的覆盖范围，之后你就可以使用%s啦。请点击下面的按钮，我们会在有服务之后通知你。": "お客様の地域は現在サービス対象エリア外です。サービスエリア拡大中ですので、%sがご利用できるまで今しばらくお待ちください。下部のボタンをクリックしていただければ、サービス開始後お知らせをお届けします。",
  "您对商家服务满意吗？": "お店と料理に満足していただけましたか？",
  "现金": "現金",
  "用户不符合%s券使用规则中列明的可以使用券的条件，应仔细阅读并保证符合优惠券注明的使用条件；": "%sクーポン使用規則に記載されているクーポン使用条件を満たしていません。クーポンに記されている使用条件をよくお読みになり、条件を満たしていることを確認してください。",
  "%s券上会注明“有效期”，该优惠券仅能在注明的有效期内使用，过期优惠券将自动失效不可用。": "%sクーポンには「有効期限」が記載されており、記載された有効期限内にのみご使用できます。期限を過ぎると自動的に無効になります。",
  "将赔付给您1张%s优惠券": "遅延補填として%sの配達料無料クーポンを1枚差し上げます",
  "1.店铺优惠指的是“满X减Y”活动。": "1. 店舗イベントは’注文金額に応じた割引’を指します",
  "满%s可用": "%s以上で適用",
  "订单": "合計金額",
  "店铺优惠说明": "店舗イベントについて",
  "订单取消成功": "注文はキャンセルされました",
  "目前没有限制。优惠券有使用限制，您可以查看下方优惠券规则，了解一天可以使用多少优惠券。": "現在は制限がありません。クーポンの使用制限に関しては、下記のクーポン規則にて一日に使用可能なクーポン枚数をご確認できます。",
  "一个用户一天内最多能够使用几张%s券？": "一人1日に%sクーポンを何枚まで使用することができますか？",
  "仅限%s使用": "%sに限り利用可",
  "您对商家服务的评价": "お店のサービスへの評価",
  "恶劣天气及运力紧张情况下，配送费根据实际情况略有浮动。": "天候や配達員の人数によって、配達料金が変動する場合があります。",
  "进度详情": "申請状況の詳細",
  "平台优惠": "キャンペーン割引",
  "使用规则": "注意事項",
  "2.“满X减Y”和“特价菜”、“优惠套餐”活动优惠可同享。": "2.「注文金額に応じた割引」、「スペシャルプライスメニュー」、「お得なセット」などのキャンペーンは併用できます。",
  "反馈超时": "申請期限が過ぎました",
  "促销码与促销活动相关，有有效期，超过有效期会兑换失败。": "クーポンコードやキャンペーンには有効期限があります、期限を過ぎると引換ができなくなります。",
  "可以用一个账号内的%s券为其他账号内的订单进行支付吗？": "%sクーポンを他のアカウントの注文で使うことはできますか？",
  "获得权益": "クーポンを獲得する",
  "我知道了": "閉じる",
  "查看已开通的区域": "配達エリアを閲覧する",
  "提交": "送信",
  "您的问题我们已经收到，将在48小时内给您反馈，并以短信/邮件通知您，请耐心等待。": "フィードバックを受け付けました。48時間以内に、ショートメッセージ/Eメールにて処理結果をお知らせします。今しばらくお待ちください。",
  "小费金额 = 餐品总价 * 小费比例，你可以点击去选择你的小费比例。（餐品总价不包含配送费和优惠券）": "チップ金額=商品合計金額*チップ率です。クリックしてチップ率を選択することができます。（商品合計金額には配送料やクーポン割引は含まれません）",
  "订单尚未完成": "ご注文は完了していません",
  "补充更多信息，以便快速处理（选填）": "詳細をご記入いただけますと、よりスムーズに対応できます（任意）",
  "3.同一商家，最多可以有3个“满X比索减Y比索”活动，自动为您选择优惠力度最大的折扣。": "3. お店では最大3段階の割引イベントを実施できます。割引額が最も大きいキャンペーンが自動的に適用されます。",
  "更多商家即将上线，点击“请通知我”商家上线时我们将第一时间通知你": "新しい店舗が間も無くオープンしますので、「通知を希望する」をクリックしていただければ、サービスが開始され次第、お知らせいたします",
  "用户应当出于合法、正当目的以合理方式使用%s券。用户不得单独或与商家恶意串通，利用%s券实施欺诈等非法行为以达到套取现金、获得积分或礼品等目的。且用户不得利用%s券进行售卖、兑换或从事其他营利性活动。如果平台发现用户存在前述违法违规行为，%s有权拒绝该用户使用%s券和/或对该用户账号和/或关联账号采取临时或永久停止平台服务等措施，并保留通过法律途径追究用户法律责任的权利。": "利用規約及び注意事項に従って、%sクーポンを使用ください。詐欺その他不正な目的・手段によって、%sクーポン・その他の景品等を不正に取得し、又は使用することはできません、%sクーポンの譲渡、販売、換金、交換、または、当社の事前の同意なく、その他の営利的な活動に使うことはできません。%sのサービス利用規約への違反行為が発見された場合、割引は適用されず、アカウントの閉鎖もしくは法的手段が追求される場合がございます。利用規約又は上記の注意事項の違反行為が発見された場合、当社は、%sクーポンの使用を拒否し、又はアカウントを停止・閉鎖、その他の法的責任を追及する可能性があります。",
  "由于用户未实际支付%s券抵用部分订单金额，因此用户不能获得%s券抵用部分金额的发票。": "%sクーポンで割引された金額はお客様がお支払いされていないため、%sクーポン相当額に関しては領収書を発行することができません。",
  "本次服务评价成功": "今回の注文を評価しました",
  "提交申请": "注文をキャンセル",
  "取消": "戻る",
  "请选择取消原因以便商家更快帮您处理": "キャンセル理由を選択すると、店舗がより早く状況確認の上、処理することができます",
  "钱包": "クーポン",
  "怎么样开通免密支付？": "パスワードフリー決済を使うには？",
  "什么是%s券？": "%sクーポンとは何ですか？",
  "谢谢。我们会及时通知你": "ありがとうございます。お知らせさせていただきます。",
  "退款金额": "払い戻し金額",
  "通用支付": "全ての支払方法",
  "促销码是字母和数字的组合。": "クーポンコードはアルファベットと数字の組み合わせです。",
  "6、每位用户每天最多赔付6张优惠券；": "6、本補填は一日につき最大6枚まで適用いたします。",
  "感谢您提前注册账号！我们将会及时为您发送最新的滴滴外卖活动信息。我们的服务即将开始，请拭目以待！": "事前のご登録ありがとうございました。ご登録いただいたあなたへ、今後もDiDiフードからおトクな情報を配信いたします。サービス開始まで今しばらくお待ちください。",
  "用户在开通%s服务的城市和/或地区下外卖订单；": "お客様が%sサービス提供中の都市やエリアで注文をする。",
  "促销码由什么组成？": "クーポンコードはどのように構成されていますか？",
  "能导致%s券不能使用的原因和解决办法是什么？": "%sクーポンが使えなくなる理由として何がありますか？またその解決方は何ですか？",
  "请您上传所收到的全部商品照片、问题商品细节照片、商品小票照片，这将协助我们更快地处理您的问题。": "受取った全商品の画像、問題のある商品の画像、レシートの画像をアップロードしてください。迅速に対応させていただきます。",
  "更多评价": "ここに記入してください",
  "99Pay-余额": "99Pay-残高",
  "详细说明": "詳細説明",
  "好的，我知道了": "閉じる",
  "4、部分时间段下单暂不参加赔付；": "4、一部時間帯においては本補填は適用されません。",
  "您的免配送费权益将于%s过期": "配達料無料クーポンは%sに期限が切れます",
  "提交反馈": "フィードバックを送信",
  "帮助中心": "ヘルプセンター",
  "6、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "6、配達完了時間をご確認いただき、実際は予定時間内に配達が完了していないにも関わらず、予定時間内に配達完了となっていた場合、%sのカスタマーセンター%sまでご連絡ください。",
  "获得新用户立减活动资格的新用户，是指第一次使用%s并成功完成订单的用户。新用户优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。": "初めて%sで注文を完了すると新規ユーザー登録割引キャンペーンが適用されます。新規ユーザー登録クーポンが店舗のスペシャルプライスや注文金額に応じた割引特典と併用可能かについて、各キャンペーン規則の詳細をご確認ください。",
  "%s公里之内配送费": "%skm以内の配達料",
  "我们很快会扩大服务范围，点击“请通知我”，我们将会在开通时通知你": "すぐにサービスエリアの拡大を行うことができますので、「通知を希望する」をクリックしていただければ、サービスが開始され次第、お知らせいたします",
  "支付配送费": "配達料金合計（税込）",
  "商家自行配送的配送费收取规则：": "店舗配達の配達料金：",
  "促销码是什么？": "クーポンコードとは何ですか？",
  "仅限支付卡bin为%s可用": "カードビンが%sの場合にのみ使用できます",
  "请告诉我们，帮助我们一起改善": "お客様よりいただいたフィードバックは、今後のサービス向上のために役立ててまいります。",
  "基础配送费": "基本配達料金",
  "当有配送费活动时，根据活动情况对配送费进行相应的优惠。": "配達料金割引キャンペーン時に、キャンペーン内容に応じて割引が適用されます。",
  "申请取消原因": "キャンセル理由",
  "打开": "を開く",
  "查看评价": "評価を見る",
  "%s券可以找零或兑换吗？": "%sクーポンはお釣りがもらえますか、また現金化は可能ですか",
  "收起": "閉じる",
  "5、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "5、以下のお客様のご都合による配達遅延や配達不能に関しては補填が適用されません。",
  "优惠券": "クーポン",
  "不可用原因": "このクーポンは利用できません",
  "(2) 因您的配送地址所在地不允许骑手入内，导致超时送达或无法送达的；": "(2) お届先住所に配達員が立ち入り不可のため、配達遅延/配達不可となった場合",
  "网络不佳，请检查您的网络": "ネットワークエラー。通信状態を確認してください",
  "周五": "金曜日",
  "您可以使用现金支付订单，请备好足够的零钱。": "受取時の代金引換が可能です。お釣りがでないようにご協力ください。",
  "周二": "火曜日",
  "最高抵扣%s": "割引上限%s",
  "特殊说明": "備考",
  "现金支付": "現金・その他",
  "已发起取消，等待商家确认": "キャンセル申請をしました。店舗にて確認中",
  "3、恶劣天气（雨、雪等）暂不参加赔付；": "3、悪天候時（雨や雪など）においては本補填は適用されません。",
  "兑换优惠": "クーポン引換",
  "现在订餐": "いますぐ注文",
  "如用户使用了%s券，取消订单时尚未完成支付，相关优惠券在用户取消订单后将即时退回账户。如用户取消订单时已完成支付，相关优惠券不再退回账户。订单款项将于订单取消后一周内原路退回支付账户。": "%sクーポンを使用して注文したが、まだ支払いを行なっていない段階で注文をキャンセルした場合は、クーポンは注文キャンセル後すぐにアカウントに返却されます。注文キャンセル時にすでに支払いが完了している場合は、クーポンはアカウントに返却されません。お支払いいただいた注文金額は注文キャンセル後1週間以内に支払い口座に返金されます。",
  "用户未违反平台外卖券使用规则，且该笔订单不属于平台判定的作弊或类似作弊订单等禁用%s券的情况。": "DiDi Foodのクーポン使用規則に違反せず、かつ不正もしくは不正に類する注文などの%sクーポン使用禁止項目に該当しない場合。",
  "我们正在努力拓展范围。如果您希望 %s 的服务尽快到您身边，请告诉我们": "サービスエリア拡大中です。お客様の地域で%sフードをご利用になりたい場合はお知らせください。ご参考にさせていただきます。",
  "已经累计为您节省了%s配送费": "%sの配送料をお安くできました！",
  "%s致力于通过无密码和现金支付流程为我们的客户提供简单安全的付款体验。": "%sはキャッシュレス決済による安全でスムーズな決済の提供に努めています。",
  "用户兑换促销码的次数限制是多少？": "クーポンコードの引換可能回数は何回までですか？",
  "配送费受多种因素影响，包括您和餐厅之前的距离，配送的最终地址和该地区的供需情况。": "配達料金はお店までの距離や地域の注文数により変動します。",
  "免配送费权益": "配達料無料キャンペーン",
  "%s平台配送费收取规则：": "%s配達の配達料金：",
  "不可以。一个账号内的%s券仅可用于支付该账号内的%s订单。": "できません。アカウント内の%sクーポンはそのアカウントでの%sの注文支払い時のみ使用できます",
  "部分餐品缺失- 表示骑手已经给您交餐，表示骑手已经给您交餐，但您有部分餐品没有收到。": "一部の商品が不足している：配達員から商品を受け取ったが、一部の商品が含まれていない場合",
  "如果对费用提出争议，将如何解决？": "支払いに関する問い合わせをしたい",
  "（必填）请填写您的申诉原因": "申立て理由を入力してください（必須）",
  "2、部分时间段下单暂不参加赔付；": "2、一部時間帯においては本補填は適用されません。",
  "很抱歉给你造成了不好的体验，请选择您遇到的问题：": "ご不便をおかけして大変申し訳ございません。\n下記より発生した問題の解決方法をご確認ください。",
  "请通知我": "通知を希望する",
  "送您1张%s优惠券；": "%sのクーポンを1枚差し上げます。",
  "恭喜你！兑换成功！": "おめでとうございます！引換に成功しました！",
  "用户绑定了银行卡或使用现金支付；": "登録済みのキャッシュカードまたは現金で決済：",
  "您的订单为商家配送，您可点击下方的联系商家按钮与商家进行问题协商。": "この注文は店舗配達です。ページ下部の「お店へ連絡」をタップしてご相談するようお願いします。",
  "同一个促销码可以重复兑换么？": "一つのクーポンコードを使って何度も引換可能ですか？",
  "%s券抵用餐费部分可以开发票吗？": "%sクーポンで支払った分は領収書を発行することができますか？",
  "已过期券": "期限切れのクーポン",
  "有效期至": "有効期限：",
  "他们已加入%s": "すでに%sに加入しています",
  "卡": "カード",
  "用户支付外卖订单时使用%s券并支付成功；": "%sクーポン適用でのお支払いに成功しました。",
  "周四": "木曜日",
  "一个外卖订单最多只能使用一张%s平台券。如：品类券与配送费券不可同时使用。": "1回の注文で%sクーポンを1枚まで使用することができます。カテゴリークーポンと配送料クーポンは同時に使用することはできません。",
  "促销码有有效日期嘛？": "クーポンコードには有効期限がありますか？",
  "你可以在个人中心的“优惠券”或“钱包”-“优惠券”中兑换它。": "マイアカウントの「クーポン」や「ウォレット」-「クーポン」で引換可能です。",
  "%s平台处理": "%sにて対応",
  "由配送商家决定配送金额。": "店舗配達の場合、配達料金はお店の規定によります。",
  "晚间：%s-%s": "夜間：%s-%s",
  "中查看": "にて確認できます。",
  "配送费详情": "配達料金明細",
  "将赔付给您1张%s折扣券": "遅延補填として%sの割引クーポンを1枚差し上げます",
  "送您%s张免配送费券；": "%s枚の配達料無料クーポンを差し上げます。",
  "正在寻找骑手": "配達員を探しています",
  "反馈部分餐品缺失": "一部商品不足としてフィードバックしてください",
  "3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。": "3. 現金・その他決済の場合は払い戻し金額分のクーポンをアカウントに付与いたします。現金での払い戻いはできません。",
  "优惠券使用规则": "クーポン使用規則",
  "验证成功": "検証に成功しました",
  "%s券是%s发放给用户，用户在支付%s订单时，用于抵扣订单金额的电子券。": "%sクーポンは%sがお客様に配布し、%sの注文を支払う際に、注文金額の割引にご利用いただく電子クーポンです。",
  "提交成功": "送信に成功しました",
  "用户在“钱包”中有可使用的%s券；": "「ウォレット」にお客様の使用可能な%sクーポンがあります。",
  "延迟送达%s分钟以上": "配達遅延%s分以上",
  "您的小费将全部支付给骑手": "チップは全額、配達員に支払われます",
  "让 %s 进入我的地区": "私の地域でも%sを使いたい",
  "在支付方式中添加您的银行卡，成功绑定后即可开通免密支付。": "支払方法にキャッシュカードの追加完了するとパスワードフリー決済が可能になります。",
  "兑换失败": "引換失敗",
  "我们正在努力拓展中。您可以访问周边热门商圈看看美食": "サービス提供エリア拡大中です。先行して周辺の人気エリアの料理を見ることができます",
  "满意": "満足",
  "再等等": "もう少し待つ",
  "骑手正在配送中": "配達中です",
  "周六": "土曜日",
  "描述": "詳細",
  "5、每位用户每天最多赔付6张优惠券；": "5、本補填は一日につき最大6枚まで適用いたします。",
  "2.点击选择“浏览器”，进行下载": "2.「ブラウザ」を選択して、インストールしてください",
  "%s将不定时地通过在本页面发布更新版本的方式修改使用规则。请用户定期访问本页面并查看最新版本。这些规则对用户均具有约束力。": "%sはこちらのページで最新バージョンを配信しており、使用規則の更新を行います。定期的にこちらのページで最新バージョンをご確認ください。また最新バージョンの規則はすべてのお客様に適用されます。 ",
  "无效券": "過去のクーポン",
  "/公里": "/km",
  "当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "注文商品（キャンセルした注文は除く）が予定配達時刻以内に配達されなかった場合、%sより一定額のクーポンを差し上げます。注文完了後に次の箇所で確認できます：",
  "出生日期": "バースデー",
  "餐费": "小計",
  "完成注册登录，以便开通服务后通知您": "登録を完了すると、サービス開始後にお知らせをお送りします",
  "不使用优惠券": "クーポンを使用しない",
  "请上传商品和小票照片": "商品の写真をアップロードしてください",
  "商家优惠": "キャンペーン割引",
  "你好": "こんにちは",
  "CPF": "CPF",
  "附近商家正在筹备中": "近くに営業中のお店がありません",
  "失败": "失敗する",
  "验证失败": "検証に失敗しました",
  "不可以。用户选择使用%s券支付订单时将优先使用%s券抵扣订单金额。券金额高于订单金额的，差额部分不可提现、退款、找零、买卖、兑现或转赠他人；订单金额高于券金额的，差额部分由用户支付。": "できません。お客様が%sクーポンを使用するを選択すると、お支払い時に%sクーポンが優先的に適用され、注文金額から割引されます。クーポンの金額が注文合計金額を超える場合でも、差額の現金化、返金、お釣りの支払い、売買、現金交換、譲渡を行うことはできません。注文金額がクーポン金額を超える場合は、不足分をお支払いいただきます。",
  "不可以，兑换出的优惠券可以根据优惠券的使用规则，抵扣部分订单费用。": "できません。引換後のクーポンはクーポン使用規則に従い、注文金額の割引にのみ使用可能です。",
  "(1) 您要求更换收获地址、您提交的地址错误、联系不上您或无人收货、拒收等导致订单超时送达或无法送达的；": "(1) お届先変更、お届先誤入力、連絡不通、受取人不在、受取拒否などの原因で配達遅延/配達不可となった場合",
  "立即兑换": "確認",
  "用户在进行订单支付时网络信号中断或不稳定，可稍后到网络信号稳定的地方再进行支付；": "支払時に通信が途切れたり、不安定な状態の場合は、通信環境が安定した場所で再度支払いをしてください；",
  "服务费+里程费": "サービス手数料+距離料金",
  "什么是%s券的“有效期”？": "%sクーポンの「有効期限」とは何ですか？",
  "目前我们支持信用卡，借记卡的线上支付，以及现金和pos机的线下支付。": "Visa/Mastercardのクレジットカード、デビットカードによるオンライン決済、および代金引換に対応してます。",
  "OFF": "OFF",
  "下单时使用%s券，订单取消后，外卖券还会返还吗？": "%sクーポン適用後に、注文をキャンセルした場合、クーポンは返却されますか？",
  "暂无优惠券": "何もありません",
  "1.点击右上角菜单": "1.右上のメニューをタップ",
  "联系骑手": "配達員へ連絡",
  "去看看已开通地区": "サービス提供中エリアを確認",
  "在哪里可以兑换促销码？": "どこでクーポンコードとの引換できますか？",
  "关闭": "閉じる",
  "下载": "をダウンロード",
  "用户通过在线支付、现金支付的方式支付外卖订单，且用户领券时输入的手机号与实际下订单时使用账号登记的手机号为同一号码；": "アプリ決済、現金・その他決済により注文を行い、かつクーポン受取時に入力した携帯電話番号と注文時に使用したアカウントに登録された携帯電話番号が同一の番号の場合。",
  "更多问题": "その他の質問",
  "pos机支付": "受取時キャッシュレス決済",
  "随时随地享美食": "「おいしい」を届けるフードデリバリーアプリ",
  "感谢你的支持，有新商家上线会第一时间通知你": "ご愛顧ありがとうございます。新しい店舗がオープン次第、すぐにお知らせいたします",
  "周日": "日曜日",
  "去周边看看": "周辺地域を確認してみる",
  "请选择有问题的商品": "問題をフィードバックしてください",
  "将赔付给您%s张免配送费券": "遅延補填として%s枚の配達料無料クーポンを差し上げます",
  "保持联系！": "何かありましたら、いつでもご連絡ください",
  "处理结果将短信通知您": "処理結果をショートメッセージでお知らせします",
  "周三": "水曜日",
  "新用户优惠券的使用要求是什么？": "新規ユーザーのクーポン使用条件は何ですか？",
  "周一": "月曜日",
  "商家配送": "店舗配達",
  "订单实际送达时间晚于预计送达时间%s分钟以上，": "実際の配達時間が予定配達時刻より%s分以上遅れた場合、",
  "取消订单": "注文をキャンセルする",
  "您可以使用信用卡或储蓄卡通过pos机支付订单，请确保您的卡是可用的。": "クレジットカードやデビットカードでお支払いが可能です。カードが有効であることをご確認ください。",
  "成功": "成功",
  "您对配送服务的评价": "配達サービスへの評価",
  "输入兑换码": "クーポンコードの入力",
  "展开": "開く",
  "评价本次服务": "今回の注文を評価する",
  "兑换失败，请再试一次": "引換に失敗しました。もう一度お試しください",
  "商家已开始制作美食，拒绝了您的取消订单申请": "店舗はすでに調理を開始しています。注文キャンセル申請は拒否されました",
  "已收到你的申请": "ご注文を受け取りました",
  "7、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "7、配達完了時間をご確認いただき、実際は予定時間内に配達が完了していないにも関わらず、予定時間内に配達完了となっていた場合、%sのカスタマーセンター%sまでご連絡ください。",
  "整个订单都没收到- 表示骑手没有为您配送订单，您没有收到任何餐品。": "すべての注文品を受け取っていない：配達員が商品を配達しなかったため、いずれの商品も受け取っていない場合",
  "对处理结果不满意？": "申し立てをしたい場合?",
  "抱歉，你所在的区域不支持配送": "申し訳ございません、お客様のエリアは配達エリアに含まれておりません",
  "2、超时赔付服务由平台为用户提供，用户没有支付任何费用，若订单取消则服务取消。": "2、遅延補填はDiDi Foodが提供している無料のサービスです。お客様の注文がキャンセルされた場合、遅延補填もキャンセルされます。",
  "隐藏": "非表示",
  "更多详情": "詳細を見る",
  "app内可以支持哪些支付方式？": "どのような決済方法がありますか？",
  "订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": "実際の配達時間が予定配達時刻を%sから%s分遅れた場合、",
  "促销码是由系统生成的，可以通过促销码兑换优惠券。": "クーポンコードはシステムにより生成され、クーポンコードを使用してクーポンと引換できます。",
  "有效期至%s": "有効期限：%s",
  "%s将不定期推出多样的优惠活动，如：新老用户满减优惠券、通过分享获得红包优惠券等，具体活动均将在本平台第一时间推送，或以短信形式联系您，您可随时关注相关活动。": "%sは様々なキャンペーンを実施します。例えば：新規/既存のお客様向けに、一定額以上のご利用で割引するクーポンや、シェアでもらえるラッキークーポンキャンペーンなどがあります。キャンペーンの詳細はアプリやショートメッセージにてお知らせいたします。",
  "配送费计费规则": "配達料金計算方法",
  "已经收到你的申请": "ご注文を受け取りました",
  "仅限新用户使用": "新規のお客様に限り利用可",
  "送您1张%s折扣券；": "%sの割引クーポンを1枚差し上げます。",
  "退款渠道": "払い戻し方法",
  "配送费": "配達料金",
  "联系客服": "カスタマーセンターへ連絡",
  "联系客服进行申诉": "カスタマーセンターにご連絡ください",
  "确认": "確認",
  "4月7号起，滴滴外卖将在大阪开始试运营啦！您可以在滴滴外卖App上找到您喜欢吃的各式当地美食。我们的服务上线大促活动即将开始，快点击下方按钮，不错过我们的优惠活动！": "4/7より、DiDiが大阪でフードデリバリーサービスの実証実験を開始します。食いだおれの街・大阪の様々なおいしい料理が、スマホ一つであなたの元へ。おトクなキャンペーンも準備中です。お見逃しなく！さあ、いますぐ登録しましょう！",
  "当前区域暂未开通服务": "現在、お客様の地域は配達エリアに含まれておりません",
  "超时赔付规则说明": "遅延補填規則の説明",
  "如果用户对使用规则或在使用%s软件和/或相关服务过程中有任何疑问或需要任何帮助，请及时与%s客服联系，": "使用規則や%sソフトウェアや関連サービスの使用に関して、疑問や要望がある場合は、%sカスタマーセンターへご連絡ください。",
  "支付信息": "お支払い情報",
  "平台配送": "配達",
  "您为什么取消？": "キャンセル理由はなんですか",
  "一个外卖订单最多能够使用几张%s券？": "1回の注文で%sクーポンを何枚まで使用することができますか？",
  "(3) 其他因您的原因导致骑手超时送达或无法送达的。": "(3) その他お客様の都合によって、配達遅延/配達不可となった場合",
  "小费说明": "チップの説明",
  "更多地区入驻中……": "サービスエリア拡大中",
  "配送费收取标准": "配達料基準",
  "%s~%s公里之间配送费": "%s~%skm以内の配達料",
  "用户的外卖订单符合%s券券面注明的使用条件，如：餐品价格加餐具费满足该外卖优惠券所属品类、满减金额及有效使用时间限制；": "注文が%sクーポンに記載された使用条件を満たす場合、例：容器代を含めた商品金額がクーポンのカテゴリや金額および有効期限の規定を満たしている場合。",
  "超出%s公里部分收取标准": "%skm超過部分の料金基準",
  "可能的原因和解决办法如下：": "考えられる原因と解決方法は下記の通りです。",
  "促销码分为可以兑换一次的和可以兑换多次的，实际可兑换次数以促销码活动规则为准。": "クーポンコードには一回限り引換可能なものと複数回引換可能なものがあります。それぞれ設定したキャンペーン規則に準じます。",
  "免费取消订单": "初回免除",
  "可抵扣%s": "%sから割引",
  "骑手没有配送我的订单": "注文は完了しているが、商品を受け取っていない",
  "仅限%s": "%sに限り利用可",
  "3、您因超时赔付服务获得的优惠券，将发放至您的%s账户，请在": "3、本補填が発生した場合、お客様の%sアカウントにクーポンを支給いたします。",
  "我的优惠": "クーポン一覧",
  "您可以点击下方按钮和骑手联系交餐。": "ページ下部のボタンより直接配達員に連絡する、もしくはDiDi Foodから配達員に連絡するかをご選択できます。",
  "您总是能在点餐之前确认该订单的配送费。": "お客様はご注文の前に配達料金を確認できます。",
  "特别提示: ": "重要なお知らせ： ",
  "POS": "決済機",
  "骑手已到达": "配達員が到着しました",
  "大阪，出发！": "大阪からスタート！",
  "等待商家接单": "お店の注文受付待ちです",
  "%s可用": "割引対象：%s",
  "商家正在入驻中": "お店が出店中",
  "1、超时赔付服务是%s为您免费提供的服务，延迟送达时间及赔付优惠券金额如下：": "1、遅延補填は%sがお客様に提供しているサービスです。詳細は以下の通りです。",
  "上报问题": "問題を報告します",
  "您的订单完成已经超过24小时，无法自助申请，如您有问题可联系客服，给您带来不便深表歉意。": "注文完了してから24時間を過ぎた場合、払い戻しの申請はできません。ご不明点がございましたらカスタマーセンターまでご連絡ください。",
  "商家拒绝取消申请": "店舗がキャンセル申請を承認しませんでした",
  "联系商家": "店舗へ連絡",
  "一个用户一天内使用的%s券数量之和不得超过两张，各优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。同一个账号、手机号、支付账号、设备以及其他相同或相似身份信息，均视为同一用户。": "一日に使用できる%sのクーポンの合計枚数は3枚です。各クーポンが店舗のスペシャルプライス/注文金額に応じた割引特典と同時に使用できるかについては、%sの各キャンペーンの詳細規則をご確認ください。同一のアカウントや携帯電話番号、決済口座番号、デバイス、同一または類似した本人確認情報は同一ユーザーと見なされます。",
  "1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费不退还。 2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。": "1. 注文の一部が払い戻し対象の場合、対象商品に対して割引適用後の実際に支払った金額を払い戻しいたします。配達料金は払い戻し対象外になります。 2. 注文の全てが払い戻し対象の場合、お支払いした金額は全額払い戻しとなります。ご利用したクーポンはアカウントに返還いたします。 ",
  "感谢你的支持，区域开通后会第一时间通知你": "ご愛顧ありがとうございます。エリアサービスがご利用いただける状態になり次第、すぐにお知らせいたします",
  "在什么条件下可以使用%s券？": "%sクーポンはいつどのように使用することができますか？",
  "您对配送服务满意吗？": "配達にご満足いただけましたか？",
  "温馨提示": "お知らせ"
}