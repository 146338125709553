module.exports = {
  "FoodC_statement__1_eIic": "（1）骑手取件后，收件人需保持电话畅通，按时到达收件地址与骑手接头。",
  "FoodC_Conciliation_Operation_failed_wEjU": "操作失败，请重试",
  "FoodC_invoicing_jCYw_YcPH": "开票成功后，电子票据将发至你的邮箱，请填写以下信息",
  "FoodC_cancel_Cancellation_Policy_rnAp": "取消规则",
  "FoodC_Later_TWbh_AXWk": "支付成功",
  "FoodC_home_The_user_ElUo": "用户绑定了银行卡或使用现金支付；",
  "FoodC_demand__Do_CKdn": "*不购买缺货商品，其余商品继续购买；",
  "FoodC_side_Tyor_qIoH": "条款",
  "FoodC_demand_Courier_Purchasing_eTAr": "“骑手代购”服务说明",
  "FoodC_cancel__free_BQFd": "骑手接单后{{free_time}} 分钟内，用户可免费取消订单",
  "FoodC_invoicing_HPuN_MOyc": "邮箱",
  "FoodC_store_Store_Coupon_szoR": "商家券",
  "FoodC_home_The_entrega_uOPx": "支付订单时使用优惠券并支付成功",
  "FoodC_Later_XtdO_YxTQ": "你所购买的券已经被放进了你的优惠券中心列表当中，通过以下步骤可以找到它",
  "FoodC_statement__4_YGAs": "（4）除了现金订单所涉及的费用，您无需向骑手支付任何其他费用。",
  "FoodC_sales_Confirmation_mTGd": "确认",
  "FoodC_page_vsqt_uXcI": "配送券",
  "FoodC_Noavailablestore_zVkf_urAN": "返回主页",
  "FoodC_2__sybX": "今天的券已经领完了，下次早点来哦",
  "FoodC_statement__2_ZtHa": "（2）向骑手交付物品时，需仔细核实配送员身份信息，否则如将物品交付到错误的骑手手中，您应自行承担相应责任；",
  "FoodC_demand__1_jqYB": "（1）您根据页面提示填写收货地址、收货人、商品要求等信息，支付费用后，骑手将会根据您的要求到指定或附近商家购买商品：",
  "FoodC_education_1_Customers_Gpbb": "1. 顾客可在下单前选择交餐方式，平台会要求骑手按照顾客所选择的方式进行处理：",
  "FoodC_title_Upload_Image_VSGW": "上传图片规范",
  "FoodC_side3_xeOS_SWLO": "{{name}}可用",
  "FoodC_education__1_mJTG": "（1）如果商家已确认接单，您可通过‘取消订单‘功能与商家协商申请取消订单；如商家因已出餐等合理理由不同意取消订单，您将不能免费取消订单；如您坚持取消订单或者拒收餐品，则订单相关费用将由您全部承担；",
  "FoodC_order__2_UYRU": "（2）您所需支付的订单费用以下单时页面展示为准，如骑手实际购买时产生较大价差，会自动向您返还费用；如实际购买价格高于您实付金额，不再向您额外收取费用",
  "FoodC_invoicing_MIEP_sDYJ": "请填写税号",
  "FoodC_sales_Confirmation_jtNg": "确认",
  "FoodC_feedback_I_received_UNiy": "我收到别人的订单",
  "FoodC_home_DiDi_zojp": "滴滴",
  "FoodC_sales_3_Display_GYtM": "3. 您下单时通过现金支付，如退款申请通过，退款金额将退还到您的钱包。",
  "FoodC_Later_TVZu_RjZF": "结账",
  "FoodC_demand__2_ALrB": "（2）您所需支付的订单费用以下单时页面展示为准，若实际购买时产生价差，不再向您额外收取或退回费用；",
  "FoodC_invoicing_epVj_kTpl": "开票名称",
  "FoodC_home_Orders_are_EEjN": "用户在开通服务的城市和/或地区下{{entrega_name}}订单；",
  "FoodC_cancel_Free_order_WqpG": "用户可免费取消订单",
  "FoodC_education_3_If_wduh": "3. 若因产品问题、天气问题等非人为因素导致交餐纠纷，平台承担责任",
  "FoodC_home_1_The_SWqc": "1. 用户应当出于合法、正当目的以合理方式使用{{entrega_name}}券。用户不得利用{{entrega_name}}券实施欺诈等非法行为以达到套取现金、获得积分或礼品等目的。且用户不得利用{{entrega_name}}券进行售卖、兑换或从事其他营利性活动。如果{{brand_name}}发现用户存在前述违法违规行为，{{entrega_name}}有权拒绝该用户使用{{entrega_name}}券和/或对该用户账号和/或关联账号采取临时或永久停止{{brand_name}}服务等措施，并保留通过法律途径追究用户法律责任的权利。",
  "FoodC_seals_Outdoor_packaging_MGso": "收餐时外包装密封状态",
  "FoodC_demand_2_How_DjaQ": "2.骑手代购服务怎么用？",
  "FoodC_activities_ZYMp_mFfB": "支付活动",
  "FoodC_c_Please_try_cHSE": "请稍后再试",
  "FoodC_Later_uAfu_eSHi": "请选择地址",
  "FoodC_statement__3_gBJT": "（3）如骑手已取件后，您取消仍需承担订单费用；",
  "FoodC_home_The_following_vTNS": "A: 用户使用{{entrega_name}}券应同时满足以下条件：",
  "FoodC_RCOUPON_Coupon_reduction_PqSi": "巴西满减券",
  "FoodC_statement__2_Rrwp": "（2）骑手取件后，发/收件人均会收到收件码；如收件人未收到包含收件码的短信，发件人可在订单详情页分享配送状态和收件码给收件人，或可联系客服确认收件码；为保证物品安全，请勿提前分享收件码给骑手及其他人。",
  "FoodC_demand_3_What_zsYM": "3.骑手代购的商品如果缺货时，如何处理？",
  "FoodC_education_In_order_SRIb": "为了使您在{{brand_name}}服务中有更好的体验，平台特制订取消规则，以供各方遵守。",
  "FoodC_feedback_2_The_ntBT": "2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。",
  "FoodC_statement_2_How_LmUD": "2、{{entrega_name}}服务怎么用？",
  "FoodC_Later_ZBBS_XWul": "账号-券中心",
  "FoodC_Later_GwXN_BQcj": "附近可用券的门店",
  "FoodC_deeplinkpageshow_wcmD_CuSi": "请选择您已下载的app打开页面",
  "FoodC_sales_If_the_TsPm": "如果外包装有封条或其他密封工具（如装订针等），请您告诉我们收餐时它们是否完好。信息仅用于平台更准确地与商家和骑手核实您的问题，信息不会影响对您的处理结果。",
  "FoodC_invoicing_QueK_zCcn": "已开票",
  "FoodC_cancel_Courier_has_LQtM": "骑手已接单",
  "FoodC_education__2_uVUK": "（2）因商家未营业、商品售罄、发生意外等原因订单被商家或骑手取消，订单费用将全部退回；",
  "soda-d-i18n---没有更多了": "没有更多了",
  "FoodC_demand__You_yjru": "*您可选择线上支付，下单时支付订单费用，骑手到店购买商品，购买成功送货上门；",
  "FoodC_home_The_user_vjgt": "用户的{{brand_name}}软件版本较低，可在升级{{brand_name}}软件版本后再进行支付。",
  "FoodOpD_status_Store_OCbV": "商家",
  "FoodC_home_The_user_lsyI": "用户未违反平台{{entrega_name}}券使用规则，且该笔订单不属于平台判定的作弊或类似作弊订单等禁用优惠券的情况。",
  "FoodD_one_Please_try_VyNK": "请稍后重试",
  "FoodC_demand__If_PNsp": "*一旦有任何商品缺货，直接整单取消；",
  "FoodC_statement__3_PxyO": "（3）骑手取件后，若因指定的收件人的原因导致送件无法完成（包括并不限于收件人拒绝接收物品、无法同收件人取得联系或者收件地址错误等情况），发件人将恢复对物品的处理权，但需要支付相关服务费用。",
  "FoodC_statement_Animals_people_IccN": "动物、人、武器或爆炸物；",
  "FoodC_home_2_brand_vxUa": "2. {{brand_name}}将不定时地通过在本页面发布更新版本的方式修改使用规则。请用户定期访问本页面并查看最新版本。这些规则对用户均具有约束力。",
  "FoodC_saved_ZHnz_OjnQ": "原价",
  "FoodC_Translation__brand_LAYp": "{{brand_name}} 优惠券",
  "FoodC_Later_BYic_nYYd": "以后再用",
  "FoodC_cancel_When_the_PAQq": "当平台检测到您频繁操作取消订单时，不可取消订单，可联系客服咨询",
  "FoodC_demand__4_HJNa": "（4）若存在违规行为，平台将进行相应的处罚；",
  "sailing.c-api-intl.[提单页]_[平台券]": "平台券",
  "FoodC_demand__3_tQmA": "（3）不同地区订单计费标准以产品公示为准；",
  "FoodC_statement__4_Nghy": "（4）若因骑手联系不上取件人/ 收件人，取件/ 收件地址错误等原因，导致物品无法交付，您仍将承担订单费用，并需和骑手自行协商物品的返还/重新配送的时间和地点；",
  "FoodC_education_3_Customers_Zoec": "3. 顾客需要保持手机畅通，便于骑手联系取餐；",
  "FoodC_invoicing_yJyj_kAmJ": "票据已发送至你的电子邮箱",
  "FoodC_update_The_expected_ZzFg": "预计送达时间以平台预估的最晚预计送达时间为准。",
  "FoodC_statement_Currency_or_drqX": "货币或者有价证券；",
  "FoodC_demand__In_XsxW": "*若因缺货问题产生纠纷，平台将根据本规则判定责任方。",
  "FoodC_Later_otla_noJA": "我囤的券",
  "FoodC_Later_wFZx_DICJ": "使用规则",
  "FoodC_education__1_vwgm": "（1）送货上门",
  "FoodC_page_Contact_customer_rJbM": "联系客服反馈",
  "FoodC_Later_Jtbt_KBCI": "已超出今日最大可买数量限制",
  "FoodC_Later_XpNu_TSXn": "点击去下单",
  "FoodC_home_What_is_tlHh": "Q: 什么是{{entrega_name}}券？",
  "FoodC_home_The_user_jRpX": "订单符合优惠券券面注明的使用条件；",
  "FoodC_education__1_qvVC": "（1）顾客取餐超时",
  "FoodC_statement_Jewelry_vtUc": "珠宝；",
  "FoodC_saved_AlLL_CHtd": "实付",
  "FoodC_statement_Psychotropic_drugs_XUfp": "精神药品和麻醉药品；",
  "FoodC_home_What_is_NZVx": "Q: 什么是{{entrega_name}}券的“有效期”？",
  "FoodC_statement_Weight_The_kJIL": "重量：单件物品重量不得超过{{weight_limit}}kg",
  "FoodC_education_Meal_picking_ziun": "取餐规则",
  "FoodC_Later_tuMj_hRvh": "切换门店",
  "FoodC_saved_jKhv_Cwtv": "券包",
  "FoodC_perception__XmZq": "返回首页",
  "FoodC_demand__1_EzSJ": "（1）在骑手购买商品前，您可免费取消订单。",
  "FoodC_statement_4_Rules_XuZc": "4、订单取件规则",
  "FoodC_home_How_do_xoCZ": "Q: 怎样才能获得{{entrega_name}}券？",
  "FoodC_education_5_Cash_zBuY": "5. 如下现金支付订单，顾客需准备充足的现金，现金付款后取餐；",
  "FoodC_cancel_Reason_for_vfFe": "取消原因",
  "FoodC_home1_DiDi_Delivery_jard": "滴滴闪送",
  "FoodC_home_Can_you_qvdx": "Q: 可以用一个账号内的{{entrega_name}}券为其他账号内的订单进行支付吗？",
  "FoodC_home_A_entrega_sgzW": "A: 一笔{{entrega_name}}订单最多只能使用一张{{entrega_name}}券。",
  "FoodC_Later_bsYo_QDtF": "加购",
  "FoodC_home_Can_the_Jjzi": "Q: {{entrega_name}}券抵用配送费部分可以开发票吗？",
  "sailing.c-api-intl.[提单页]_[商家券]": "商家券",
  "FoodC_Later_rnmL_Piol": "购买须知",
  "FoodC_linkjump_jhdQ_iztH": "在{{DiDiFood}}中打开",
  "FoodC_page_WkPn_SZWm": "餐费券",
  "FoodC_home__entrega_HFmI": "A: {{entrega_name}}将不定期推出多样的优惠活动，如：新老用户满减优惠券、通过分享获得红包优惠券等，具体活动均将在本平台第一时间推送，或以短信形式联系您，您可随时关注相关活动。",
  "FoodC_feedback_Order_issues_wWOH": "订单问题",
  "FoodC_page_llxi_vTBV": "商家活动",
  "FoodC_demand_4_Courier_ReDA": "4.骑手代购订单取消规则",
  "FoodC_saved_UjVo_BAJJ": "没有更多数据",
  "FoodC_cancel__free_pPaN": "骑手接单后{{free_time}} 分钟后，用户有偿取消订单，需支付{{cancellation_fee}}取消费",
  "FoodC_cancel_Order_accepted_DmOH": "待骑手接单",
  "FoodC_cancel_Wait_uNLX": "等一等",
  "FoodC_feedback_3_When_OcDb": "3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。",
  "FoodC_statement_5_Rules_eBJs": "5、订单收件规则",
  "FoodC_home_What_are_DhsJ": "Q: 能导致{{entrega_name}}券不能使用的原因和解决办法是什么？",
  "FoodC_home_Because_the_geRF": "A: 由于用户未实际支付{{entrega_name}}券抵用部分订单金额，因此用户不能获得{{entrega_name}}券抵用部分金额的发票。",
  "FoodC_description_Partial_meal_JWNU": "部分餐品缺失:",
  "FoodC_statement_Volume_The_wFKH": "体积：单件物品长宽高不得超过{{length_limit}} cm x {{width_limit}} cm x {{height_limit}} cm",
  "FoodC_Translation__unk_axrk": "{{brand_name}}优惠券已经兑换成功!",
  "FoodC_demand__3_hmpb": "（3）商品缺货退款规则：",
  "FoodC_Later_ePJi_KUku": "当前定位地点还没有可购买的券，尝试切换其他位置吧",
  "FoodC_home__entrega_VWeG": "A: {{entrega_name}}券是{{entrega_name}}发放给用户，用户在支付{{entrega_name}}订单时，用于抵扣订单金额的电子券。",
  "FoodC_statement_Drugs_controlled_drMh": "受管制或限制流通的药物；",
  "FoodOpC_Notifications_Full_service_HBkd": "全部服务城市可用",
  "FoodC_home_Can_entrega_mdgU": "Q: {{entrega_name}}券可以找零或兑换吗？",
  "FoodC_home_Users_who_Xjqh": "用户不符合{{entrega_name}}券使用规则中列明的可以使用券的条件，应仔细阅读并保证符合优惠券注明的使用条件；",
  "FoodC_statement_1_What_SFNM": "1、{{entrega_name}}服务是什么？",
  "FoodC_page__iyoe": "收起",
  "FoodC_education_1_If_iWDm": "1. 如顾客未按照上述要求取餐，导致交餐纠纷，优先顾客承担责任，具体场景包括且不限于：",
  "FoodC_statement_Laboratory_samples_AmoJ": "实验室样品；",
  "FoodC_home_A_new_TGvz": "A: 获得新用户立减活动资格的新用户，是指第一次使用{{entrega_name}}并成功完成订单的用户。",
  "FoodC_saved_FWIR_qHYd": "{{app_name}}不收取服务费",
  "FoodC_Later_cyVs_VahD": "去下单",
  "FoodC_side_UqUs_JnwO": "兑换码",
  "FoodC_Noavailablestore_mGUe_RipS": "附近没有参与活动的商家",
  "Groceries_payment_AeNZ_HMSX": "继续提交",
  "FoodC_C_spYG_gGNu": "实际退款金额将在商家或平台审核后确认",
  "FoodC_ServiceFee_LQae_RZIk": "请填写地址",
  "FoodC_demand_5_there_jISx": "5、对代购商家信息有疑惑，如何处理？",
  "FoodC_page__tlAr": "商家活动",
  "FoodC_issues_Contact_customer_Anvy": "联系客服处理",
  "FoodC_cancel_Special_circumstances_aOpL": "特殊情况",
  "FoodC_invoicing_DqEG_YcKx": "请填写邮箱",
  "FoodC_home_Terms_of_ZXoU": "使用条款及隐私政策",
  "FoodC_home_How_many_mego": "Q: 一笔{{entrega_name}}订单最多能够使用几张{{entrega_name}}券？",
  "FoodC_sales_Remember_my_kvqE": "记住我的选择，之后默认使用该方式退款。",
  "FoodC_home_How_many_hRpj": "Q: 一位用户一天内最多能够使用几张{{entrega_name}}券？",
  "Gattaran_coupon_Store_Coupon_xLBA": "商家券",
  "FoodC_education_Principles_of_RKEk": "取餐纠纷处理原则",
  "FoodC_Later_ZBxz_opnp": "去使用",
  "FoodD_one_Try_again_qZka": "重试",
  "FoodC_Later_vuZG_sxUt": "目前没有购买的优惠券",
  "FoodD_one_Service_not_eFvs": "服务未连接",
  "FoodC_feedback_It_was_ZrMP": "收到整个订单都是错的",
  "FoodC_statement__1_wZhz": "（1）如骑手长时间未接单，您可免费取消订单；",
  "FoodC_cancel_Courier_picked_obrP": "骑手已取件",
  "FoodC_name_DiDi_Flash_TAPX": "滴滴闪送",
  "Entrega_name_99_Flash_maAd": "99闪送",
  "FoodC_education_Basic_requirements_hIbv": "顾客取餐基本要求",
  "FoodC_cancel_Cancel_the_MyBx": "取消结点",
  "FoodC_demand__2_eSAk": "（2）缺货时，您可选择以下处理方式：",
  "FoodC_description_Indicates_that_MWBQ": "表示骑手没有为您配送订单，您没有收到任何餐品。",
  "FoodC_statement__5_eCLd": "（5）若因骑手在取件途中发生意外/车辆故障等原因，订单被取消，平台将为您指派其他骑手上门取件；",
  "FoodC_home_The_sum_zzUZ": "A: 一位用户一天内使用的{{entrega_name}}券数量之和不得超过三张。同一个账号、手机号、支付账号、设备以及其他相同或相似身份信息，均视为同一用户。",
  "FoodC_description_Indicates_that_fnET": "表示骑手已经给您交餐，但您有部分餐品没有收到。",
  "FoodC_feedback_1_When_buWL": "1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费和小费不退还。",
  "FoodC_Later_FVUi_pcGM": "很抱歉，本次活动已经结束，期待您下次早些参与！",
  "FoodC_demand__If_qODk": "*若您选择不购买缺货商品，其余商品继续购买，则线上支付订单自动退回缺货商品费用，现金支付订单无需支付缺货商品费用；",
  "FoodC_demand_If_you_SpKh": "若您对代购商家信息有疑惑，可联系客服说明问题，客服电话{{Customer_Service_Contact_Number}}",
  "FoodC_c_Unable_to_cPrS": "无法连接服务",
  "FoodC_invoicing_xzCZ_wjMI": "填写错误，请检查后再填写",
  "FoodC_home_Special_note_LSrQ": "特别提示：",
  "FoodC_home__Validity_FDCw": "A: {{entrega_name}}券上会注明“有效期”，该优惠券仅能在注明的有效期内使用，过期优惠券将自动失效不可用。",
  "FoodC_sales_Supplementary_information_ihyk": "补充信息",
  "FoodC_home_The_user_GKZd": "用户通过在线支付、现金支付的方式支付订单，且用户领券时输入的手机号与实际下订单时使用账号登记的手机号为同一号码；",
  "FoodC_demand__3_cTVC": "（3）若因商家未营业、商品售罄、发生意外等原因订单被商家或骑手取消，订单费用将全部退回；",
  "FoodC_saved_Euxs_oQHV": "自2020年1月1日到现在，已经累计为你节省",
  "FoodC_coupon_AcjA_mqeV": "免配券",
  "FoodC_page_Sorry_the_tNDc": "抱歉，平台检测到您的操作存在异常，暂时无法提交，建议您联系客服进行沟通。",
  "FoodC_education_In_the_nIgD": "下列取消订单的情形下，您需承担责任：",
  "FoodC_demand__Let_qvly": "*让骑手先联系您，您来选择上述两种处理方式的其中一种；",
  "FoodC_statement__2_mmNa": "（2）顾客需包装帮送物品符合如下限制，使其完全适宜帮送服务",
  "FoodC_enhance_sGnQ_mmEf": "会员专属",
  "FoodC_invoicing_jOXr_YSAW": "开票中",
  "FoodC_side_AeEL_MHLA": "所有的券",
  "FoodC_education__2_KHhl": "（2）如您超过平台规定的时间未到达交餐点、订单地址填写错误、收餐号码错误、拒接送餐人员电话、无正当理由拒收餐品，您需要支付订单费用",
  "FoodC_education_If_you_RTIV": "您成功支付外卖价款后，如需要取消订单的，将按照如下规则进行：",
  "FoodC_education_2_Receiving_gFZl": "2. 收餐地址、联系方式填写正确；",
  "FoodC_education__3_TDsa": "（3）顾客拒收餐品",
  "FoodC_education_2_If_SoSk": "2. 若骑手未按照平台规范完成交餐，导致交餐纠纷，优先骑手承担责任",
  "FoodC_education__unk_aOCD": "{{delivery_type}}平台规则",
  "FoodC_demand_Purchasing_services_jshb": "代购服务，您可以让骑手帮忙到店购买周边商品，例如：披萨、汉堡、奶茶、咖啡等（具体以商品页面展示为准）。骑手接单后将会尽快购买并送货上门。",
  "FoodC_Later_WRgg_djEL": "立即抢购",
  "Global_Driver_Multiplier_new_interaction_Current_activities_vSRD": "当前活动未开始",
  "sailing.c-api-intl.[商家营销标签分类]_[菜品活动]": "菜品活动",
  "FoodC_store_Store_voucher_NnMx": "商家券已经兑换成功！",
  "FoodC_education__2_jwSo": "（2）放在门口（文字备注）",
  "sailing.c-api-intl.[商家营销标签分类]_[满X减Y]": "满X减Y",
  "FoodC_home_Possible_causes_wopU": "A: 可能的原因和解决办法如下：",
  "FoodC_Later_VHDh_UWSb": "查看所有可用券的门店",
  "GDriver_5_Event_ended_gNeH": "活动已结束",
  "FoodC_c_Try_again_lIZQ": "重试",
  "FoodB_optimization_See_more_rbCl": "查看更多",
  "FoodC_page__Zjra": "优惠券",
  "FoodC_home_If_the_SPtR": "A: 如用户使用了{{entrega_name}}券，取消订单时尚未完成支付，相关优惠券在用户取消订单后将即时退回账户。如用户取消订单时已完成支付，相关优惠券不再退回账户。订单款项将于订单取消后1-30个工作日原路退回支付账户。",
  "FoodC_ServiceFee_EvAo_Aloo": "用户地址",
  "FoodC_Translation_Sign_in_pljT": "请登录",
  "FoodC_statement_If_you_Jbkm": "如发现物品少送、错送、丢失或损坏等情况，请您及时联系客服处理。",
  "FoodC_statement_3_Flash_IEYW": "3、订单取消规则",
  "FoodC_home_No_entrega_GnGI": "A: 不可以。一个账号内的{{entrega_name}}券仅可用于支付该账号内的{{entrega_name}}订单。",
  "FoodC_Later_jqbf_DAWh": "无法配送到当前定位地点的门店",
  "FoodC_education_4_Customers_laxR": "4. 顾客需要骑手到户后10分钟内及时取餐；",
  "FoodC_invoicing_YDSi_TMLw": "税号",
  "FoodC_linkjump_LAQl_lfnD": "在{{DiDi}}中打开",
  "FoodC_cancel_Users_initiate_suVB": "用户可发起取消申请，申请不可撤回，待骑手返件后取消成功，取消仍需支付订单费用",
  "FoodC_demand__1_LhGa": "（1）您可在下单时选择缺货的处理方式，实际发生缺货情况时，骑手会根据您所选择的方式进行处理。",
  "FoodC_statement_pornography_WPQI": "色情材料；",
  "soda-b-homepage-i18n---登录": "登录",
  "FoodC_statement_Value_Items_svYm": "价值：价值不得超过{{value_limit}}的物品",
  "sailing.act-api-intl.去使用": "去使用",
  "FoodC_invoicing_AxaA_RodV": "提交",
  "FoodC_title_Please_choose_yekD": "请选择有问题的餐品",
  "FoodC_saved_nDkp_KPcN": "其他优惠类型",
  "FoodC_sales_Condition_of_lnwg": "收餐时外包装密封品状态（选填）",
  "FoodC_Translation_Successful_exchange_BVbd": "兑换成功！请返回购物车前往{{coupon_name}}券列表查看。",
  "FoodC_home_3_If_BGBo": "3. 如果用户对使用规则或在使用{{brand_name}}软件和/或相关服务过程中有任何疑问或需要任何帮助，请及时与客服联系。",
  "FoodC_statement__4_bDLh": "（4）收件人在收到帮送物品时，应打开包装，及时验收物品的数量和完整性。",
  "FoodC_demand_1_What_aoLM": "1.“骑手代购”服务是什么？",
  "FoodC_page_DIKR_secx": "配送活动",
  "FoodC_invoicing_okhb_GoXI": "我知道了",
  "FoodC_demand__You_bJcT": "*您可选择现金支付，您选择商品并下单，骑手到店购买商品，订单费用在交货时用户向骑手支付现金；",
  "FoodC_issues_Sorry_for_aHCW": "很抱歉给您带来不好的体验，请您联系客服进行解决。",
  "FoodC_invoicing_SvTe_iYZQ": "请填写开票名称",
  "FoodC_education__1_IIfh": "（1）在商家接单前，您可以在订单详情页通过‘取消订单’功能进行取消",
  "FoodC_reasons_Enter_a_Pnzg": "请输入具体原因",
  "FoodC_sales_How_do_ylCn": "怎么判断密封品状态？",
  "FoodC_demand__2_TZJq": "（2）在骑手购买商品后，若订单未产生严重超时的情况，您取消需要支付订单费用；",
  "FoodC_home_No_When_xNrI": "A: 不可以。用户选择使用{{entrega_name}}券支付订单时将优先使用{{entrega_name}}券抵扣订单金额。券金额高于订单金额的，差额部分不可提现、退款、找零、买卖、兑现或转赠他人；订单金额高于券金额的，差额部分由用户支付。",
  "FoodC_education__4_QYXm": "（4）顾客拒绝支付现金单",
  "FoodC_statement__2_Gymp": "（2）如骑手尚未取件，在骑手接单后一定时间内，您可免费取消订单；超过一定时间后，您需要支付取消费；",
  "FoodC_education_In_the_MKeB": "下列取消订单的情形下，您无需承担责任：",
  "FoodC_statement__1_hBNr": "（1）骑手接单后，需保证电话畅通，按时到达取件地址与骑手接头；",
  "FoodC_education__3_ltNM": "（3）因分配不到骑手等平台原因或外部原因导致订单取消的情形",
  "FoodC_statement__1_ZXJA": "（1）发件人需根据页面提示，填写完整且准确的取/送地址、发/收件人联系方式信息，且请如实、正确填写物品信息；",
  "FoodC_invoicing_ubrt_obAj": "服务费开票",
  "FoodC_statement_6_Lost_VmQE": "6、物品丢失/ 损毁规则",
  "FoodC_home_When_the_qNRR": "用户在进行订单支付时网络信号中断或不稳定，可稍后到网络信号稳定的地方再进行支付；",
  "FoodC_Later_abhG_KiUN": "立即购买",
  "FoodC_home_What_are_TUik": "Q: 新用户优惠券的使用要求是什么？",
  "FoodC_statement_Items_prohibited_vdcx": "任何其他相关法律禁止的物品；",
  "FoodC_Later_UYPU_ZvGX": "配送至",
  "FoodC_education_In_order_AZCw": "为了使您在{{brand_name}}服务中有更好的体验，平台特制订取餐规则，以供各方遵守。涉及顾客取餐纠纷的场景，将按照如下规则处理",
  "FoodC_home_Under_what_udiG": "Q: 在什么条件下可以使用{{entrega_name}}券？",
  "FoodC_sales_Not_set_AJmE": "暂不设置",
  "FoodC_home_The_user_DZGn": "用户在“钱包”中有可使用的{{entrega_name}}券；",
  "FoodC_education__2_KZTZ": "（2）骑手联系不上顾客",
  "FoodC_fee_Ukhd_NWfo": "同样门槛和优惠的券仅展示一张，可以去账户内查看全部",
  "sailing.c-api-intl.推荐": "推荐",
  "FoodC_page__KoEd": "展开",
  "FoodC_statement__6_cuGI": "（6）若因骑手取件时发现配送物品不合规，骑手则有权取消，您的物品将无法配送。",
  "FoodC_education_Cancellation_Policy_fxrl": "取消规则",
  "FoodC_statement_Currently_DiDi_jUEl": "目前{{entrega_name}}服务支持帮送服务，即您可以通过在客户端App上下单，让平台骑手帮忙递送物品至指定的周边地点和收件人。骑手接单后会到您指定地址取货，并将物品送往您指定的目的地和收件人。",
  "FoodC_description_The_entire_lTeJ": "整个订单都没收到：",
  "FoodC_statement__3_JHtK": "（3）您作为下单人/发件人需要承担该订单所涉及的费用。如线上支付订单，需在下单时完成订单费用的支付；如现金支付订单，需在骑手取货时将现金支付给骑手。收件人不需要承担或支付任何费用；",
  "FoodC_cancel_When_an_XxLF": "当为线上支付订单时，用户实际支付的取消费，最多不超过实际支付的配送费",
  "FoodC_statement_Dangerous_materials_tLOa": "危险材料或废弃物（易燃液体或固体；在压力下冷藏、液化或溶解的压缩气体；存在毒性、传染性、放射性或腐蚀性的物质）；",
  "FoodC_statement_Type_You_sJki": "类型：用户不得要求{{entrega_name}}服务递送包括但不限于以下被视为违禁物品的对象：",
  "FoodC_statement__5_ZbOp": "（5）如发现物品丢失/ 损毁/ 少送/ 错送，优先与骑手协商赔偿问题，如协商不一致，则需联系客服解决。",
  "FoodC_home_When_placing_UpoJ": "Q: 下单时使用{{entrega_name}}券，订单取消后，{{entrega_name}}券还会返还吗？",
  "FoodC_sales_Tips_LCST": "提示",
  "FoodC_Later_ubvI_oLLU": "以后使用",
  "您的订单还未完成，您可以联系骑手确认当前的具体配送位置。": "",
  "您所在的地区还未开通服务": "",
  "可以把促销码换成钱吗？ ": "",
  "不满意": "",
  "用户使用%s券应同时满足以下条件：": "",
  "怎样才能获得%s券？": "",
  "取消订单进度": "取消订单状态",
  "4、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "",
  "早间：%s-%s": "",
  "跳过": "",
  "个人中心的“优惠券”": "",
  "联系商家处理": "",
  "用户的%s软件版本较低，可在升级%s软件版本后再进行支付。": "",
  "商家正在备餐": "",
  "1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "",
  "申请信息": "",
  "返回首页": "",
  "在极少数情况下，如果您对订单费用产生疑议，可以点击“帮助”联系我们，客服团队会尽快为您解答。": "",
  "我要注册！": "Eu quero me registrar!",
  "你的位置还不在我们服务范围内。我们很快就会扩大我们的覆盖范围，之后你就可以使用%s啦。请点击下面的按钮，我们会在有服务之后通知你。": "",
  "您对商家服务满意吗？": "",
  "现金": "",
  "用户不符合%s券使用规则中列明的可以使用券的条件，应仔细阅读并保证符合优惠券注明的使用条件；": "",
  "%s券上会注明“有效期”，该优惠券仅能在注明的有效期内使用，过期优惠券将自动失效不可用。": "",
  "将赔付给您1张%s优惠券": "",
  "1.店铺优惠指的是“满X减Y”活动。": "",
  "满%s可用": "",
  "订单": "",
  "店铺优惠说明": "",
  "订单取消成功": "",
  "目前没有限制。优惠券有使用限制，您可以查看下方优惠券规则，了解一天可以使用多少优惠券。": "",
  "一个用户一天内最多能够使用几张%s券？": "",
  "仅限%s使用": "",
  "您对商家服务的评价": "",
  "恶劣天气及运力紧张情况下，配送费根据实际情况略有浮动。": "",
  "进度详情": "",
  "平台优惠": "",
  "使用规则": "",
  "2.“满X减Y”和“特价菜”、“优惠套餐”活动优惠可同享。": "",
  "反馈超时": "",
  "促销码与促销活动相关，有有效期，超过有效期会兑换失败。": "",
  "可以用一个账号内的%s券为其他账号内的订单进行支付吗？": "",
  "获得权益": "",
  "我知道了": "",
  "查看已开通的区域": "",
  "提交": "",
  "您的问题我们已经收到，将在48小时内给您反馈，并以短信/邮件通知您，请耐心等待。": "",
  "小费金额 = 餐品总价 * 小费比例，你可以点击去选择你的小费比例。（餐品总价不包含配送费和优惠券）": "",
  "订单尚未完成": "",
  "补充更多信息，以便快速处理（选填）": "",
  "3.同一商家，最多可以有3个“满X比索减Y比索”活动，自动为您选择优惠力度最大的折扣。": "",
  "更多商家即将上线，点击“请通知我”商家上线时我们将第一时间通知你": "",
  "用户应当出于合法、正当目的以合理方式使用%s券。用户不得单独或与商家恶意串通，利用%s券实施欺诈等非法行为以达到套取现金、获得积分或礼品等目的。且用户不得利用%s券进行售卖、兑换或从事其他营利性活动。如果平台发现用户存在前述违法违规行为，%s有权拒绝该用户使用%s券和/或对该用户账号和/或关联账号采取临时或永久停止平台服务等措施，并保留通过法律途径追究用户法律责任的权利。": "",
  "由于用户未实际支付%s券抵用部分订单金额，因此用户不能获得%s券抵用部分金额的发票。": "",
  "本次服务评价成功": "",
  "提交申请": "申请取消",
  "取消": "",
  "请选择取消原因以便商家更快帮您处理": "",
  "钱包": "",
  "怎么样开通免密支付？": "",
  "什么是%s券？": "",
  "谢谢。我们会及时通知你": "",
  "退款金额": "",
  "通用支付": "",
  "促销码是字母和数字的组合。": "",
  "6、每位用户每天最多赔付6张优惠券；": "",
  "感谢您提前注册账号！我们将会及时为您发送最新的滴滴外卖活动信息。我们的服务即将开始，请拭目以待！": "Obrigado por se registrar com antecedência! Enviaremos as atividades mais recentes de Didi para entrega em tempo hábil. Nosso serviço começará em breve. Aguarde e veja!",
  "用户在开通%s服务的城市和/或地区下外卖订单；": "",
  "促销码由什么组成？": "",
  "能导致%s券不能使用的原因和解决办法是什么？": "",
  "请您上传所收到的全部商品照片、问题商品细节照片、商品小票照片，这将协助我们更快地处理您的问题。": "",
  "更多评价": "",
  "99Pay-余额": "",
  "详细说明": "",
  "好的，我知道了": "",
  "4、部分时间段下单暂不参加赔付；": "",
  "您的免配送费权益将于%s过期": "",
  "提交反馈": "",
  "帮助中心": "",
  "6、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "",
  "获得新用户立减活动资格的新用户，是指第一次使用%s并成功完成订单的用户。新用户优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。": "",
  "%s公里之内配送费": "",
  "我们很快会扩大服务范围，点击“请通知我”，我们将会在开通时通知你": "",
  "支付配送费": "",
  "商家自行配送的配送费收取规则：": "",
  "促销码是什么？": "",
  "仅限支付卡bin为%s可用": "",
  "请告诉我们，帮助我们一起改善": "",
  "基础配送费": "",
  "当有配送费活动时，根据活动情况对配送费进行相应的优惠。": "",
  "申请取消原因": "",
  "打开": "",
  "查看评价": "",
  "%s券可以找零或兑换吗？": "",
  "收起": "",
  "5、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "",
  "优惠券": "",
  "不可用原因": "",
  "(2) 因您的配送地址所在地不允许骑手入内，导致超时送达或无法送达的；": "",
  "网络不佳，请检查您的网络": "",
  "周五": "",
  "您可以使用现金支付订单，请备好足够的零钱。": "",
  "周二": "",
  "最高抵扣%s": "",
  "特殊说明": "",
  "现金支付": "",
  "已发起取消，等待商家确认": "",
  "3、恶劣天气（雨、雪等）暂不参加赔付；": "",
  "兑换优惠": "",
  "现在订餐": "",
  "如用户使用了%s券，取消订单时尚未完成支付，相关优惠券在用户取消订单后将即时退回账户。如用户取消订单时已完成支付，相关优惠券不再退回账户。订单款项将于订单取消后一周内原路退回支付账户。": "",
  "用户未违反平台外卖券使用规则，且该笔订单不属于平台判定的作弊或类似作弊订单等禁用%s券的情况。": "",
  "我们正在努力拓展范围。如果您希望 %s 的服务尽快到您身边，请告诉我们": "",
  "已经累计为您节省了%s配送费": "",
  "%s致力于通过无密码和现金支付流程为我们的客户提供简单安全的付款体验。": "",
  "用户兑换促销码的次数限制是多少？": "",
  "配送费受多种因素影响，包括您和餐厅之前的距离，配送的最终地址和该地区的供需情况。": "",
  "免配送费权益": "",
  "%s平台配送费收取规则：": "",
  "不可以。一个账号内的%s券仅可用于支付该账号内的%s订单。": "",
  "部分餐品缺失- 表示骑手已经给您交餐，表示骑手已经给您交餐，但您有部分餐品没有收到。": "",
  "如果对费用提出争议，将如何解决？": "",
  "（必填）请填写您的申诉原因": "",
  "2、部分时间段下单暂不参加赔付；": "",
  "很抱歉给你造成了不好的体验，请选择您遇到的问题：": "",
  "请通知我": "",
  "送您1张%s优惠券；": "",
  "恭喜你！兑换成功！": "",
  "用户绑定了银行卡或使用现金支付；": "",
  "您的订单为商家配送，您可点击下方的联系商家按钮与商家进行问题协商。": "",
  "同一个促销码可以重复兑换么？": "",
  "%s券抵用餐费部分可以开发票吗？": "",
  "已过期券": "",
  "有效期至": "",
  "他们已加入%s": "",
  "卡": "",
  "用户支付外卖订单时使用%s券并支付成功；": "",
  "周四": "",
  "一个外卖订单最多只能使用一张%s平台券。如：品类券与配送费券不可同时使用。": "",
  "促销码有有效日期嘛？": "",
  "你可以在个人中心的“优惠券”或“钱包”-“优惠券”中兑换它。": "",
  "%s平台处理": "",
  "由配送商家决定配送金额。": "",
  "晚间：%s-%s": "",
  "中查看": "",
  "配送费详情": "",
  "将赔付给您1张%s折扣券": "",
  "送您%s张免配送费券；": "",
  "正在寻找骑手": "",
  "反馈部分餐品缺失": "",
  "3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。": "",
  "优惠券使用规则": "",
  "验证成功": "",
  "%s券是%s发放给用户，用户在支付%s订单时，用于抵扣订单金额的电子券。": "",
  "提交成功": "",
  "用户在“钱包”中有可使用的%s券；": "",
  "延迟送达%s分钟以上": "",
  "您的小费将全部支付给骑手": "",
  "让 %s 进入我的地区": "",
  "在支付方式中添加您的银行卡，成功绑定后即可开通免密支付。": "",
  "兑换失败": "",
  "我们正在努力拓展中。您可以访问周边热门商圈看看美食": "",
  "满意": "",
  "再等等": "再等等",
  "骑手正在配送中": "",
  "周六": "",
  "描述": "",
  "5、每位用户每天最多赔付6张优惠券；": "",
  "2.点击选择“浏览器”，进行下载": "",
  "%s将不定时地通过在本页面发布更新版本的方式修改使用规则。请用户定期访问本页面并查看最新版本。这些规则对用户均具有约束力。": "",
  "无效券": "",
  "/公里": "",
  "当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "",
  "出生日期": "",
  "餐费": "",
  "完成注册登录，以便开通服务后通知您": "",
  "不使用优惠券": "",
  "请上传商品和小票照片": "",
  "商家优惠": "",
  "你好": "",
  "CPF": "",
  "附近商家正在筹备中": "",
  "失败": "",
  "验证失败": "",
  "不可以。用户选择使用%s券支付订单时将优先使用%s券抵扣订单金额。券金额高于订单金额的，差额部分不可提现、退款、找零、买卖、兑现或转赠他人；订单金额高于券金额的，差额部分由用户支付。": "",
  "不可以，兑换出的优惠券可以根据优惠券的使用规则，抵扣部分订单费用。": "",
  "(1) 您要求更换收获地址、您提交的地址错误、联系不上您或无人收货、拒收等导致订单超时送达或无法送达的；": "",
  "立即兑换": "",
  "用户在进行订单支付时网络信号中断或不稳定，可稍后到网络信号稳定的地方再进行支付；": "",
  "服务费+里程费": "",
  "什么是%s券的“有效期”？": "",
  "目前我们支持信用卡，借记卡的线上支付，以及现金和pos机的线下支付。": "",
  "OFF": "",
  "下单时使用%s券，订单取消后，外卖券还会返还吗？": "",
  "暂无优惠券": "",
  "1.点击右上角菜单": "",
  "联系骑手": "联系骑手",
  "去看看已开通地区": "",
  "在哪里可以兑换促销码？": "",
  "关闭": "",
  "下载": "",
  "用户通过在线支付、现金支付的方式支付外卖订单，且用户领券时输入的手机号与实际下订单时使用账号登记的手机号为同一号码；": "",
  "更多问题": "",
  "pos机支付": "",
  "随时随地享美食": "",
  "感谢你的支持，有新商家上线会第一时间通知你": "",
  "周日": "",
  "去周边看看": "",
  "请选择有问题的商品": "",
  "将赔付给您%s张免配送费券": "",
  "保持联系！": "",
  "处理结果将短信通知您": "",
  "周三": "",
  "新用户优惠券的使用要求是什么？": "",
  "周一": "",
  "商家配送": "",
  "订单实际送达时间晚于预计送达时间%s分钟以上，": "",
  "取消订单": "取消订单",
  "您可以使用信用卡或储蓄卡通过pos机支付订单，请确保您的卡是可用的。": "",
  "成功": "",
  "您对配送服务的评价": "",
  "输入兑换码": "",
  "展开": "",
  "评价本次服务": "",
  "兑换失败，请再试一次": "",
  "商家已开始制作美食，拒绝了您的取消订单申请": "",
  "已收到你的申请": "",
  "7、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "",
  "整个订单都没收到- 表示骑手没有为您配送订单，您没有收到任何餐品。": "",
  "对处理结果不满意？": "",
  "抱歉，你所在的区域不支持配送": "",
  "2、超时赔付服务由平台为用户提供，用户没有支付任何费用，若订单取消则服务取消。": "",
  "隐藏": "",
  "更多详情": "",
  "app内可以支持哪些支付方式？": "",
  "订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": "",
  "促销码是由系统生成的，可以通过促销码兑换优惠券。": "",
  "有效期至%s": "",
  "%s将不定期推出多样的优惠活动，如：新老用户满减优惠券、通过分享获得红包优惠券等，具体活动均将在本平台第一时间推送，或以短信形式联系您，您可随时关注相关活动。": "",
  "配送费计费规则": "",
  "已经收到你的申请": "",
  "仅限新用户使用": "",
  "送您1张%s折扣券；": "",
  "退款渠道": "",
  "配送费": "",
  "联系客服": "联系客服",
  "联系客服进行申诉": "",
  "确认": "",
  "4月7号起，滴滴外卖将在大阪开始试运营啦！您可以在滴滴外卖App上找到您喜欢吃的各式当地美食。我们的服务上线大促活动即将开始，快点击下方按钮，不错过我们的优惠活动！": "A partir de 7 de abril, Didi Takeaway começará a operação experimental em Osaka! Você pode encontrar todos os tipos de comidas locais que você gosta no aplicativo Didi takeaway. Nosso evento de promoção de lançamento de serviços está prestes a começar. Clique no botão abaixo e não perca nossa oferta especial!",
  "当前区域暂未开通服务": "",
  "超时赔付规则说明": "",
  "如果用户对使用规则或在使用%s软件和/或相关服务过程中有任何疑问或需要任何帮助，请及时与%s客服联系，": "",
  "支付信息": "",
  "平台配送": "",
  "您为什么取消？": "",
  "一个外卖订单最多能够使用几张%s券？": "",
  "(3) 其他因您的原因导致骑手超时送达或无法送达的。": "",
  "小费说明": "",
  "更多地区入驻中……": "",
  "配送费收取标准": "",
  "%s~%s公里之间配送费": "",
  "用户的外卖订单符合%s券券面注明的使用条件，如：餐品价格加餐具费满足该外卖优惠券所属品类、满减金额及有效使用时间限制；": "",
  "超出%s公里部分收取标准": "",
  "可能的原因和解决办法如下：": "",
  "促销码分为可以兑换一次的和可以兑换多次的，实际可兑换次数以促销码活动规则为准。": "",
  "免费取消订单": "",
  "可抵扣%s": "",
  "骑手没有配送我的订单": "",
  "仅限%s": "",
  "3、您因超时赔付服务获得的优惠券，将发放至您的%s账户，请在": "",
  "我的优惠": "",
  "您可以点击下方按钮和骑手联系交餐。": "",
  "您总是能在点餐之前确认该订单的配送费。": "",
  "特别提示: ": "",
  "POS": "",
  "骑手已到达": "",
  "大阪，出发！": "Osaka, vá!",
  "等待商家接单": "",
  "%s可用": "",
  "商家正在入驻中": "",
  "1、超时赔付服务是%s为您免费提供的服务，延迟送达时间及赔付优惠券金额如下：": "",
  "上报问题": "",
  "您的订单完成已经超过24小时，无法自助申请，如您有问题可联系客服，给您带来不便深表歉意。": "",
  "商家拒绝取消申请": "",
  "联系商家": "联系商家",
  "一个用户一天内使用的%s券数量之和不得超过两张，各优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。同一个账号、手机号、支付账号、设备以及其他相同或相似身份信息，均视为同一用户。": "",
  "1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费不退还。 2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。": "",
  "感谢你的支持，区域开通后会第一时间通知你": "",
  "在什么条件下可以使用%s券？": "",
  "您对配送服务满意吗？": "",
  "温馨提示": ""
}