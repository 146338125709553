module.exports = {
  "FoodC_statement__1_eIic": "(1) After the courier has picked up the package, the recipient must be available to answer their phone and be present at the dropoff location to meet the courier on time.",
  "FoodC_Conciliation_Operation_failed_wEjU": "Error, please try again",
  "FoodC_invoicing_jCYw_YcPH": "Once issued, an electronic official receipt will be sent to your email. Please provide the following information:",
  "FoodC_cancel_Cancellation_Policy_rnAp": "Cancellation Policy",
  "FoodC_Later_TWbh_AXWk": "Payment successful",
  "FoodC_home_The_user_ElUo": "You must link a bank card or pay with cash;",
  "FoodC_demand__Do_CKdn": "* Continue to buy all items aside from those that are out of stock.",
  "FoodC_side_Tyor_qIoH": "T&C",
  "FoodC_demand_Courier_Purchasing_eTAr": "Shopped by Courier",
  "FoodC_cancel__free_BQFd": "Customer may cancel for free within {{free_time}} min(s) after the courier has accepted the order",
  "FoodC_invoicing_HPuN_MOyc": "Email",
  "FoodC_store_Store_Coupon_szoR": "Store Coupons",
  "FoodC_home_The_entrega_uOPx": "You must successfully complete payment using a coupon for your order.",
  "FoodC_Later_XtdO_YxTQ": "The coupon(s) you purchased are in the Coupon Center. Follow the steps below to view them",
  "FoodC_statement__4_YGAs": "(4) Aside from fees associated with cash orders, you do not need to pay any other fees to the courier.",
  "FoodC_sales_Confirmation_mTGd": "Got It",
  "FoodC_page_vsqt_uXcI": "Delivery Coupons",
  "FoodC_Noavailablestore_zVkf_urAN": "Back to Homepage",
  "FoodC_2__sybX": "There are no more coupons left today. Please try again next time",
  "FoodC_statement__2_ZtHa": "(2) When handing over the package to the courier, the sender must verify the courier’s identity. The sender assumes all liability if the package is provided to someone other than the specified courier;",
  "FoodC_demand__1_jqYB": "(1) Simply enter the delivery address, recipient name, and the items you want, then check out. A courier will buy the items from your chosen store or another store nearby:",
  "FoodC_education_1_Customers_Gpbb": "1. Before placing an order, you will be asked to choose your preferred delivery option. The courier will then deliver your order to you using your selected option:",
  "FoodC_title_Upload_Image_VSGW": "Photo Instructions",
  "FoodC_side3_xeOS_SWLO": "Available for {{name}}",
  "FoodC_education__1_mJTG": "(1) If the store has already confirmed and accepted the order, you can use the ‘Cancel Order’ feature to contact the store and ask to cancel the order. If the store does not agree to cancel the order because they have already finished preparing it, or other reasonable cause, you will not be able to cancel the order for free. If you insist on cancelling or refuse to accept all or part of the order, you will be responsible for paying all relevant order fees.",
  "FoodC_order__2_UYRU": "(2) Your order total will be shown when checking out. If the amount paid by the courier is significantly less than the order total, you will be issued a partial refund automatically. If the actual price is higher than the amount you paid, you won’t be charged anything extra",
  "FoodC_invoicing_MIEP_sDYJ": "Please enter the tax ID",
  "FoodC_sales_Confirmation_jtNg": "OK",
  "FoodC_feedback_I_received_UNiy": "I received someone else’s order",
  "FoodC_home_DiDi_zojp": "DiDi",
  "FoodC_sales_3_Display_GYtM": "3. You opted to pay for this order in cash. If your refund request is approved, the refund amount will be added to your Wallet.",
  "FoodC_Later_TVZu_RjZF": "Check out",
  "FoodC_demand__2_ALrB": "(2) Your order total will be shown when checking out. If the courier is charged a different amount, you won’t be charged anything extra (or refunded the difference).",
  "FoodC_invoicing_epVj_kTpl": "Receipt Name",
  "FoodC_home_Orders_are_EEjN": "You must place a {{entrega_name}} order in a city and/or region where it is available;",
  "FoodC_cancel_Free_order_WqpG": "Customer can cancel order for free",
  "FoodC_education_3_If_wduh": "3. If a delivery dispute is caused by reasons other than human factors, such as a product issue or bad weather, we’ll take responsibility",
  "FoodC_home_1_The_SWqc": "1. You shall use {{entrega_name}} coupons in a reasonable manner for legitimate and legal purposes only. You are prohibited from maliciously using {{entrega_name}} coupons to commit fraud and other illegal acts in order to obtain cash, points or gifts. You are prohibited from selling or exchanging {{entrega_name}} coupons, or using the coupons for other profit-making activities. If {{brand_name}} discovers that you have committed any of the aforementioned violations of laws and regulations, {{entrega_name}} has the right to refuse your use of {{entrega_name}} coupons and/or take measures such as temporarily or permanently suspending your account or associated account and your ability to access {{brand_name}} services. We also reserve the right to take legal action against you through legal channels.",
  "FoodC_seals_Outdoor_packaging_MGso": "Was item packaging intact when received?",
  "FoodC_demand_2_How_DjaQ": "2. How does Shopped by Courier work?",
  "FoodC_activities_ZYMp_mFfB": "Payment Events",
  "FoodC_c_Please_try_cHSE": "Please try again later",
  "FoodC_Later_uAfu_eSHi": "Please select your address",
  "FoodC_statement__3_gBJT": "(3) Once the courier has picked up the package, the order fee will not be refunded if you cancel;",
  "FoodC_home_The_following_vTNS": "A: When using {{entrega_name}} coupons, you must satisfy the following conditions:",
  "FoodC_RCOUPON_Coupon_reduction_PqSi": "Special Food Coupon",
  "FoodC_statement__2_Rrwp": "(2) After the courier has picked up the package, both the sender and recipient will receive a Delivery PIN; if the recipient does not receive the Delivery PIN via SMS, the sender can share the delivery status and Delivery PIN to the recipient from the Order Details page, or contact Customer Service to confirm the Delivery PIN. To ensure secure package delivery, please do not share the Delivery PIN with the courier or anyone else until package is received.",
  "FoodC_demand_3_What_zsYM": "3. What if an item I want is out of stock?",
  "FoodC_education_In_order_SRIb": "This Cancellation Policy was formulated to help all users enjoy a fair and reliable experience when using {{brand_name}}.",
  "FoodC_feedback_2_The_ntBT": "2. For a full refund, your entire payment will be refunded in full. If you used any coupons on your order, they will be returned to your Wallet.",
  "FoodC_statement_2_How_LmUD": "2. How do I use {{entrega_name}}?",
  "FoodC_Later_ZBBS_XWul": "Account > Coupon Center",
  "FoodC_Later_GwXN_BQcj": "Nearest Store",
  "FoodC_deeplinkpageshow_wcmD_CuSi": "Please select an app on your device to open the page",
  "FoodC_sales_If_the_TsPm": "To help us verify the issue with the store and courier, please let us know if the item was delivered in resealable packaging, or sealed in another way (like with staples).",
  "FoodC_invoicing_QueK_zCcn": "Official receipt issued",
  "FoodC_cancel_Courier_has_LQtM": "Order accepted by courier",
  "FoodC_education__2_uVUK": "(2) The store you ordered from is closed, an item is sold out, an accident occurs, or the order is cancelled by the store or courier for another reason, in which case you will receive a full refund.",
  "soda-d-i18n---没有更多了": "No more results",
  "FoodC_demand__You_yjru": "* Pay in the app: You can make payment in the app when placing an order, then the courier will buy the items you ordered and deliver them straight to your door.",
  "FoodC_home_The_user_vjgt": "You may be using an out of date version of the {{brand_name}} app. Update {{brand_name}} to the newest version, then try again.",
  "FoodOpD_status_Store_OCbV": "Store",
  "FoodC_home_The_user_lsyI": "You must follow our Terms of Use when using a {{entrega_name}} coupon, and your order must not be determined to be fraudulent when using the coupon.",
  "FoodD_one_Please_try_VyNK": "Please try again later",
  "FoodC_demand__If_PNsp": "* Cancel the entire order if an item is out of stock.",
  "FoodC_statement__3_PxyO": "(3) After the courier has picked up the package, if delivery cannot be completed due to a reason involving the recipient (including, but not limited to, the recipient declining to receive the package, the recipient not being able to be contacted, or the recipient’s address being incorrect), the right of disposal of the package shall revert to the sender, and any related service fees arising therefrom shall be borne by the sender.",
  "FoodC_statement_Animals_people_IccN": "Animals, persons, weapons or explosives;",
  "FoodC_home_2_brand_vxUa": "2. {{brand_name}}'s Terms of Use will be revised from time to time and updates will be published on this page. You shall visit this page regularly and check for any updates. These rules are binding on users.",
  "FoodC_saved_ZHnz_OjnQ": "Original price",
  "FoodC_Translation__brand_LAYp": "{{brand_name}} Coupons",
  "FoodC_Later_BYic_nYYd": "Use later",
  "FoodC_cancel_When_the_PAQq": "If multiple cancellation attempts are detected involving your account, you won’t be able to cancel the order directly, but you can contact Customer Service for assistance",
  "FoodC_demand__4_HJNa": "(4) Actions may be taken against any violations.",
  "sailing.c-api-intl.[提单页]_[平台券]": "DiDi Food Coupons",
  "FoodC_demand__3_tQmA": "(3) Actual pricing varies depending on your area.",
  "FoodC_statement__4_Nghy": "(4) If the package cannot be delivered because the courier is unable to contact the sender / recipient, the pickup / dropoff location is incorrect, or other reason, the order fee will not be refunded if you cancel, and you will have to contact the courier to negotiate a time and place for returning or re-delivering the package;",
  "FoodC_education_3_Customers_Zoec": "3. You should have access to your phone during delivery to ensure the courier can get in touch with you if needed.",
  "FoodC_invoicing_yJyj_kAmJ": "The official receipt has been sent to your email",
  "FoodC_update_The_expected_ZzFg": "The estimated delivery time is based on the latest estimated arrival time.",
  "FoodC_statement_Currency_or_drqX": "Currency or negotiable instruments;",
  "FoodC_demand__In_XsxW": "* If there’s a dispute involving an out of stock item, DiDi will use this policy to determine who’s responsible.",
  "FoodC_Later_otla_noJA": "My Coupons",
  "FoodC_Later_wFZx_DICJ": "Usage rules",
  "FoodC_education__1_vwgm": "(1) Deliver to door",
  "FoodC_page_Contact_customer_rJbM": "Please Contact Customer Service",
  "FoodC_Later_Jtbt_KBCI": "Daily purchase limit reached",
  "FoodC_Later_XpNu_TSXn": "Tap the Order button",
  "FoodC_home_What_is_tlHh": "Q: What are {{entrega_name}} coupons?",
  "FoodC_home_The_user_jRpX": "Your order must meet the coupon usage conditions.",
  "FoodC_education__1_qvVC": "(1) You don’t pick up your order in time",
  "FoodC_statement_Jewelry_vtUc": "Jewelry;",
  "FoodC_saved_AlLL_CHtd": "Price paid",
  "FoodC_statement_Psychotropic_drugs_XUfp": "Psychoactive drugs or narcotics;",
  "FoodC_home_What_is_NZVx": "Q: What is the “validity period” of a {{entrega_name}} coupon?",
  "FoodC_statement_Weight_The_kJIL": "Weight: The maximum permitted weight of a package is {{weight_limit}} kg",
  "FoodC_education_Meal_picking_ziun": "Delivery Rules",
  "FoodC_Later_tuMj_hRvh": "Switch Store",
  "FoodC_saved_jKhv_Cwtv": "Coupon packs",
  "FoodC_perception__XmZq": "Back to Homepage",
  "FoodC_demand__1_EzSJ": "(1) If the courier hasn't bought any items yet, you can cancel your order for free.",
  "FoodC_statement_4_Rules_XuZc": "4. Guidelines for Package Pickup",
  "FoodC_home_How_do_xoCZ": "Q: How do I get {{entrega_name}} coupons?",
  "FoodC_education_5_Cash_zBuY": "5. If you place a cash order, ensure you have enough cash on hand to pay for the order before accepting delivery.",
  "FoodC_cancel_Reason_for_vfFe": "Reason for cancellation",
  "FoodC_home1_DiDi_Delivery_jard": "DiDi Entrega",
  "FoodC_home_Can_you_qvdx": "Q: Can {{entrega_name}} coupons in one account be used to pay for an order in another account?",
  "FoodC_home_A_entrega_sgzW": "A: Only one {{entrega_name}} coupon can be used per {{entrega_name}} order.",
  "FoodC_Later_bsYo_QDtF": "Add item(s) to cart",
  "FoodC_home_Can_the_Jjzi": "Q: Can I receive an official receipt for the Delivery Fee total offset by the {{entrega_name}} coupon?",
  "sailing.c-api-intl.[提单页]_[商家券]": "Store Coupons",
  "FoodC_Later_rnmL_Piol": "Instructions",
  "FoodC_linkjump_jhdQ_iztH": "Open in {{DiDiFood}}",
  "FoodC_page_WkPn_SZWm": "Discount Coupons",
  "FoodC_home__entrega_HFmI": "A: {{entrega_name}} will launch promotions from time to time, like discounts for meeting a minimum spending amount for new and existing customers and reward coupons for sharing content. You will be notified of specific promotions at the earliest possible time in the app or via SMS.",
  "FoodC_feedback_Order_issues_wWOH": "Order Issues",
  "FoodC_page_llxi_vTBV": "Store Promos",
  "FoodC_demand_4_Courier_ReDA": "4. Shopped by Courier Cancellation Policy",
  "FoodC_saved_UjVo_BAJJ": "No more data",
  "FoodC_cancel__free_pPaN": "Customer may cancel for a {{cancellation_fee}} Cancellation Fee more than {{free_time}} min(s) after the courier has accepted the order",
  "FoodC_cancel_Order_accepted_DmOH": "Waiting for courier to accept",
  "FoodC_cancel_Wait_uNLX": "Not Now",
  "FoodC_feedback_3_When_OcDb": "3. You opted to pay for this order in cash. If your refund request is approved, the refund amount will be returned to you in the form of a coupon added to your Wallet.",
  "FoodC_statement_5_Rules_eBJs": "5.  Guidelines for Delivery Confirmation",
  "FoodC_home_What_are_DhsJ": "Q: In which circumstances might a {{entrega_name}} coupon become unusable, and how can these situations be resolved?",
  "FoodC_home_Because_the_geRF": "A: Since you are not actually making a payment on the portion of the order total offset by a {{entrega_name}} coupon, you cannot receive an official receipt for the portion covered by the {{entrega_name}} coupon.",
  "FoodC_description_Partial_meal_JWNU": "Part of the order was missing:",
  "FoodC_statement_Volume_The_wFKH": "Dimensions: The length, width and height of a package cannot exceed {{length_limit}} cm x {{width_limit}} cm x {{height_limit}} cm",
  "FoodC_Translation__unk_axrk": "{{brand_name}} coupon redeemed!",
  "FoodC_demand__3_hmpb": "(3) Refund policy for out of stock items:",
  "FoodC_Later_ePJi_KUku": "You currently don’t have any available coupons. Try switching your location",
  "FoodC_home__entrega_VWeG": "A: {{entrega_name}} coupons are electronic vouchers issued by {{entrega_name}} to customers to offer a discount on the order amount of {{entrega_name}} orders.",
  "FoodC_statement_Drugs_controlled_drMh": "Controlled or restricted drugs;",
  "FoodOpC_Notifications_Full_service_HBkd": "All cities",
  "FoodC_home_Can_entrega_mdgU": "Q: Can I receive change back when using {{entrega_name}} coupons or exchange them for cash?",
  "FoodC_home_Users_who_Xjqh": "Check carefully that your order meets all of the conditions of the {{entrega_name}} coupon;",
  "FoodC_statement_1_What_SFNM": "1. What is {{entrega_name}}?",
  "FoodC_page__iyoe": "Show Less",
  "FoodC_education_1_If_iWDm": "1. If you’re unable to meet the above requirements for accepting delivery, you may be held responsible in the event of a delivery dispute. This includes, but is not limited to, situations where:",
  "FoodC_statement_Laboratory_samples_AmoJ": "Laboratory samples;",
  "FoodC_home_A_new_TGvz": "A: To qualify for a new customer discount, a customer must be using {{entrega_name}} for the first time and successfully complete an order.",
  "FoodC_saved_FWIR_qHYd": "{{app_name}} does not charge any service fees",
  "FoodC_Later_cyVs_VahD": "Order Now",
  "FoodC_side_UqUs_JnwO": "Promo Code",
  "FoodC_Noavailablestore_mGUe_RipS": "There are no stores nearby participating in this promotion",
  "Groceries_payment_AeNZ_HMSX": "Continue",
  "FoodC_C_spYG_gGNu": "Actual refund amount is subject to review by the store or the app",
  "FoodC_ServiceFee_LQae_RZIk": "Please enter your address",
  "FoodC_demand_5_there_jISx": "5. I have a question about store information, what should I do?",
  "FoodC_page__tlAr": "Store Promotions",
  "FoodC_issues_Contact_customer_Anvy": "Contact Customer Service",
  "FoodC_cancel_Special_circumstances_aOpL": "Special circumstances",
  "FoodC_invoicing_DqEG_YcKx": "Please enter the email",
  "FoodC_home_Terms_of_ZXoU": "Terms of Use and Privacy Policy",
  "FoodC_home_How_many_mego": "Q: How many {{entrega_name}} coupons can I use per {{entrega_name}} order?",
  "FoodC_sales_Remember_my_kvqE": "Remember my choice and use this as my default refund method in future.",
  "FoodC_home_How_many_hRpj": "Q: How many {{entrega_name}} coupons can a customer use in a single day?",
  "Gattaran_coupon_Store_Coupon_xLBA": "Store Coupon",
  "FoodC_education_Principles_of_RKEk": "Rules for Handling Delivery Disputes",
  "FoodC_Later_ZBxz_opnp": "Use Now",
  "FoodD_one_Try_again_qZka": "Try Again",
  "FoodC_Later_vuZG_sxUt": "No coupons",
  "FoodD_one_Service_not_eFvs": "Service not connected",
  "FoodC_feedback_It_was_ZrMP": "Entire order was wrong",
  "FoodC_statement__1_wZhz": "(1) If your order is not accepted by a courier after the specified waiting period, you may cancel the order for free;",
  "FoodC_cancel_Courier_picked_obrP": "Order picked up by courier",
  "FoodC_name_DiDi_Flash_TAPX": "DiDi Entrega",
  "Entrega_name_99_Flash_maAd": "99Entrega",
  "FoodC_education_Basic_requirements_hIbv": "Basic Delivery Rules for Customers",
  "FoodC_cancel_Cancel_the_MyBx": "Status",
  "FoodC_demand__2_eSAk": "(2) If an item is out of stock, you can:",
  "FoodC_description_Indicates_that_MWBQ": "The courier didn’t deliver the order, so you didn’t receive any items.",
  "FoodC_statement__5_eCLd": "(5) If your order is cancelled due to an accident, vehicle malfunction or other reason involving the courier while on the way to pick up the package, another courier will be assigned automatically to pick up the package for you;",
  "FoodC_home_The_sum_zzUZ": "A: Each customer can use up to three {{entrega_name}} coupons a day per account. Using the same account, phone number, payment method, device or any other similar identifiers are considered to be the same account.",
  "FoodC_description_Indicates_that_fnET": "The courier delivered the order, but one or more items weren’t received.",
  "FoodC_feedback_1_When_buWL": "1. For a partial refund, the refund amount is the total amount for items that you requested a refund for, minus any prorated discounts. Delivery Fee and Tip are nonrefundable.",
  "FoodC_Later_FVUi_pcGM": "This event has ended. Stay tuned for the next one!",
  "FoodC_demand__If_qODk": "* If you choose to buy the remaining items that are in stock, you’ll be automatically refunded for the out of stock items if you paid in the app. For cash orders, you don't need to pay for items that are out of stock.",
  "FoodC_demand_If_you_SpKh": "If you have any questions about the information of a store, please contact Customer Service at {{Customer_Service_Contact_Number}}",
  "FoodC_c_Unable_to_cPrS": "Service Unavailable",
  "FoodC_invoicing_xzCZ_wjMI": "Invalid information. Please check and re-enter",
  "FoodC_home_Special_note_LSrQ": "Important:",
  "FoodC_home__Validity_FDCw": "A:{{entrega_name}} coupons have a specified “validity period”. Coupons can only be used during the validity period, after which the coupon will automatically become invalid.",
  "FoodC_sales_Supplementary_information_ihyk": "Additional Details",
  "FoodC_home_The_user_GKZd": "You must pay for the order either through the app or with cash. The phone number you entered when using the coupon in the order must match the phone number linked to your account.",
  "FoodC_demand__3_cTVC": "(3) If the store you ordered from is closed, an item is sold out, an accident occurs, or the order is cancelled by the store or courier for another reason, you’ll receive a full refund.",
  "FoodC_saved_Euxs_oQHV": "Since 1/1/2020, you’ve saved a total of",
  "FoodC_coupon_AcjA_mqeV": "100%off Delivery Fee Coupon",
  "FoodC_page_Sorry_the_tNDc": "Sorry, we are unable to process your request due to unusual activity. For assistance, please contact Customer Service.",
  "FoodC_education_In_the_nIgD": "You will be responsible for a cancellation in the following situations:",
  "FoodC_demand__Let_qvly": "* Ask the courier to contact you, then select one of the choices above.",
  "FoodC_statement__2_mmNa": "(2) The sender must ensure that the package meets the following packing requirements in order to be suitable for delivery:",
  "FoodC_enhance_sGnQ_mmEf": "Members Only",
  "FoodC_invoicing_jOXr_YSAW": "Issuing",
  "FoodC_side_AeEL_MHLA": "All Coupons",
  "FoodC_education__2_KHhl": "(2) If you do not arrive at the delivery location by the specified time, or if you enter the wrong delivery address or phone number, refuse to answer a call from the courier, or refuse delivery in whole or in part without a valid reason, you will be required to pay for the order.",
  "FoodC_education_If_you_RTIV": "If you paid for an order and need to cancel it, the following rules will apply:",
  "FoodC_education_2_Receiving_gFZl": "2. You should ensure that the delivery address and contact method you provide are correct.",
  "FoodC_education__3_TDsa": "(3) You refuse to accept the order in whole or in part",
  "FoodC_education_2_If_SoSk": "2. If a delivery dispute occurs because the courier did not follow our rules, we may hold the courier responsible",
  "FoodC_education__unk_aOCD": "{{delivery_type}} App Rules",
  "FoodC_demand_Purchasing_services_jshb": "With Shopped by Courier, you can have a courier buy a variety of items – like pizza, burgers, milk tea, or coffee – from nearby stores (item availability may vary), then deliver them straight to your door.",
  "FoodC_Later_WRgg_djEL": "Buy Now",
  "Global_Driver_Multiplier_new_interaction_Current_activities_vSRD": "This event hasn't started yet",
  "sailing.c-api-intl.[商家营销标签分类]_[菜品活动]": "Item Promotion",
  "FoodC_store_Store_voucher_NnMx": "Store Coupon redeemed!",
  "FoodC_education__2_jwSo": "(2) Leave at door (with note)",
  "sailing.c-api-intl.[商家营销标签分类]_[满X减Y]": "Spend X Save Y",
  "FoodC_home_Possible_causes_wopU": "A: Some common issues and their solutions are provided below:",
  "FoodC_Later_VHDh_UWSb": "View all available stores",
  "GDriver_5_Event_ended_gNeH": "This event has ended",
  "FoodC_c_Try_again_lIZQ": "Try Again",
  "FoodB_optimization_See_more_rbCl": "See more",
  "FoodC_page__Zjra": "Coupons",
  "FoodC_home_If_the_SPtR": "A: If you use a {{entrega_name}} coupon and have not completed payment by the time of order cancellation, the coupon will be returned to your account after the order is canceled. If payment has already been completed at the time of order cancellation, the coupon will not be returned to your account, and the order payment will be refunded to your original payment method within one to thirty days.",
  "FoodC_ServiceFee_EvAo_Aloo": "Address",
  "FoodC_Translation_Sign_in_pljT": "Please sign in",
  "FoodC_statement_If_you_Jbkm": "If you discover that a package is incomplete, lost, or damaged, or the wrong package was delivered, please contact Customer Service as soon as possible for assistance.",
  "FoodC_statement_3_Flash_IEYW": "3. Cancellation Policy",
  "FoodC_home_No_entrega_GnGI": "A: Unfortunately not. {{entrega_name}} coupons in one account can only be used to pay for {{entrega_name}} orders in that account.",
  "FoodC_Later_jqbf_DAWh": "Store(s) outside delivery range",
  "FoodC_education_4_Customers_laxR": "4. You should accept your order within 10 minutes of the courier’s arrival at the delivery location.",
  "FoodC_invoicing_YDSi_TMLw": "Tax ID",
  "FoodC_linkjump_LAQl_lfnD": "Open in {{DiDi}}",
  "FoodC_cancel_Users_initiate_suVB": "Customer can submit a cancellation request, which can’t be withdrawn. The cancellation will be completed once the courier has returned the package, and the cash payment for this order will not be returned",
  "FoodC_demand__1_LhGa": "(1) When you place an order, you’ll be asked what you want to do if an item is out of stock, and the courier will follow your instructions.",
  "FoodC_statement_pornography_WPQI": "Pornographic material;",
  "soda-b-homepage-i18n---登录": "Sign in",
  "FoodC_statement_Value_Items_svYm": "Declared value: The value of the package cannot exceed {{value_limit}}",
  "sailing.act-api-intl.去使用": "Use Now",
  "FoodC_invoicing_AxaA_RodV": "Confirm",
  "FoodC_title_Please_choose_yekD": "Select an Item to Report",
  "FoodC_saved_nDkp_KPcN": "Discounts & Promotions",
  "FoodC_sales_Condition_of_lnwg": "Was the item’s packaging sealed? (optional)",
  "FoodC_Translation_Successful_exchange_BVbd": "Success! See {{coupon_name}} coupons in cart.",
  "FoodC_home_3_If_BGBo": "3. If you have any questions regarding the Terms of Use or the {{brand_name}} app and services, please contact our Customer Service team.",
  "FoodC_statement__4_bDLh": "(4) The recipient shall open the package immediately upon receipt to verify its quantity and condition.",
  "FoodC_demand_1_What_aoLM": "1. What’s “Shopped by Courier”?",
  "FoodC_page_DIKR_secx": "Delivery Promos",
  "FoodC_invoicing_okhb_GoXI": "Got It",
  "FoodC_demand__You_bJcT": "* Pay in cash: After you place an order, the courier will buy the items you ordered, then you pay the courier cash on delivery.",
  "FoodC_issues_Sorry_for_aHCW": "We’re committed to delivering the best experience possible. If you need assistance, please contact us.",
  "FoodC_invoicing_SvTe_iYZQ": "Please enter a name for the official receipt",
  "FoodC_education__1_IIfh": "(1) You cancel before the store has accepted the order by using the ‘Cancel Order’ feature on the Order Details page.",
  "FoodC_reasons_Enter_a_Pnzg": "Please describe the issue in detail",
  "FoodC_sales_How_do_ylCn": "Check if the item was sealed",
  "FoodC_demand__2_TZJq": "(2) If the courier has bought an item for you and isn’t very late, you’ll still need to pay for the order if you cancel.",
  "FoodC_home_No_When_xNrI": "A: Unfortunately not. When using a {{entrega_name}} coupon, the {{entrega_name}} coupon’s value will be subtracted first from the order total. If the coupon’s value exceeds the order total, the difference cannot be cashed out, refunded, received as change, sold, converted, or transmitted to another user. If the order total exceeds the value of the coupon, the difference is paid by you.",
  "FoodC_education__4_QYXm": "(4) You refuse to pay for a cash order",
  "FoodC_statement__2_Gymp": "(2) A free cancellation window is provided after the courier accepts your order and before the package is picked up by the courier; once this window has elapsed, a Cancellation Fee will apply if you cancel;",
  "FoodC_education_In_the_MKeB": "You will not be responsible for a cancellation in the following situations:",
  "FoodC_statement__1_hBNr": "(1) After the courier accepts an order, the sender must be available to answer their phone and be present at the pickup location to meet the courier on time;",
  "FoodC_education__3_ltNM": "(3) Other factors outside of your control that cause the order to be cancelled, such as if we can’t find a courier to deliver the order, or some other issue on our end.",
  "FoodC_statement__1_ZXJA": "(1) To send a package, the sender simply enters the pickup and dropoff addresses, contact information of the sender and recipient, and details about the package in the app;",
  "FoodC_invoicing_ubrt_obAj": "Service Fee Official Receipt",
  "FoodC_statement_6_Lost_VmQE": "6. Guidelines for Handling Lost or Damaged Packages",
  "FoodC_home_When_the_qNRR": "If your network connection is unstable, a payment may not be processed. Try moving to a different location with a better signal, then try again;",
  "FoodC_Later_abhG_KiUN": "Buy Now",
  "FoodC_home_What_are_TUik": "Q: What are the requirements for using a new customer coupon?",
  "FoodC_statement_Items_prohibited_vdcx": "Any other item prohibited by relevant laws and regulations.",
  "FoodC_Later_UYPU_ZvGX": "Deliver to",
  "FoodC_education_In_order_AZCw": "These Delivery Rules were formulated to help all users enjoy a fair and reliable experience when using {{brand_name}}. In the event of a delivery related dispute, the following rules will apply",
  "FoodC_home_Under_what_udiG": "Q: What are the conditions for using {{entrega_name}} coupons?",
  "FoodC_sales_Not_set_AJmE": "Not Now",
  "FoodC_home_The_user_DZGn": "You need to have a {{entrega_name}} coupon you can use in your Wallet in the app;",
  "FoodC_education__2_KZTZ": "(2) The courier is unable to contact you",
  "FoodC_fee_Ukhd_NWfo": "Only one coupon with the same threshold and discount is displayed. You can go to your account to view all",
  "sailing.c-api-intl.推荐": "Recommend",
  "FoodC_page__KoEd": "Show More",
  "FoodC_statement__6_cuGI": "(6) If the courier discovers upon pickup that the package to be delivered does not comply with the packing requirements or package content policy, the courier may cancel the order, and your package will not be delivered.",
  "FoodC_education_Cancellation_Policy_fxrl": "Cancellation Policy",
  "FoodC_statement_Currently_DiDi_jUEl": "{{entrega_name}} is a dedicated delivery service that lets you place an order in the app for a courier to pick up and deliver a package to a specified location or recipient nearby. After a courier accepts your order, they will pick up the package at your specified address and deliver it to your chosen destination or recipient.",
  "FoodC_description_The_entire_lTeJ": "Entire order was not received:",
  "FoodC_statement__3_JHtK": "(3) As the requester/sender, you are responsible for bearing the costs associated with the order. For orders paid in the app, payment must be completed at the time the order is placed; for orders paid in cash, payment must be made to the courier at the time of pickup. The recipient is not liable for any charges or fees;",
  "FoodC_cancel_When_an_XxLF": "For in-app payments, a Cancellation Fee will not exceed the actual delivery fee",
  "FoodC_statement_Dangerous_materials_tLOa": "Hazardous materials or waste (flammable liquids or solids; compressed gases stored in liquefied, non-liquefied or dissolved form; toxic, infectious, radioactive or corrosive substances);",
  "FoodC_statement_Type_You_sJki": "Package content: {{entrega_name}} cannot be used to deliver prohibited items including, but not limited to, any of the following:",
  "FoodC_statement__5_ZbOp": "(5) If you discover that the package is lost, damaged, or incomplete, or the wrong package was delivered, please first discuss the issue of compensation with the courier; if a resolution cannot be reached, please contact Customer Service to resolve the issue.",
  "FoodC_home_When_placing_UpoJ": "Q: If I cancel an order on which I used a {{entrega_name}} coupon, will the {{entrega_name}} coupon be returned to me?",
  "FoodC_sales_Tips_LCST": "Note",
  "FoodC_Later_ubvI_oLLU": "Enjoy later",
  "您的订单还未完成，您可以联系骑手确认当前的具体配送位置。": "You have an open order that hasn’t been delivered yet. If you need to check the current location of your order, please contact the courier.",
  "您所在的地区还未开通服务": "Service not yet available in your area",
  "可以把促销码换成钱吗？ ": "Can a promotion code be converted to cash?",
  "不满意": "Unsatisfied",
  "用户使用%s券应同时满足以下条件：": "A customer using %s Coupons must simultaneously meet the following conditions:",
  "怎样才能获得%s券？": "How can I get a %s Coupon?",
  "取消订单进度": "Cancellation Status",
  "4、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "4. Orders that are delayed or cannot be delivered are not eligible for compensation under the following circumstances:",
  "早间：%s-%s": "Mornings: %s-%s",
  "跳过": "Skip",
  "个人中心的“优惠券”": " Account > Coupons.",
  "联系商家处理": "Contact Store",
  "用户的%s软件版本较低，可在升级%s软件版本后再进行支付。": "The customer is using a version of %s software that is too old. Try updating to the latest version and making the payment again.",
  "商家正在备餐": "Store Is Preparing Order",
  "1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "1. Compensating you for a late delivery is our way of making things right. If your order isn’t delivered by the estimated delivery time (excluding canceled orders), %s will give you one or more free coupons. You can view your coupons after your order is completed by going to ",
  "申请信息": "Request Details",
  "返回首页": "Back to Homepage",
  "在极少数情况下，如果您对订单费用产生疑议，可以点击“帮助”联系我们，客服团队会尽快为您解答。": "In the unlikely event of a fare dispute, just tap \"Help\" to contact us. Our Customer Support will be happy to resolve your issue right away.",
  "我要注册！": "Register now!",
  "你的位置还不在我们服务范围内。我们很快就会扩大我们的覆盖范围，之后你就可以使用%s啦。请点击下面的按钮，我们会在有服务之后通知你。": "Your location is not in our service area. Very soon we will expand our coverage and you will enjoy %s. Please click the button and we will inform you when the service is available. ",
  "您对商家服务满意吗？": "How do you rate this store?",
  "现金": "Cash",
  "用户不符合%s券使用规则中列明的可以使用券的条件，应仔细阅读并保证符合优惠券注明的使用条件；": "The customer does not meet the conditions for use listed in the %s Coupon terms of use. Customers should read the terms of use carefully to ensure they meet the conditions.",
  "%s券上会注明“有效期”，该优惠券仅能在注明的有效期内使用，过期优惠券将自动失效不可用。": "%s Coupons have a specified “validity period”. A %s Coupon can only be used during its specified validity period, after which the coupon will automatically become invalid.",
  "将赔付给您1张%s优惠券": "You get a %s off coupon",
  "1.店铺优惠指的是“满X减Y”活动。": "1. Store discount refers to \"Spend x, save y\"",
  "满%s可用": "over %s",
  "订单": "Order",
  "店铺优惠说明": "Store Discount Details",
  "订单取消成功": "Order cancelled!",
  "目前没有限制。优惠券有使用限制，您可以查看下方优惠券规则，了解一天可以使用多少优惠券。": "Currently there are no restrictions. Coupons have usage restrictions. You can view the coupon rules below to see how many you can use on any single day.",
  "一个用户一天内最多能够使用几张%s券？": "How many %s Coupons can a customer use in a single day?",
  "仅限%s使用": "%s Only",
  "您对商家服务的评价": "You rated this store",
  "恶劣天气及运力紧张情况下，配送费根据实际情况略有浮动。": "In the event of severe weather or tight operational capacity, delivery fees may fluctuate depending on actual circumstances.",
  "进度详情": "Request Status",
  "平台优惠": "Platform Promotions",
  "使用规则": "Terms of Use",
  "2.“满X减Y”和“特价菜”、“优惠套餐”活动优惠可同享。": "2. \"Spend x, save y\" discounts can be combined with Sale ",
  "反馈超时": "Feedback Window Expired",
  "促销码与促销活动相关，有有效期，超过有效期会兑换失败。": "As promotion codes are tied to promotional events, each has a validity period and expiry date. Attempts to redeem a coupon once it has expired will not be accepted.",
  "可以用一个账号内的%s券为其他账号内的订单进行支付吗？": "Can a %s Coupon in one account be used to pay for an order in another account?",
  "获得权益": "SIGN UP",
  "我知道了": "OK",
  "查看已开通的区域": "View shipping area",
  "提交": "Submit",
  "您的问题我们已经收到，将在48小时内给您反馈，并以短信/邮件通知您，请耐心等待。": "We received your message and will respond via SMS or email within 48 hours. Thanks for your patience!",
  "小费金额 = 餐品总价 * 小费比例，你可以点击去选择你的小费比例。（餐品总价不包含配送费和优惠券）": "Tip amount: Order total × Tip percentage (order total does not include delivery fee or coupons)",
  "订单尚未完成": "You Have an Open Order",
  "补充更多信息，以便快速处理（选填）": "Provide other details to help solve the issue faster (Optional)",
  "3.同一商家，最多可以有3个“满X比索减Y比索”活动，自动为您选择优惠力度最大的折扣。": "3. Up to 3 \"Spend x, save y\" promotions may be redeemed per store. Promotions with the highest discounts are automatically applied first.",
  "更多商家即将上线，点击“请通知我”商家上线时我们将第一时间通知你": "There will be more restaurants coming soon, click ‘Notify me’ to receive notifications immediately when new restaurants have settled down here.",
  "用户应当出于合法、正当目的以合理方式使用%s券。用户不得单独或与商家恶意串通，利用%s券实施欺诈等非法行为以达到套取现金、获得积分或礼品等目的。且用户不得利用%s券进行售卖、兑换或从事其他营利性活动。如果平台发现用户存在前述违法违规行为，%s有权拒绝该用户使用%s券和/或对该用户账号和/或关联账号采取临时或永久停止平台服务等措施，并保留通过法律途径追究用户法律责任的权利。": "Customers shall use %s Coupons in a reasonable manner for lawful, proper purposes. Customers shall not individually or in malicious collusion with a Store utilize %s Coupons to engage in fraud or other illegal acts in order to obtain cash, points, gifts, or other benefits. Customers shall also refrain from using %s Coupons to conduct sales, exchanges or other profit-making activities. If a customer is discovered engaging in any of the aforementioned illegal acts, %s reserves the right to deny the customer usage of %s Coupons and/or enforce a temporary or permanent freeze of platform services on the customer’s account and/or related accounts. %s also reserves the right to pursue legal action against the customer.",
  "由于用户未实际支付%s券抵用部分订单金额，因此用户不能获得%s券抵用部分金额的发票。": "Since the customer is not actually making a payment on the portion of the order total offset by a %s Coupon, the customer cannot receive an official receipt for that portion of the total.",
  "本次服务评价成功": "Thank you for your feedback",
  "提交申请": "Request Cancellation",
  "取消": "Cancel",
  "请选择取消原因以便商家更快帮您处理": "To help the store process your request faster, please select your reason for cancelling",
  "钱包": "Wallet",
  "怎么样开通免密支付？": "How do I enable password-free payments?",
  "什么是%s券？": "What is a %s Coupon?",
  "谢谢。我们会及时通知你": "Thanks! We will inform you!",
  "退款金额": "Refund Amount",
  "通用支付": "All Payments",
  "促销码是字母和数字的组合。": "The promotion code is composed of letters and numbers.",
  "6、每位用户每天最多赔付6张优惠券；": "6. Compensation is limited to 6 coupons per customer per day;",
  "感谢您提前注册账号！我们将会及时为您发送最新的滴滴外卖活动信息。我们的服务即将开始，请拭目以待！": "Thank you for registering your account in advance! We will be keeping you updated on all DiDi Food promotions going forward. Don't forget to check out our App on April 7th!",
  "用户在开通%s服务的城市和/或地区下外卖订单；": "The customer must place orders in a city/region where %s services are available.",
  "促销码由什么组成？": "What is the promotion code composed of?",
  "能导致%s券不能使用的原因和解决办法是什么？": "In which circumstances might a %s Coupon become unusable, and how can these be resolved?",
  "请您上传所收到的全部商品照片、问题商品细节照片、商品小票照片，这将协助我们更快地处理您的问题。": "To help us process your request faster, please upload photos of the following: all item(s) you received; closeup of the item(s) having a problem; and receipt for the item(s).",
  "更多评价": "More ratings",
  "99Pay-余额": "99Pay - Balance",
  "详细说明": "Details",
  "好的，我知道了": "OK",
  "4、部分时间段下单暂不参加赔付；": "4.Orders placed during certain hours are not eligible for compensation;",
  "您的免配送费权益将于%s过期": "Your free delivery membership will expire on %s",
  "提交反馈": "Submit Feedback",
  "帮助中心": "Help",
  "6、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "6. Please note the delivery time reported by your courier. If a courier claims to have delivered an order before you actually receive it, please call %s Customer Service at %s to file a complaint.",
  "获得新用户立减活动资格的新用户，是指第一次使用%s并成功完成订单的用户。新用户优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。": "New customers eligible for the new customer instant discount promotion are those that are using %s for the first time and have successfully completed an order. To establish whether the welcome reward can be used simultaneously for a store’s already discounted items or for items already with fixed spending discounts, please consult the detailed terms of the particular %s promotion.",
  "%s公里之内配送费": "Delivery fee within %s km ",
  "我们很快会扩大服务范围，点击“请通知我”，我们将会在开通时通知你": "We're going to take more areas in , click \"Notify me\" and we’ll let you know the latest service range when we launch the service.",
  "支付配送费": "Pay Delivery Fee",
  "商家自行配送的配送费收取规则：": "Store-Delivered Delivery Fee Collection Rules:",
  "促销码是什么？": "What is a promotion code?",
  "仅限支付卡bin为%s可用": "Only available if the card bin is %s",
  "请告诉我们，帮助我们一起改善": "Let us know and help us improve",
  "基础配送费": "Base Delivery Fee",
  "当有配送费活动时，根据活动情况对配送费进行相应的优惠。": "When there is a delivery fee promotion, the delivery fee will be discounted a certain amount based on the promotion.",
  "申请取消原因": "Reason for Cancellation",
  "打开": "On",
  "查看评价": "View ratings",
  "%s券可以找零或兑换吗？": "Can I receive change back when using %s Coupons or exchange them for cash?",
  "收起": "Tuck up",
  "5、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "5.Orders that are delayed or cannot be delivered are not eligible for compensation under the following circumstances:",
  "优惠券": "Coupons",
  "不可用原因": "Reason Invalid",
  "(2) 因您的配送地址所在地不允许骑手入内，导致超时送达或无法送达的；": "(2) The address you provide doesn’t permit couriers to enter, and this results in a delay or failure to deliver;",
  "网络不佳，请检查您的网络": "Poor connection. Please check your network connection",
  "周五": "Friday",
  "您可以使用现金支付订单，请备好足够的零钱。": "You can pay for your order in cash. Please be prepared to pay the exact amount.",
  "周二": "Tuesday",
  "最高抵扣%s": "Applicable to %s",
  "特殊说明": "Special Remarks",
  "现金支付": "Cash payments",
  "已发起取消，等待商家确认": "Cancellation request submitted. Waiting for store to confirm",
  "3、恶劣天气（雨、雪等）暂不参加赔付；": "3. Delays caused by severe weather (rain, snow, etc.) are not eligible for compensation;",
  "兑换优惠": "Redeem Coupon",
  "现在订餐": "GO BACK",
  "如用户使用了%s券，取消订单时尚未完成支付，相关优惠券在用户取消订单后将即时退回账户。如用户取消订单时已完成支付，相关优惠券不再退回账户。订单款项将于订单取消后一周内原路退回支付账户。": "If a customer uses a %s Coupon and has not completed payment by the time of order cancellation, the coupon will be returned to the customer’s account after the order is canceled. If payment has already been completed at the time of order cancellation, the coupon will not be returned to the customer’s account, and the order payment will be refunded to the original payment account within one week.",
  "用户未违反平台外卖券使用规则，且该笔订单不属于平台判定的作弊或类似作弊订单等禁用%s券的情况。": "The customer must not violate platform coupon terms of use. The order must also not be considered by the platform to be a fraud, a type of fraudulent order, or fall under any other condition forbidding the use of %s Coupons.",
  "我们正在努力拓展范围。如果您希望 %s 的服务尽快到您身边，请告诉我们": "We're hard at work expanding. If you want %s in your area ASAP, let us know",
  "已经累计为您节省了%s配送费": "You’ve already saved %s in delivery fees",
  "%s致力于通过无密码和现金支付流程为我们的客户提供简单安全的付款体验。": "%s provides offline and password-free online payment options that are safe and easy to use.",
  "用户兑换促销码的次数限制是多少？": "How many times can a customer redeem a promotion code?",
  "配送费受多种因素影响，包括您和餐厅之前的距离，配送的最终地址和该地区的供需情况。": "It is calculated based on the distance between the restaurant and the delivery location and may vary due to increased demand.",
  "免配送费权益": "Free Delivery Membership",
  "%s平台配送费收取规则：": "%s Platform Delivery Fee Collection Rules:",
  "不可以。一个账号内的%s券仅可用于支付该账号内的%s订单。": "Unfortunately not. %s Coupons in one account can only be used to pay for %s orders in that account.",
  "部分餐品缺失- 表示骑手已经给您交餐，表示骑手已经给您交餐，但您有部分餐品没有收到。": "Part of the order was missing: The courier delivered the order, but one or more items weren’t received.",
  "如果对费用提出争议，将如何解决？": "If I have a fare dispute, how will it be resolved?",
  "（必填）请填写您的申诉原因": "(Required) Specify your reason to submit appeal",
  "2、部分时间段下单暂不参加赔付；": "2. Orders placed during certain hours are not eligible for compensation;",
  "很抱歉给你造成了不好的体验，请选择您遇到的问题：": "We apologize for the inconvenience. Please select the issue you encountered:",
  "请通知我": "Notify me",
  "送您1张%s优惠券；": "we’ll give you a %s off coupon;",
  "恭喜你！兑换成功！": "Congrats! Coupon redeemed!",
  "用户绑定了银行卡或使用现金支付；": "The customer must have a bank card linked to their account or pay in cash.",
  "您的订单为商家配送，您可点击下方的联系商家按钮与商家进行问题协商。": "Esta entrega está a cargo de la tienda. Para obtener más información sobre el estado del pedido, pulsa el botón de contactar a la tienda que está abajo.",
  "同一个促销码可以重复兑换么？": "Can the same promotion code be redeemed multiple times?",
  "%s券抵用餐费部分可以开发票吗？": "Can I get an official receipt for the portion of the order fee offset by a %s Coupon?",
  "已过期券": "Expired Coupons",
  "有效期至": "Valid until",
  "他们已加入%s": "They have joined %s",
  "卡": "Card",
  "用户支付外卖订单时使用%s券并支付成功；": "When making payment for an order, the customer must use the %s Coupon and successfully complete the payment.",
  "周四": "Thursday",
  "一个外卖订单最多只能使用一张%s平台券。如：品类券与配送费券不可同时使用。": "A maximum of one %s platform coupon can be used for a single order. For example, a category coupon and a delivery fee coupon cannot be used simultaneously.",
  "促销码有有效日期嘛？": "Do promotion codes have an expiry date?",
  "你可以在个人中心的“优惠券”或“钱包”-“优惠券”中兑换它。": "You can redeem it under \"Account\" > \"Coupons\" or \"Wallet\" > \"Coupons\".",
  "%s平台处理": "LET %s HANDLE IT",
  "由配送商家决定配送金额。": "The delivery amount is determined by the Store.",
  "晚间：%s-%s": "Evenings: %s-%s",
  "中查看": " ",
  "配送费详情": "Delivery Fee Details",
  "将赔付给您1张%s折扣券": "You get a %s off coupon",
  "送您%s张免配送费券；": "we’ll give you %s Free Delivery coupon",
  "正在寻找骑手": "Finding A Courier",
  "反馈部分餐品缺失": "Tell us what’s missing",
  "3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。": "3. If you paid for your order in cash and are eligible for a refund, your refund will be issued to you in the form of a coupon added to your Wallet.",
  "优惠券使用规则": "Coupon Terms of Use",
  "验证成功": "Verification succeeded",
  "%s券是%s发放给用户，用户在支付%s订单时，用于抵扣订单金额的电子券。": "A %s Coupon is an electronic voucher issued by %s to customers, and it can be used to offset the order amount when paying for %s orders.",
  "提交成功": "Submitted",
  "用户在“钱包”中有可使用的%s券；": "The customer's \"Wallet\" must have a %s Coupon available for use.",
  "延迟送达%s分钟以上": "%s+ minute delay",
  "您的小费将全部支付给骑手": "Courier will receive entire tip amount",
  "让 %s 进入我的地区": "Bring %s to My Area",
  "在支付方式中添加您的银行卡，成功绑定后即可开通免密支付。": "Password-free payments will be enabled once you add a valid bank card under Add Payment Method section.",
  "兑换失败": "Unable to redeem",
  "我们正在努力拓展中。您可以访问周边热门商圈看看美食": "We're hard at work expanding our reach. Check out the food in nearby popular business districts",
  "满意": "Satisfied",
  "再等等": "Wait",
  "骑手正在配送中": "Courier Is on the Way",
  "周六": "Saturday",
  "描述": "Description",
  "5、每位用户每天最多赔付6张优惠券；": "5. Compensation is limited to 6 coupons per customer per day;",
  "2.点击选择“浏览器”，进行下载": "2. Tap and select \"browser\" to download",
  "%s将不定时地通过在本页面发布更新版本的方式修改使用规则。请用户定期访问本页面并查看最新版本。这些规则对用户均具有约束力。": "%s will from time to time amend the terms of use by posting an updated edition to this page. Customers are asked to periodically visit this page to view the latest edition. These terms are binding for all customers.",
  "无效券": "Invalid Coupons",
  "/公里": "/km",
  "当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "If an order isn’t delivered by the estimated delivery time (excluding canceled orders), %s will give you one or more coupons to help make things right. After your order is completed, you can view your coupons by going to ",
  "出生日期": "Date of Birth",
  "餐费": "Food Fee",
  "完成注册登录，以便开通服务后通知您": "Complete the registration and login to notify you when the service is available.",
  "不使用优惠券": "Don’t Use Coupon",
  "请上传商品和小票照片": "Please upload supporting photos",
  "商家优惠": "Store Promotions",
  "你好": "Hello",
  "CPF": "CPF",
  "附近商家正在筹备中": "Nearby Stores making preparations",
  "失败": "Fail",
  "验证失败": "Verification failed",
  "不可以。用户选择使用%s券支付订单时将优先使用%s券抵扣订单金额。券金额高于订单金额的，差额部分不可提现、退款、找零、买卖、兑现或转赠他人；订单金额高于券金额的，差额部分由用户支付。": "Unfortunately not. When using a %s Coupon, the coupon’s value will be subtracted first from the order total. If the coupon’s value exceeds the order total, the difference cannot be cashed out, refunded, received as change, sold, converted, or transmitted to another customer. If the order total exceeds the value of the coupon, the difference is paid by the customer.",
  "不可以，兑换出的优惠券可以根据优惠券的使用规则，抵扣部分订单费用。": "No. The coupon redeemed by the promotion code can be used to offset a portion of an order fee according to the coupon’s terms of use.",
  "(1) 您要求更换收获地址、您提交的地址错误、联系不上您或无人收货、拒收等导致订单超时送达或无法送达的；": "(1) You request a delivery address change, the address you provide is incorrect, we are unable to reach you, no one is present to accept delivery, or you refuse delivery, and any of the above results in a delay or failure to deliver;",
  "立即兑换": "Redeem Now",
  "用户在进行订单支付时网络信号中断或不稳定，可稍后到网络信号稳定的地方再进行支付；": "When making payment on an order, the customer’s network signal may have dropped or be unstable. Try going to a place with a better network signal and making the payment again.",
  "服务费+里程费": "Service Charge + Distance Rate",
  "什么是%s券的“有效期”？": "What is the “validity period” of a %s Coupon?",
  "目前我们支持信用卡，借记卡的线上支付，以及现金和pos机的线下支付。": "We currently support online payments using a credit or debit card as well as offline payments using cash and POS terminals.",
  "OFF": "OFF",
  "下单时使用%s券，订单取消后，外卖券还会返还吗？": "If I cancel an order for which I’m using a %s Coupon, will the coupon be returned to me?",
  "暂无优惠券": "No coupons currently",
  "1.点击右上角菜单": "1. Tap menu in upper right",
  "联系骑手": "Contact Courier",
  "去看看已开通地区": "Check Out Available Areas",
  "在哪里可以兑换促销码？": "Where can I redeem my promotion code?",
  "关闭": "Closed",
  "下载": "Download",
  "用户通过在线支付、现金支付的方式支付外卖订单，且用户领券时输入的手机号与实际下订单时使用账号登记的手机号为同一号码；": "The customer must pay for their orders via online or cash payment methods. The phone number entered by the customer when redeeming the coupon must be the same as the phone number used to register the account under which the actual order was placed.",
  "更多问题": "More questions",
  "pos机支付": "POS machine payment",
  "随时随地享美食": "Enjoy Great Food Anytime, Anywhere",
  "感谢你的支持，有新商家上线会第一时间通知你": "Thank you for your support, we will inform you immediately when new restaurants have settled down.",
  "周日": "Sunday",
  "去周边看看": "Check Out Nearby",
  "请选择有问题的商品": "Select an item to report",
  "将赔付给您%s张免配送费券": "You get %s Free Delivery coupon",
  "保持联系！": "We'll Keep You Updated",
  "处理结果将短信通知您": "Results will be sent to you via SMS",
  "周三": "Wednesday",
  "新用户优惠券的使用要求是什么？": "What are the requirements for using a welcome reward?",
  "周一": "Monday",
  "商家配送": "Store Delivery",
  "订单实际送达时间晚于预计送达时间%s分钟以上，": "If your order is delivered over %s minutes after the estimated delivery time,",
  "取消订单": "Cancel Order",
  "您可以使用信用卡或储蓄卡通过pos机支付订单，请确保您的卡是可用的。": "You can use a credit or debit card to pay for your order via the POS terminal. Please make sure your card is valid.",
  "成功": "Success",
  "您对配送服务的评价": "You rated this delivery",
  "输入兑换码": "Enter promotion code",
  "展开": "Deploy",
  "评价本次服务": "Rate Your Experience",
  "兑换失败，请再试一次": "Failed. Try again",
  "商家已开始制作美食，拒绝了您的取消订单申请": "Your request to cancel was declined by the store because your order is already being prepared.",
  "已收到你的申请": "Application received ",
  "7、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "7. Please note the delivery time reported by your courier. If a courier claims to have delivered an order before you actually receive it, please call %s Customer Service at %s to file a complaint.",
  "整个订单都没收到- 表示骑手没有为您配送订单，您没有收到任何餐品。": " Entire order was not received: The courier didn’t deliver any of the order. To notify the courier to complete delivery, please tap one of the buttons below.",
  "对处理结果不满意？": "Not satisfied?",
  "抱歉，你所在的区域不支持配送": "Sorry, the area where you are is not available for the delivery service for now.",
  "2、超时赔付服务由平台为用户提供，用户没有支付任何费用，若订单取消则服务取消。": "2.Coupons are provided to you at no extra charge. However, if you cancel an order, you won’t be able eligible to receive any coupons.",
  "隐藏": "Hide",
  "更多详情": "More Details",
  "app内可以支持哪些支付方式？": "What payment methods does the app support?",
  "订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": " If your order is delivered %s–%s minutes after the estimated delivery time, ",
  "促销码是由系统生成的，可以通过促销码兑换优惠券。": "Promotion codes are generated by the system. They can be used to redeem discount coupons.",
  "有效期至%s": "Valid Until %s",
  "%s将不定期推出多样的优惠活动，如：新老用户满减优惠券、通过分享获得红包优惠券等，具体活动均将在本平台第一时间推送，或以短信形式联系您，您可随时关注相关活动。": "%s periodically issues discount promotions, e.g., fixed spending discount amounts for new/old customers, getting coupons for sharing content, etc. You will be notified of specific events as early as possible either on this platform or via SMS message.",
  "配送费计费规则": "Delivery Fee Billing Rules",
  "已经收到你的申请": "Application received",
  "仅限新用户使用": "New Customer Only",
  "送您1张%s折扣券；": "we’ll give you a %s off coupon;",
  "退款渠道": "Refund Method",
  "配送费": "Delivery Fee",
  "联系客服": "Contact Us",
  "联系客服进行申诉": "Contact Customer Service and submit an appeal",
  "确认": "Confirm",
  "4月7号起，滴滴外卖将在大阪开始试运营啦！您可以在滴滴外卖App上找到您喜欢吃的各式当地美食。我们的服务上线大促活动即将开始，快点击下方按钮，不错过我们的优惠活动！": "From April 7th, DiDi Food will be starting our pilot food delivery services in Osaka! You can order your favorite Osaka local restaurants at your fingertip with DiDi Food. Our launch campaign will start soon. Click below to stay tuned on all our promotions!",
  "当前区域暂未开通服务": "There's no available service around you right now",
  "超时赔付规则说明": "Coupon for Late Delivery",
  "如果用户对使用规则或在使用%s软件和/或相关服务过程中有任何疑问或需要任何帮助，请及时与%s客服联系，": "If a customer has any questions or requires any assistance regarding the terms of use or the use of %s software and/or related services, please contact %s customer service immediately.",
  "支付信息": "Payment information",
  "平台配送": "Platform Delivery",
  "您为什么取消？": "Why did you cancel?",
  "一个外卖订单最多能够使用几张%s券？": "How many %s Coupons can be used for a single order?",
  "(3) 其他因您的原因导致骑手超时送达或无法送达的。": "(3) Any other factors attributable to you directly cause a delay or prevent delivery by the courier.",
  "小费说明": "About Tips to Couriers",
  "更多地区入驻中……": "More areas joining...",
  "配送费收取标准": "Delivery Fee Collection Standard",
  "%s~%s公里之间配送费": "Delivery fee between %s-%s km ",
  "用户的外卖订单符合%s券券面注明的使用条件，如：餐品价格加餐具费满足该外卖优惠券所属品类、满减金额及有效使用时间限制；": "The customer’s order must comply with the terms of use specified on the %s Coupon. E.g., Item price and utensil fees must meet the category, spending threshold, and period of validity requirements for the %s Coupon.",
  "超出%s公里部分收取标准": "Collection standard over %s km ",
  "可能的原因和解决办法如下：": "Possible causes and solutions are as follows:",
  "促销码分为可以兑换一次的和可以兑换多次的，实际可兑换次数以促销码活动规则为准。": "Some promotion codes can only be redeemed once and others can be redeemed multiple times. For the actual number of redemptions allowed, please consult promotion code event rules.",
  "免费取消订单": "First cancellation is free",
  "可抵扣%s": "Applicable to %s",
  "骑手没有配送我的订单": "Courier didn’t deliver my order",
  "仅限%s": "%s Only",
  "3、您因超时赔付服务获得的优惠券，将发放至您的%s账户，请在": "3.Coupons for late deliveries will be added to your %s account after your order is completed. You can view your coupons by going to ",
  "我的优惠": "My Coupons",
  "您可以点击下方按钮和骑手联系交餐。": "You can contact the courier on your own, or let us handle it.",
  "您总是能在点餐之前确认该订单的配送费。": "The delivery fee is always shown before order.",
  "特别提示: ": "Special Note:",
  "POS": "POS Terminal",
  "骑手已到达": "Courier Has Arrived",
  "大阪，出发！": "Greetings, Osaka!",
  "等待商家接单": "Waiting for Store to Accept",
  "%s可用": "Available in %s",
  "商家正在入驻中": "More restaurants coming",
  "1、超时赔付服务是%s为您免费提供的服务，延迟送达时间及赔付优惠券金额如下：": "1.Compensating you for a late delivery is our way of making things right. Depending on how long the delay is, we’ll issue one or more free coupons to you:",
  "上报问题": "Report Issue",
  "您的订单完成已经超过24小时，无法自助申请，如您有问题可联系客服，给您带来不便深表歉意。": "As your order was finalized more than 24 hours ago, it is no longer possible to make this request.",
  "商家拒绝取消申请": "Cancellation request declined by store",
  "联系商家": "Contact Store",
  "一个用户一天内使用的%s券数量之和不得超过两张，各优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。同一个账号、手机号、支付账号、设备以及其他相同或相似身份信息，均视为同一用户。": "One customer can use a maximum of two %s Coupons in a single day. Please consult the detailed terms of the specific %s promotion to see whether particular coupons can be used on top of a store’s existing discount items or fixed spending discounts. If any of the following account information (account name, phone number, payment account, device) and other identical or similar information match that of another account, they will both be considered as belonging to one customer. ",
  "1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费不退还。 2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。": "1. For partial refunds, the refund amount is the total amount for items in your order that you requested a refund for, minus any prorated coupon discounts. Delivery Fee is nonrefundable. 2. For full refund, your entire payment will be refunded in full. If you used any coupons on your order, they will be returned to your Wallet.",
  "感谢你的支持，区域开通后会第一时间通知你": "Thank you for your support, we will inform you immediately when we launch the new service range",
  "在什么条件下可以使用%s券？": "Under what conditions can I use %s Coupons?",
  "您对配送服务满意吗？": "How do you rate this delivery?",
  "温馨提示": "Reminder"
}