module.exports = {
  "FoodC_statement__1_eIic": "(1) Luego de que el socio repartidor recolecte el paquete, se recomienda que el destinatario esté atento a su teléfono y presente en el punto de entrega para encontrarse con el socio repartidor a tiempo.",
  "FoodC_Conciliation_Operation_failed_wEjU": "Error. Inténtalo de nuevo.",
  "FoodC_cancel_Cancellation_Policy_rnAp": "Política de cancelación",
  "FoodC_Later_TWbh_AXWk": "Pago completado",
  "FoodC_home_The_user_ElUo": "Vincular una tarjeta o pagar en efectivo.",
  "FoodC_demand__Do_CKdn": "* Continuar con la compra de todos los platos que no estén agotados.",
  "FoodC_side_Tyor_qIoH": "TyC",
  "FoodC_demand_Courier_Purchasing_eTAr": "Comprado por el socio repartidor",
  "FoodC_cancel__free_BQFd": "El usuario puede cancelar sin cargos hasta {{free_time}} min después de que el socio repartidor haya aceptado el pedido.",
  "FoodC_store_Store_Coupon_szoR": "Cupones de tienda",
  "FoodC_home_The_entrega_uOPx": "Completar el pago correctamente y usar el cupón para tu pedido.",
  "FoodC_Later_XtdO_YxTQ": "Los cupones que compraste están en el Centro de cupones. Sigue los pasos a continuación para verlos.",
  "FoodC_statement__4_YGAs": "(4) Además de las tarifas asociadas a los pedidos con pago en efectivo, no se debe realizar algún otro pago al socio repartidor.",
  "FoodC_sales_Confirmation_mTGd": "Entendido",
  "FoodC_page_vsqt_uXcI": "Cupones de entrega",
  "FoodC_Noavailablestore_zVkf_urAN": "Volver al Inicio",
  "FoodC_2__sybX": "Lo sentimos, no hay más cupones por hoy. Por favor, inténtalo de nuevo en otro momento.",
  "FoodC_statement__2_ZtHa": "(2) Al entregarle el paquete al socio repartidor, se recomienda verificar la identidad de éste, ya que el remitente asumirá toda la responsabilidad si el paquete se entrega a alguien que no es el socio repartidor correspondiente.",
  "FoodC_demand__1_jqYB": "(1) Simplemente ingresa la dirección de entrega, el nombre del destinatario, los platos que quieres y luego paga. Un socio repartidor comprará los platos de tu restaurante elegido u otro cercano:",
  "FoodC_education_1_Customers_Gpbb": "1. Antes de realizar un pedido, elige la mejor opción de entrega para ti. Indica al socio repartidor cómo prefieres la entrega del  pedido según la opción que selecciones:",
  "FoodC_title_Upload_Image_VSGW": "Especificaciones para las fotos",
  "FoodC_side3_xeOS_SWLO": "Disponible para {{name}}",
  "FoodC_education__1_mJTG": "(1) Si la tienda ya confirmó y aceptó el pedido, puedes usar la función \"Cancelar pedido\" o contactar a la tienda y pedirle que cancele el pedido. Si la tienda no acepta cancelar el pedido porque ya está listo o por otro motivo razonable, no podrás cancelar el pedido sin costo extra. Si insistes en cancelar o te niegas a recibir una parte del pedido o el pedido completo, se deberán pagar los gastos correspondientes de ese pedido.",
  "FoodC_order__2_UYRU": "(2) Te mostraremos el total del pedido cuando vayas a pagar. Si el monto pagado por el socio repartidor es mucho menor al total del pedido, recibirás un reembolso parcial automáticamente. Si el precio final es mayor a lo que pagaste, no te cobraremos ningún monto adicional.",
  "FoodC_sales_Confirmation_jtNg": "Aceptar",
  "FoodC_feedback_I_received_UNiy": "Recibí un pedido que no es mío",
  "FoodC_home_DiDi_zojp": "DiDi",
  "FoodC_sales_3_Display_GYtM": "3. Para reembolsos de pedidos que se pagaron en efectivo, si se aprueba tu solicitud de reembolso, te devolveremos el monto correspondiente como saldo que se agregará a la sección de Billetera.",
  "FoodC_Later_TVZu_RjZF": "Finalizar compra",
  "FoodC_demand__2_ALrB": "(2) El total de tu pedido se mostrará al pagar. Si se cobra al socio repartidor un importe diferente, no se te cobrará ningún importe adicional (ni se te reembolsará la diferencia).",
  "FoodC_home_Orders_are_EEjN": "Hacer un pedido de {{entrega_name}} en una ciudad o región en la que el servicio esté disponible.",
  "FoodC_cancel_Free_order_WqpG": "El usuario puede cancelar el pedido sin cargo alguno.",
  "FoodC_education_3_If_wduh": "",
  "FoodC_home_1_The_SWqc": "1. Debes usar los cupones de {{entrega_name}} conforme a los Términos y Condiciones. Está prohibido usar los cupones de {{entrega_name}} de forma maliciosa para cometer fraude u otros actos ilegales para obtener dinero en efectivo, puntos o regalos. Está prohibida la venta o el intercambio de cupones de {{entrega_name}}, así como su uso para otras actividades lucrativas. Si {{brand_name}} identifica que incumpliste los Términos y Condiciones, {{entrega_name}} podrá rechazar el uso de tus cupones de {{entrega_name}} y/o tomar medidas como cerrar temporal o permanentemente tu cuenta o cuenta asociada y detener la capacidad de acceder a los servicios de {{brand_name}}. También nos reservamos el derecho de tomar acciones legales en tu contra por los medios legales correspondientes.",
  "FoodC_seals_Outdoor_packaging_MGso": "¿El empaquetado del plato estaba intacto?",
  "FoodC_demand_2_How_DjaQ": "2. ¿Cómo funciona “Comprado por el socio repartidor”?",
  "FoodC_activities_ZYMp_mFfB": "Promo por método de pago",
  "FoodC_c_Please_try_cHSE": "Por favor, inténtalo de nuevo más tarde.",
  "FoodC_Later_uAfu_eSHi": "Selecciona tu dirección",
  "FoodC_statement__3_gBJT": "(3) Una vez que el socio repartidor haya recolectado el paquete, no se reembolsará la tarifa del pedido si lo cancelas.",
  "FoodC_home_The_following_vTNS": "R: Al usar cupones de {{entrega_name}} debes cumplir con las siguientes condiciones:",
  "FoodC_RCOUPON_Coupon_reduction_PqSi": "Cupón especial",
  "FoodC_statement__2_Rrwp": "(2) Luego de que el socio repartidor haya recolectado el paquete, tanto el remitente como el destinatario recibirán un código de entrega. Si el destinatario no recibe el código por mensaje de texto, el remitente puede compartir el estado de la entrega y el código desde la página de detalles del pedido o contactar a Soporte DiDi para confirmar el código. Para completar correctamente la entrega del paquete, se recomienda no compartir el código con el socio repartidor o con terceros hasta que se entregue el paquete.",
  "FoodC_demand_3_What_zsYM": "3. ¿Qué pasa si un plato que quiero está agotado?",
  "FoodC_education_In_order_SRIb": "Esta política de cancelación se creó para ayudar a que todos los usuarios tengan una buena experiencia con {{brand_name}}.",
  "FoodC_feedback_2_The_ntBT": "2. Para un reembolso completo, se realizará el reembolso del valor total pagado. Si utilizaste cupones para el pedido, estos se regresarán a la sección de Billetera.",
  "FoodC_statement_2_How_LmUD": "2. ¿Cómo se usa {{entrega_name}}?",
  "FoodC_Later_ZBBS_XWul": "Cuenta > Centro de cupones",
  "FoodC_Later_GwXN_BQcj": "Tienda más cercana",
  "FoodC_deeplinkpageshow_wcmD_CuSi": "Selecciona una aplicación en tu dispositivo para abrir la página",
  "FoodC_sales_If_the_TsPm": "Dinos si el plato fue entregado con empaque sellado, resellable o de otra manera (ejemplo, con grapas) para que podamos verificar el problema con la tienda o el socio repartidor.",
  "FoodC_cancel_Courier_has_LQtM": "Pedido aceptado por socio el repartidor",
  "FoodC_education__2_uVUK": "(2) La tienda a la que le hiciste el pedido estaba cerrada, un producto estaba agotado, ocurrió un accidente o la tienda o el socio repartidor cancelaron el pedido por otro motivo. En ese caso, recibirás un reembolso completo.",
  "soda-d-i18n---没有更多了": "No hay más resultados",
  "FoodC_demand__You_yjru": "* Paga a través de la aplicación: puedes pagar a través de la aplicación cuando hagas un pedido y luego el socio repartidor comprará los platos que pediste y los entregará directamente en tu puerta.",
  "FoodC_home_The_user_vjgt": "Es posible que estés usando una versión desactualizada de la aplicación de {{brand_name}}. Actualiza {{brand_name}} a la versión más reciente para intentarlo de nuevo.",
  "FoodC_home_The_user_lsyI": "Respetar nuestros Términos y Condiciones al usar un cupón de {{entrega_name}} y debemos determinar que tu pedido no sea fraudulento al usarlo.",
  "FoodD_one_Please_try_VyNK": "Por favor, inténtalo de nuevo más tarde.",
  "FoodC_demand__If_PNsp": "* Cancelar todo el pedido si un plato está agotado.",
  "FoodC_statement__3_PxyO": "(3) Luego de que el socio repartidor haya recolectado el paquete, si la entrega no puede completarse por un motivo relacionado con el destinatario (ya sea porque el destinatario rechace el paquete, que no se pueda contactar al destinatario, que la dirección del destinatario no sea correcta, u otros motivos), la responsabilidad del paquete será nuevamente del remitente y deberá pagar las tarifas de servicio que correspondan.",
  "FoodC_statement_Animals_people_IccN": "Animales, personas, armas o explosivos;",
  "FoodC_home_2_brand_vxUa": "2. Los Términos y Condiciones de {{brand_name}} se revisan cada tanto, y se publicarán las actualizaciones en esta página. Debes visitar esta página de forma regular para consultar si hay actualizaciones. Éstos son vinculantes para los usuarios.",
  "FoodC_saved_ZHnz_OjnQ": "Precio original",
  "FoodC_Translation__brand_LAYp": "Cupones de {{brand_name}}",
  "FoodC_Later_BYic_nYYd": "Usar más tarde",
  "FoodC_cancel_When_the_PAQq": "Si hay múltiples intentos de cancelación de tu cuenta, no podrás cancelar este pedido directamente, pero puedes contactar a Soporte DiDi para obtener ayuda.",
  "FoodC_demand__4_HJNa": "(4) Es posible que se tomen medidas en caso de incumplimiento a los términos y condiciones de uso.",
  "sailing.c-api-intl.[提单页]_[平台券]": "Cupones de DiDi Food",
  "FoodC_demand__3_tQmA": "(3) Los precios reales varían según tu zona.",
  "FoodC_statement__4_Nghy": "(4) Si el paquete no se puede entregar porque el socio repartidor no puede contactar al remitente o al destinatario, porque el punto de recolección/entrega es incorrecto o por algún otro motivo, la tarifa del pedido no se reembolsará si se cancela el pedido. Te recomendamos contactar al socio repartidor para acordar una hora y un lugar para la devolución o entrega del paquete.",
  "FoodC_education_3_Customers_Zoec": "3. Para que el socio repartidor pueda contactarte si lo necesita, procura tener tu teléfono a la mano durante la entrega.",
  "FoodC_update_The_expected_ZzFg": "El tiempo de entrega estimado se basa en el tiempo de entrega más reciente.",
  "FoodC_statement_Currency_or_drqX": "Dinero o títulos de crédito;",
  "FoodC_demand__In_XsxW": "* Si hay un conflicto respecto a un plato agotado, DiDi usará esta política para determinar quién es responsable.",
  "FoodC_Later_otla_noJA": "Mis cupones",
  "FoodC_Later_wFZx_DICJ": "Política de uso",
  "FoodC_education__1_vwgm": "(1) Entregar en la puerta",
  "FoodC_page_Contact_customer_rJbM": "Contacta a Soporte DiDi",
  "FoodC_Later_Jtbt_KBCI": "Alcanzaste el límite de compra diario",
  "FoodC_Later_XpNu_TSXn": "Haz clic en el botón de pedir",
  "FoodC_home_What_is_tlHh": "P: ¿Qué son los cupones de {{entrega_name}}?",
  "FoodC_home_The_user_jRpX": "Tu pedido debe cumplir con las condiciones para el cupón.",
  "FoodC_education__1_qvVC": "(1) No recibiste el pedido a tiempo",
  "FoodC_statement_Jewelry_vtUc": "Joyas;",
  "FoodC_saved_AlLL_CHtd": "Precio pagado",
  "FoodC_statement_Psychotropic_drugs_XUfp": "Drogas psicoactivas o narcóticos;",
  "FoodC_home_What_is_NZVx": "P: ¿Cuál es la vigencia de un cupón de {{entrega_name}}?",
  "FoodC_statement_Weight_The_kJIL": "Peso: el peso máximo permitido para un paquete es de {{weight_limit}} kg",
  "FoodC_education_Meal_picking_ziun": "Recomendaciones para la entrega",
  "FoodC_Later_tuMj_hRvh": "Cambiar de tienda",
  "FoodC_saved_jKhv_Cwtv": "Paquetes de cupones",
  "FoodC_perception__XmZq": "Volver a Inicio",
  "FoodC_demand__1_EzSJ": "(1) Si el socio repartidor todavía no ha comprado ningún plato, puedes cancelar tu pedido sin costo.",
  "FoodC_statement_4_Rules_XuZc": "4. Instrucciones para la recolección de paquetes",
  "FoodC_home_How_do_xoCZ": "P: ¿Cómo obtengo cupones de {{entrega_name}}?",
  "FoodC_education_5_Cash_zBuY": "5. Si realizas un pedido con pago en efectivo, procura tener suficiente dinero a la mano para pagar el pedido antes de aceptar la entrega.",
  "FoodC_cancel_Reason_for_vfFe": "¿Cuál es el motivo de cancelación?",
  "FoodC_home1_DiDi_Delivery_jard": "DiDi Entrega",
  "FoodC_home_Can_you_qvdx": "P: ¿Puede usarse un cupón de {{entrega_name}} de una cuenta para pagar un pedido realizado desde otra?",
  "FoodC_home_A_entrega_sgzW": "R: Se puede usar un solo cupón {{entrega_name}} por cada pedido de {{entrega_name}}.",
  "FoodC_Later_bsYo_QDtF": "Agregar artículos al carrito",
  "FoodC_home_Can_the_Jjzi": "P: ¿Puedo obtener un recibo del total que cubre el cupón de {{entrega_name}} de la tarifa de entrega?",
  "sailing.c-api-intl.[提单页]_[商家券]": "Cupones de la tienda",
  "FoodC_Later_rnmL_Piol": "Instrucciones",
  "FoodC_linkjump_jhdQ_iztH": "Abrir en {{DiDiFood}}",
  "FoodC_page_WkPn_SZWm": "Cupones de ahorro",
  "FoodC_home__entrega_HFmI": "R: {{entrega_name}} lanza promociones cada cierto tiempo por llegar a un monto mínimo de gastos para usuarios nuevos y existentes, y cupones por compartir cierto contenido. Te notificaremos las promociones específicas lo antes posible a través de la aplicación o por mensaje de texto.",
  "FoodC_feedback_Order_issues_wWOH": "Problemas con el pedido",
  "FoodC_page_llxi_vTBV": "Promos de la tienda",
  "FoodC_demand_4_Courier_ReDA": "4. Política de cancelación de “Comprado por el socio repartidor”",
  "FoodC_saved_UjVo_BAJJ": "No hay más datos",
  "FoodC_cancel__free_pPaN": "El usuario puede cancelar pagando un monto por cancelación de {{cancellation_fee}} hasta {{free_time}} min después de que el socio repartidor haya aceptado el pedido.",
  "FoodC_cancel_Order_accepted_DmOH": "Esperando a que el socio repartidor acepte",
  "FoodC_cancel_Wait_uNLX": "Regresar",
  "FoodC_feedback_3_When_OcDb": "3. Para reembolsos de pedidos que se pagaron con efectivo, si se aprueba tu solicitud de reembolso, te devolveremos el monto correspondiente en forma de cupón que se agregará en la sección de Billetera.",
  "FoodC_statement_5_Rules_eBJs": "5. Instrucciones para la confirmación de entrega",
  "FoodC_home_What_are_DhsJ": "P: ¿En qué circunstancias es posible que un cupón de {{entrega_name}} ya no pueda usarse y cómo se puede solucionar?",
  "FoodC_home_Because_the_geRF": "R: Debido a que no pagas la parte que cubre el cupón de {{entrega_name}} del total, no puedes obtener un recibo por eso.",
  "FoodC_description_Partial_meal_JWNU": "Faltó parte del pedido:",
  "FoodC_statement_Volume_The_wFKH": "Dimensiones: el largo, el ancho y la altura de un paquete no pueden ser mayores a {{length_limit}} cm x {{width_limit}} cm x {{height_limit}} cm",
  "FoodC_Translation__unk_axrk": "¡Cupón de {{brand_name}} canjeado!",
  "FoodC_demand__3_hmpb": "(3) Política de reembolso para platos agotados:",
  "FoodC_Later_ePJi_KUku": "Actualmente no tienes cupones disponibles. Intenta cambiar tu ubicación",
  "FoodC_home__entrega_VWeG": "R: Los cupones de {{entrega_name}} son emitidos por {{entrega_name}} con el fin de ser redimidos a través de la app y obtener un porcentaje de ahorro en el monto de los pedidos solicitados a través de {{entrega_name}}.",
  "FoodC_statement_Drugs_controlled_drMh": "Drogas controladas o restringidas;",
  "FoodC_home_Can_entrega_mdgU": "P: ¿Se puede recibir cambio al usar cupones {{entrega_name}} o canjearlos por dinero?",
  "FoodC_home_Users_who_Xjqh": "Revisa con cuidado que el pedido cumpla con las condiciones del cupón de {{entrega_name}}.",
  "FoodC_statement_1_What_SFNM": "1. ¿Qué es {{entrega_name}}?",
  "FoodC_page__iyoe": "Ver menos",
  "FoodC_education_1_If_iWDm": "1. Si no te es posible seguir las recomendaciones anteriores para recibir un pedido, podría considerarse que el inconveniente relacionado con la entrega fue tu responsabilidad. Esto incluye, pero no se limita a, situaciones en donde:",
  "FoodC_statement_Laboratory_samples_AmoJ": "Muestras de laboratorio;",
  "FoodC_home_A_new_TGvz": "R: Para utilizar un cupón de usuario nuevo, un usuario debe estar usando {{entrega_name}} por primera vez y completar un pedido correctamente.",
  "FoodC_saved_FWIR_qHYd": "{{app_name}} no cobra tarifas de servicio",
  "FoodC_Later_cyVs_VahD": "Pedir",
  "FoodC_side_UqUs_JnwO": "Código",
  "FoodC_Noavailablestore_mGUe_RipS": "No hay tiendas cerca que participen en esta promoción",
  "Groceries_payment_AeNZ_HMSX": "Continuar",
  "FoodC_C_spYG_gGNu": "El monto en efectivo del reembolso está sujeto a revisión de la tienda o la aplicación",
  "FoodC_ServiceFee_LQae_RZIk": "Ingresa tu dirección",
  "FoodC_demand_5_there_jISx": "5. Tengo una pregunta sobre la información del restaurante, ¿qué debo hacer?",
  "FoodC_page__tlAr": "Promociones",
  "FoodC_issues_Contact_customer_Anvy": "Contactar a Soporte DiDi",
  "FoodC_cancel_Special_circumstances_aOpL": "Otros casos",
  "FoodC_home_Terms_of_ZXoU": "Términos y Condiciones de uso y Aviso de privacidad",
  "FoodC_home_How_many_mego": "P: ¿Cuántos cupones {{entrega_name}} puedo usar por cada pedido a través de {{entrega_name}}?",
  "FoodC_sales_Remember_my_kvqE": "Recordar mi elección y usar este método de reembolso en el futuro.",
  "FoodC_home_How_many_hRpj": "P: ¿Cuántos cupones de {{entrega_name}} puede usar un usuario por día?",
  "FoodC_education_Principles_of_RKEk": "Recomendaciones en caso de algún inconveniente con la entrega",
  "FoodC_Later_ZBxz_opnp": "Usar ahora",
  "FoodD_one_Try_again_qZka": "Inténtalo de nuevo",
  "FoodC_Later_vuZG_sxUt": "No hay cupones",
  "FoodD_one_Service_not_eFvs": "Servicio no conectado",
  "FoodC_feedback_It_was_ZrMP": "Todo el pedido estaba mal",
  "FoodC_statement__1_wZhz": "(1) Si el pedido no es aceptado por un socio repartidor luego del tiempo de espera especificado, se puede cancelar el pedido sin cargo alguno.",
  "FoodC_cancel_Courier_picked_obrP": "Pedido recolectado por el socio repartidor",
  "FoodC_name_DiDi_Flash_TAPX": "DiDi Entrega",
  "Entrega_name_99_Flash_maAd": "99Entrega",
  "FoodC_education_Basic_requirements_hIbv": "Recomendaciones para los usuarios con respecto a la entrega",
  "FoodC_cancel_Cancel_the_MyBx": "Estado",
  "FoodC_demand__2_eSAk": "(2) Si un plato está agotado, puedes:",
  "FoodC_description_Indicates_that_MWBQ": "El socio repartidor no entregó el pedido, por lo que no recibiste ningún artículo.",
  "FoodC_statement__5_eCLd": "(5) Si el pedido se cancela debido a un accidente, una falla del vehículo o por algún otro motivo que tenga que ver con el socio repartidor dirigiéndose a recolectar el paquete, se asignará otro socio repartidor automáticamente para que realice la recolección.",
  "FoodC_home_The_sum_zzUZ": "R: Cada usuario puede usar hasta tres cupones de {{entrega_name}} por día. Un usuario se identifica por la cuenta, el número de teléfono, el método de pago, el dispositivo y otros identificadores similares, por lo que no se pueden usar los mismos más de una vez.",
  "FoodC_description_Indicates_that_fnET": "El socio repartidor entregó el pedido, pero no entregó uno o más artículos.",
  "FoodC_feedback_1_When_buWL": "1. Para un reembolso parcial, el monto a reembolsar será el valor total de los artículos de los cuales solicitaste el reembolso, menos los descuentos aplicados. La tarifa de entrega y la propina no se reembolsarán.",
  "FoodC_Later_FVUi_pcGM": "Esta promoción ha finalizado. ¡Mantente conectado para acceder a la próxima!",
  "FoodC_demand__If_qODk": "* Si elegiste comprar el resto de los platos que están disponibles, te reembolsaremos automáticamente el importe de los platos agotados si pagaste a través de la aplicación. En el caso de los pedidos con pago en efectivo, no tendrás que pagar los platos que estén agotados.",
  "FoodC_demand_If_you_SpKh": "Si tienes alguna pregunta sobre la información de un restaurante, contacta a Soporte DiDi llamando al 010-9757585.",
  "FoodC_c_Unable_to_cPrS": "Servicio no disponible",
  "FoodC_home_Special_note_LSrQ": "Importante:",
  "FoodC_home__Validity_FDCw": "R: Los cupones de {{entrega_name}} tienen una vigencia específica y sólo se pueden utilizar durante ese periodo. Una vez finalizado ese plazo, automáticamente dejará de ser válido.",
  "FoodC_sales_Supplementary_information_ihyk": "Detalles adicionales",
  "FoodC_home_The_user_GKZd": "Pagar el pedido a través de la aplicación o en efectivo. El número de teléfono que ingresaste al usar el cupón en un pedido debe coincidir con el número vinculado a tu cuenta.",
  "FoodC_demand__3_cTVC": "(3) Si el restaurante al que le hiciste el pedido está cerrado, un plato está agotado, ocurre un accidente o el restaurante o el socio repartidor cancelan el pedido por otro motivo, recibirás un reembolso completo.",
  "FoodC_saved_Euxs_oQHV": "Desde el 01/01/2020 has ahorrado un total de",
  "FoodC_page_Sorry_the_tNDc": "Lo sentimos, no pudimos procesar la solicitud debido a actividad irregular. Si necesitas ayuda, contacta a Soporte DiDi.",
  "FoodC_education_In_the_nIgD": "La cancelación se considerará responsabilidad tuya en las siguientes situaciones:",
  "FoodC_demand__Let_qvly": "* Pedir al socio repartidor que te contacte y luego seleccionar una de las opciones anteriores.",
  "FoodC_statement__2_mmNa": "(2) Se recomienda que el remitente revise que el paquete cumpla con las siguientes características para su entrega:",
  "FoodC_enhance_sGnQ_mmEf": "Sólo usuarios",
  "FoodC_side_AeEL_MHLA": "Todos los cupones",
  "FoodC_education__2_KHhl": "(2) Si no llegaste al lugar de la entrega para la hora especificada o si ingresaste la dirección o número de teléfono incorrectos, te negaste a aceptar una llamada del socio repartidor o te negaste a recibir parte del pedido o el pedido completo sin un motivo válido, se deberá pagar el pedido.",
  "FoodC_education_If_you_RTIV": "Si pagaste un pedido y necesitas cancelarlo, se aplicará lo siguiente:",
  "FoodC_education_2_Receiving_gFZl": "2. Revisa que la dirección de entrega y el método de contacto que proporciones sean los correctos.",
  "FoodC_education__3_TDsa": "(3) Te negaste a recibir el pedido, ya sea una parte del mismo o por completo",
  "FoodC_education_2_If_SoSk": "2. Si un inconveniente relacionado con una entrega sucede porque el socio repartidor no siguió las recomendaciones, se podría considerar que fue responsabilidad del socio repartidor.",
  "FoodC_education__unk_aOCD": "Recomendaciones de la aplicación sobre el tipo de entrega:  {{delivery_type}}",
  "FoodC_demand_Purchasing_services_jshb": "Con “Comprado por el socio repartidor”, puedes solicitarle a un socio repartidor que compre una variedad de platos (como pizza, hamburguesas, té con leche o café) de restaurantes cercanos (la disponibilidad de los platos puede variar) y luego los entregue directamente en tu puerta.",
  "FoodC_Later_WRgg_djEL": "Comprar",
  "Global_Driver_Multiplier_new_interaction_Current_activities_vSRD": "Este evento aún no inicia",
  "sailing.c-api-intl.[商家营销标签分类]_[菜品活动]": "Promoción de artículos",
  "FoodC_store_Store_voucher_NnMx": "¡Cupón de la tienda canjeado!",
  "FoodC_education__2_jwSo": "(2) Dejar en la puerta (con nota)",
  "sailing.c-api-intl.[商家营销标签分类]_[满X减Y]": "Gasta X Ahorra Y",
  "FoodC_home_Possible_causes_wopU": "R: Estos son algunos de los problemas comunes y sus soluciones:",
  "FoodC_Later_VHDh_UWSb": "Ver todas las tiendas disponibles",
  "GDriver_5_Event_ended_gNeH": "Esta promoción ha finalizado.",
  "FoodC_c_Try_again_lIZQ": "Reintentar",
  "FoodB_optimization_See_more_rbCl": "Ver más",
  "FoodC_page__Zjra": "Cupones",
  "FoodC_home_If_the_SPtR": "R: Si usas un cupón de {{entrega_name}} y no completas el pago para el momento en el que se canceló el pedido, te devolveremos el cupón a tu cuenta luego de cancelado el pedido. Si el pago ya se completó antes de que se haya cancelado el pedido, no se podrá devolver el cupón a tu cuenta, y el pago del pedido se reembolsará al método de pago original dentro de los siguientes treinta días hábiles.",
  "FoodC_ServiceFee_EvAo_Aloo": "Dirección",
  "FoodC_Translation_Sign_in_pljT": "Por favor, inicia sesión",
  "FoodC_statement_If_you_Jbkm": "Si un paquete está incompleto, se perdió, está dañado o se entregó el paquete incorrecto, contacta a Soporte DiDi lo antes posible.",
  "FoodC_statement_3_Flash_IEYW": "3. Política de cancelación",
  "FoodC_home_No_entrega_GnGI": "R: Lamentablemente, no. Los cupones de {{entrega_name}} de una cuenta sólo pueden usarse para pagar solicitudes de servicio de {{entrega_name}} de esa cuenta.",
  "FoodC_Later_jqbf_DAWh": "Tiendas fuera del rango de entrega",
  "FoodC_education_4_Customers_laxR": "4. Una vez que el socio repartidor llegue a tu ubicación, tendrás 10 minutos para recibir tu pedido.",
  "FoodC_linkjump_LAQl_lfnD": "Abrir en {{DiDi}}",
  "FoodC_cancel_Users_initiate_suVB": "El usuario puede enviar una solicitud de cancelación, la cual no puede eliminarse una vez enviada. La cancelación se completará una vez que el socio repartidor haya devuelto el paquete. El pago en efectivo de este pedido no se reembolsará.",
  "FoodC_demand__1_LhGa": "(1) Cuando haces un pedido, te preguntaremos qué quieres hacer en caso de que un plato esté agotado y el socio repartidor seguirá tus instrucciones.",
  "FoodC_statement_pornography_WPQI": "Material pornográfico;",
  "soda-b-homepage-i18n---登录": "Iniciar sesión",
  "FoodC_statement_Value_Items_svYm": "Valor declarado: el valor del paquete no puede ser mayor a {{value_limit}}",
  "sailing.act-api-intl.去使用": "Usa ahora",
  "FoodC_title_Please_choose_yekD": "Elige todos los artículos a reportar",
  "FoodC_saved_nDkp_KPcN": "Ahorros y promociones",
  "FoodC_sales_Condition_of_lnwg": "¿Estaba cerrado el empaque? (opcional)",
  "FoodC_Translation_Successful_exchange_BVbd": "Los cupones {{coupon_name}} ya están en tu carrito",
  "FoodC_home_3_If_BGBo": "3. Si tienes alguna pregunta relacionada con los Términos y Condiciones o con la aplicación y los servicios de {{brand_name}}, contacta a nuestro equipo de soporte.",
  "FoodC_statement__4_bDLh": "(4) El destinatario debe abrir el paquete inmediatamente después de realizada la entrega para verificar el contenido.",
  "FoodC_demand_1_What_aoLM": "1. ¿Qué es “Comprado por el socio repartidor”?",
  "FoodC_page_DIKR_secx": "Promos de entrega",
  "FoodC_demand__You_bJcT": "* Paga en efectivo: después de que hagas un pedido, el socio repartidor comprará los platos que pediste y luego le pagarás en efectivo cuando te lo entregue.",
  "FoodC_issues_Sorry_for_aHCW": "Nos comprometemos a ofrecer la mejor experiencia posible. Si necesitas ayuda, contáctanos.",
  "FoodC_education__1_IIfh": "(1) Si cancelaste antes de que la tienda haya aceptado el pedido con la función \"Cancelar pedido\" en la sección de Detalles del pedido.",
  "FoodC_reasons_Enter_a_Pnzg": "Por favor, describe los detalles del problema.",
  "FoodC_sales_How_do_ylCn": "¿Plato sellado?",
  "FoodC_demand__2_TZJq": "(2) Si el socio repartidor ha comprado un plato para ti y no llega muy tarde, tendrás que pagar el pedido si lo cancelas.",
  "FoodC_home_No_When_xNrI": "R: Lamentablemente, no. Al usar un cupón de {{entrega_name}}, el valor del cupón de {{entrega_name}} se restará del monto total del pedido. Si el valor del cupón excede el total del pedido, la diferencia no se puede retirar, reembolsar, recibir como cambio, vender, convertir o transferir a otro usuario. Si el total del pedido supera el valor del cupón, el usuario deberá pagar la diferencia.",
  "FoodC_education__4_QYXm": "(4) Te negaste a pagar el pedido con pago en efectivo",
  "FoodC_statement__2_Gymp": "(2) Existe un periodo para poder cancelar un pedido sin cargo alguno, luego de que un socio repartidor lo acepte y antes de que recolecte el paquete. Una vez pasado ese periodo, se aplicará una tarifa por cancelación si cancelas.",
  "FoodC_education_In_the_MKeB": "La cancelación no se considerará responsabilidad tuya en las siguientes situaciones:",
  "FoodC_statement__1_hBNr": "(1) Luego de que el socio repartidor acepte un pedido, se recomienda que el remitente deba estar atento a su teléfono y estar presente en el punto de recolección para encontrarse con el socio repartidor a tiempo.",
  "FoodC_education__3_ltNM": "(3) Otros factores que no puedas controlar y que causaron la cancelación del pedido, como que no se haya podido encontrar un socio repartidor que entregue el pedido o algún otro asunto.",
  "FoodC_statement__1_ZXJA": "(1) Para enviar un paquete, el remitente simplemente debe ingresar en la app la dirección de recolección y de entrega, su información de contacto y la del destinatario y los detalles sobre el paquete.",
  "FoodC_statement_6_Lost_VmQE": "6. Seguimiento de paquetes perdidos o dañados",
  "FoodC_home_When_the_qNRR": "Si la conexión de red es inestable, es posible que no se procese el pago. Intenta moverte y buscar un lugar con mejor señal para intentarlo de nuevo.",
  "FoodC_Later_abhG_KiUN": "Comprar",
  "FoodC_home_What_are_TUik": "P: ¿Cuáles son los requisitos para usar un cupón de usuario nuevo?",
  "FoodC_statement_Items_prohibited_vdcx": "Cualquier otro artículo prohibido por las leyes y regulaciones locales o términos y condiciones de uso de la app.",
  "FoodC_Later_UYPU_ZvGX": "Entregar a",
  "FoodC_education_In_order_AZCw": "Las recomendaciones para la entrega se crearon con el motivo de ayudar a que todos los usuarios tengan una buena experiencia con {{brand_name}}. En caso de que haya algún inconveniente relacionado con la entrega, se aplicará lo siguiente.",
  "FoodC_home_Under_what_udiG": "P: ¿Cuáles son las condiciones para usar cupones de {{entrega_name}}?",
  "FoodC_sales_Not_set_AJmE": "Más tarde",
  "FoodC_home_The_user_DZGn": "Tener un cupón de {{entrega_name}} que puedas usar en la sección de billetera de la aplicación.",
  "FoodC_education__2_KZTZ": "(2) El socio repartidor no pudo contactarte",
  "FoodC_fee_Ukhd_NWfo": "Sólo se muestra un cupón con el mismo límite y descuento. Puedes acceder a tu cuenta para ver todos",
  "sailing.c-api-intl.推荐": "Recomendado",
  "FoodC_page__KoEd": "Ver más",
  "FoodC_statement__6_cuGI": "(6) Si luego de la recolección, el socio repartidor nota que el paquete a entregar no cumple con los requisitos o con la política de contenidos de paquetes, o a su criterio considera que el paquete representa cualquier tipo de irregularidad, riesgo o es sospechoso, puede cancelar el pedido y no entregar el paquete.",
  "FoodC_education_Cancellation_Policy_fxrl": "Política de cancelación",
  "FoodC_statement_Currently_DiDi_jUEl": "{{entrega_name}} es un servicio de mensajería que te permite realizar un pedido a través de la app para que un socio repartidor recolecte un paquete y lo entregue a una ubicación específica o a un destinatario cercano. Luego de que un socio repartidor acepte el pedido, recolectará el paquete en la dirección indicada y lo entregará en el punto de entrega o al destinatario que especificaste.",
  "FoodC_description_The_entire_lTeJ": "El pedido no se entregó:",
  "FoodC_statement__3_JHtK": "(3) El solicitante/remitente, es responsable de cubrir los costos asociados al pedido. Para los pedidos con pago con tarjeta, los pagos deben completarse en el momento en que se realiza el pedido. Para los pedidos con pago en efectivo, el pago debe entregarse al socio repartidor cuando recolecte el paquete. El destinatario no es responsable de pagar cargos o tarifas del pedido.",
  "FoodC_cancel_When_an_XxLF": "Para pagos a través de la aplicación, el monto por cancelación no será mayor que el monto de la tarifa de entrega.",
  "FoodC_statement_Dangerous_materials_tLOa": "Materiales o desechos peligrosos (líquidos o sólidos inflamables; gases comprimidos almacenados en forma licuada, no licuada o disuelta; sustancias tóxicas, infecciosas, radiactivas o corrosivas);",
  "FoodC_statement_Type_You_sJki": "Contenido del paquete: no se puede usar {{entrega_name}} para entregar artículos prohibidos, entre los cuales están los siguientes:",
  "FoodC_statement__5_ZbOp": "(5) Si el paquete se perdió, está dañado, incompleto o se entregó el paquete incorrecto, se recomienda contactar primero al socio repartidor sobre la situación. Si no se puede llegar a un acuerdo, contacta a Soporte DiDi para obtener ayuda.",
  "FoodC_home_When_placing_UpoJ": "P: Si cancelo un pedido en el que usé un cupón de {{entrega_name}}, ¿me devolverán el cupón de {{entrega_name}}?",
  "FoodC_sales_Tips_LCST": "Nota",
  "FoodC_Later_ubvI_oLLU": "Disfrutar más tarde",
  "您的订单还未完成，您可以联系骑手确认当前的具体配送位置。": "Tienes un pedido abierto que aún no ha sido entregado. Si necesitas conocer la ubicación actual de tu pedido, por favor ponte en contacto con el socio repartidor.",
  "您所在的地区还未开通服务": "Servicio aún no disponible en tu área",
  "可以把促销码换成钱吗？ ": "¿Se puede convertir un código promocional en efectivo?",
  "不满意": "Mala",
  "用户使用%s券应同时满足以下条件：": "Un usuario que utiliza cupones de %s debe cumplir las siguientes condiciones:",
  "怎样才能获得%s券？": "¿Cómo puedo obtener un cupón de %s?",
  "取消订单进度": "Estado de la cancelación",
  "4、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "4. Para los pedidos demorados que no pueden entregarse, no se otorgarán cupones en los siguientes casos:",
  "早间：%s-%s": "Durante la mañana: %s a %s",
  "跳过": "Saltar",
  "个人中心的“优惠券”": " Mi Perfil > Cupones.",
  "联系商家处理": "Contactar a la tienda",
  "用户的%s软件版本较低，可在升级%s软件版本后再进行支付。": "El usuario está usando una versión del software de %s que es demasiado antigua. Intenta actualizar el software a la versión más reciente y vuelve a realizar el pago.",
  "商家正在备餐": "La tienda está preparando el pedido.",
  "1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "1. Como parte de nuestro programa de lealtad queremos darte unos cupones en caso de una entrega con demora. Si un pedido no se entrega en el tiempo de entrega estimado (salvo los pedidos cancelados), %s te dará uno o más cupones gratis. Puedes ver los cupones una vez que se complete el pedido en",
  "申请信息": "Detalles de la solicitud",
  "返回首页": "Volver a Inicio",
  "在极少数情况下，如果您对订单费用产生疑议，可以点击“帮助”联系我们，客服团队会尽快为您解答。": "Si sucede una situación improbable de conflicto con una tarifa, haz clic en Ayuda para contactarnos. Nuestro servicio de Soporte DiDi estará dispuesto a resolver tu problema enseguida.",
  "我要注册！": "¡Quiero registrarme!",
  "你的位置还不在我们服务范围内。我们很快就会扩大我们的覆盖范围，之后你就可以使用%s啦。请点击下面的按钮，我们会在有服务之后通知你。": "Tu ubicación aún no está dentro de nuestra área de servicio. Muy pronto ampliaremos nuestra cobertura y podrás disfrutar de %s. Haz clic abajo y te notificaremos cuando el servicio esté disponible.",
  "您对商家服务满意吗？": "¿Cómo calificarías a esta tienda?",
  "现金": "Efectivo",
  "用户不符合%s券使用规则中列明的可以使用券的条件，应仔细阅读并保证符合优惠券注明的使用条件；": "El usuario no cumple las condiciones de uso especificadas en los términos de uso del cupón de %s. Los usuarios deben leer los términos de uso cuidadosamente para asegurarse de cumplir las condiciones.",
  "%s券上会注明“有效期”，该优惠券仅能在注明的有效期内使用，过期优惠券将自动失效不可用。": "Los cupones de %s tienen un periodo de vigencia específico y sólo se pueden utilizar durante ese periodo. Una vez finalizado ese plazo, el cupón automáticamente dejará de ser válido.",
  "将赔付给您1张%s优惠券": "Obtienes un cupón de %s.",
  "1.店铺优惠指的是“满X减Y”活动。": "1. El descuento de la tienda se refiere a las promociones de “Gasta x, ahorra y”.",
  "满%s可用": "más de %s",
  "订单": "Pedidos",
  "店铺优惠说明": "Detalles de descuento de la tienda.",
  "订单取消成功": "Pedido cancelado",
  "目前没有限制。优惠券有使用限制，您可以查看下方优惠券规则，了解一天可以使用多少优惠券。": "Actualmente, no hay restricciones, aunque los cupones tienen restricciones de uso. Consulta las reglas de cupones para averiguar cuántos puedes utilizar al día.",
  "一个用户一天内最多能够使用几张%s券？": "¿Cuántos cupones de %s puede usar un usuario al día?",
  "仅限%s使用": "Sólo %s",
  "您对商家服务的评价": "Calificaste a esta tienda.",
  "恶劣天气及运力紧张情况下，配送费根据实际情况略有浮动。": "Cuando hay mal clima o poca capacidad operativa, las tarifas de entrega pueden variar en función de las circunstancias reales.",
  "进度详情": "Estado de la solicitud",
  "平台优惠": "Promociones de la plataforma",
  "使用规则": "Términos de uso",
  "2.“满X减Y”和“特价菜”、“优惠套餐”活动优惠可同享。": "2. Los descuentos de “Gasta x, ahorra y” pueden combinarse con las promociones de “artículo de venta” y “combo”.",
  "反馈超时": "Venció el tiempo para comentarios.",
  "促销码与促销活动相关，有有效期，超过有效期会兑换失败。": "Los códigos promocionales están vinculados a eventos promocionales. Ambos tienen periodos de validez y fechas de vencimiento. No se aceptará ningún canje luego de su vencimiento.",
  "可以用一个账号内的%s券为其他账号内的订单进行支付吗？": "¿Puede usarse un cupón de %s de una cuenta para pagar un pedido realizado desde otra?",
  "获得权益": "REGISTRARSE",
  "我知道了": "Aceptar",
  "查看已开通的区域": "Ver área de envío",
  "提交": "Enviar",
  "您的问题我们已经收到，将在48小时内给您反馈，并以短信/邮件通知您，请耐心等待。": "Recibimos tu mensaje y te responderemos por mensaje de texto o correo electrónico en 48 horas. ¡Gracias por tu paciencia!",
  "小费金额 = 餐品总价 * 小费比例，你可以点击去选择你的小费比例。（餐品总价不包含配送费和优惠券）": "Importe de la propina: el total del pedido x el porcentaje de propina (el total del pedido no incluye la tarifa de entrega ni los cupones).",
  "订单尚未完成": "Tienes un pedido abierto.",
  "补充更多信息，以便快速处理（选填）": "Proporciona otros detalles que ayuden a resolver el problema de manera más rápida (opcional).",
  "3.同一商家，最多可以有3个“满X比索减Y比索”活动，自动为您选择优惠力度最大的折扣。": "3. Pueden canjearse hasta 3 promociones de “Gasta x, ahorra y” por tienda. Las promociones con los descuentos más altos se aplican de forma automática primero.",
  "更多商家即将上线，点击“请通知我”商家上线时我们将第一时间通知你": "Habrá más restaurantes disponibles próximamente. Haz clic en “notificarme” para recibir notificaciones cuando los nuevos restaurantes estén instalados.",
  "用户应当出于合法、正当目的以合理方式使用%s券。用户不得单独或与商家恶意串通，利用%s券实施欺诈等非法行为以达到套取现金、获得积分或礼品等目的。且用户不得利用%s券进行售卖、兑换或从事其他营利性活动。如果平台发现用户存在前述违法违规行为，%s有权拒绝该用户使用%s券和/或对该用户账号和/或关联账号采取临时或永久停止平台服务等措施，并保留通过法律途径追究用户法律责任的权利。": "Los usuarios deberán utilizar los cupones de %s de una manera razonable para fines legítimos y apropiados. No podrán conspirar, individualmente o en conjunto con una tienda a fin de utilizar los cupones de %s para cometer fraudes u otras actividades ilegales con el propósito de obtener dinero en efectivo, puntos, regalos u otros beneficios. Los usuarios tampoco podrán utilizar los cupones de %s en ventas, intercambios u otras actividades con fines de lucro. Si se descubre que un usuario participa en cualquier actividad ilícita mencionada anteriormente, %s se reserva el derecho de rechazar el uso de los cupones de %s por parte del usuario y congelar temporal o permanentemente los servicios en la plataforma de la cuenta del usuario o de las cuentas relacionadas. %s también se reserva el derecho de iniciar acciones legales contra el usuario.",
  "由于用户未实际支付%s券抵用部分订单金额，因此用户不能获得%s券抵用部分金额的发票。": "Como el usuario en realidad no está pagando la parte de la tarifa que se descuenta a partir del cupón de %s, no se le puede enviar al usuario un recibo oficial correspondiente a esa parte del total.",
  "本次服务评价成功": "Gracias por tus comentarios",
  "提交申请": "Solicitar cancelación",
  "取消": "Cancelar",
  "请选择取消原因以便商家更快帮您处理": "Para ayudar a la tienda a procesar tu solicitud más rápido, por favor, selecciona el motivo de la cancelación.",
  "钱包": "Billetera",
  "怎么样开通免密支付？": "¿Cómo habilito los pagos sin contraseña?",
  "什么是%s券？": "¿Qué es un cupón de %s?",
  "谢谢。我们会及时通知你": "¡Gracias! Te mantendremos informado.",
  "退款金额": "Monto del reembolso",
  "通用支付": "Todos los medios de pago",
  "促销码是字母和数字的组合。": "El código promocional está compuesto por letras y números.",
  "6、每位用户每天最多赔付6张优惠券；": "6. Se limita a 6 cupones por usuario al día;",
  "感谢您提前注册账号！我们将会及时为您发送最新的滴滴外卖活动信息。我们的服务即将开始，请拭目以待！": "¡Gracias por registrarte con anticipación! Te enviaremos las últimas actividades para llevar de DiDi de manera oportuna. Nuestro servicio comenzará pronto, ¡espera y mira!",
  "用户在开通%s服务的城市和/或地区下外卖订单；": "El usuario debe realizar un pedido en una ciudad o región donde estén disponibles los servicios de %s.",
  "促销码由什么组成？": "¿Qué formato tiene el código promocional?",
  "能导致%s券不能使用的原因和解决办法是什么？": "¿En qué circunstancias es posible que un cupón de %s ya no pueda usarse y cómo se pueden resolver?",
  "请您上传所收到的全部商品照片、问题商品细节照片、商品小票照片，这将协助我们更快地处理您的问题。": "Para ayudarnos a procesar tu solicitud más rápido, sube fotos de lo siguiente: todos los artículos recibidos; una foto de cerca del artículo con daños; y recibo del artículo.",
  "更多评价": "Más calificaciones",
  "99Pay-余额": "99Pay - Saldo",
  "详细说明": "Detalles",
  "好的，我知道了": "Entendido",
  "4、部分时间段下单暂不参加赔付；": "4. Los pedidos realizados durante determinadas horas no recibirán compensación;",
  "您的免配送费权益将于%s过期": "Tu membresía de entrega gratuita vence el %s.",
  "提交反馈": "Enviar comentarios",
  "帮助中心": "Ayuda",
  "6、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "6. Anota el tiempo de entrega informado por tu socio repartidor. Si un socio repartidor reclama que entregó un pedido antes de que lo hayas recibido, llama al Soporte DiDi de %s al %s para presentar un reclamo.",
  "获得新用户立减活动资格的新用户，是指第一次使用%s并成功完成订单的用户。新用户优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。": "Son aptos para la promoción de descuento instantáneo que se ofrece a nuevos usuarios los usuarios que utilizan %s por primera vez y que realizaron un pedido con éxito. Para averiguar si el premio de bienvenida se puede usar simultáneamente en artículos que ya tienen un descuento de la tienda o que ya tienen descuentos de gastos fijos, consulta los términos detallados de la promoción particular de %s.",
  "%s公里之内配送费": "Tarifa de entrega dentro de %s km",
  "我们很快会扩大服务范围，点击“请通知我”，我们将会在开通时通知你": "Activaremos más áreas pronto, haz clic en \"notificarme\" y te informaremos la última gama de servicios cuando la lancemos.",
  "支付配送费": "Pagar tarifa de entrega",
  "商家自行配送的配送费收取规则：": "Reglas de cobro de la tarifa de entrega de las entregas en la tienda:",
  "促销码是什么？": "¿Qué es un código promocional?",
  "仅限支付卡bin为%s可用": "Sólo disponible si la bandeja de tarjetas es %s.",
  "请告诉我们，帮助我们一起改善": "Por favor, envíanos tus comentarios y ayúdanos a mejorar.",
  "基础配送费": "Tarifa de entrega base",
  "当有配送费活动时，根据活动情况对配送费进行相应的优惠。": "Cuando hay una promoción para la tarifa de entrega, se descontará una cierta cantidad de esta tarifa en función de la promoción.",
  "申请取消原因": "Motivo de la cancelación",
  "打开": "Abrir",
  "查看评价": "Ver calificaciones",
  "%s券可以找零或兑换吗？": "¿Se puede recibir cambio cuando se usan cupones de %s o se puede canjearlos por efectivo?",
  "收起": "Ver menos",
  "5、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "5. Para los pedidos demorados que no pueden entregarse, no se otorgarán cupones en los siguientes casos:",
  "优惠券": "Cupones",
  "不可用原因": "Motivo no válido",
  "(2) 因您的配送地址所在地不允许骑手入内，导致超时送达或无法送达的；": "(2) La dirección que has proporcionado no permite el ingreso de los socios repartidores y esto ocasiona una demora o la imposibilidad de realizar la entrega;",
  "网络不佳，请检查您的网络": "Mala conexión. Comprueba tu conexión de internet.",
  "周五": "Viernes",
  "您可以使用现金支付订单，请备好足够的零钱。": "Debes pagar tu pedido en efectivo. Prepárate para pagar con la cantidad justa.",
  "周二": "Martes",
  "最高抵扣%s": "Aplicable a %s",
  "特殊说明": "Comentarios especiales",
  "现金支付": "Pago en efectivo",
  "已发起取消，等待商家确认": "Solicitud de cancelación enviada. Esperando confirmación de la tienda.",
  "3、恶劣天气（雨、雪等）暂不参加赔付；": "3. Si las demoras son ocasionadas por mal clima, no se otorgarán cupones;",
  "兑换优惠": "Canjear cupón",
  "现在订餐": "VOLVER",
  "如用户使用了%s券，取消订单时尚未完成支付，相关优惠券在用户取消订单后将即时退回账户。如用户取消订单时已完成支付，相关优惠券不再退回账户。订单款项将于订单取消后一周内原路退回支付账户。": "Si un usuario utiliza un cupón de %s y cuando realiza la cancelación y todavía no completó el pagó, se devolverá el cupón a la cuenta del usuario después de que se cancele el pedido. Si el pago ya se completó en el momento de la cancelación del pedido, el cupón no se devolverá a la cuenta del usuario y el pago correspondiente al pedido se reembolsará a la cuenta desde la cual se realizó el pago en el plazo de una semana.",
  "用户未违反平台外卖券使用规则，且该笔订单不属于平台判定的作弊或类似作弊订单等禁用%s券的情况。": "El usuario debe cumplir con los términos de uso del cupón de la plataforma. La plataforma no debe considerar que el pedido es fraudulento, un tipo de pedido fraudulento o que está incluido en cualquier otra condición que prohíba el uso de los cupones de %s.",
  "我们正在努力拓展范围。如果您希望 %s 的服务尽快到您身边，请告诉我们": "Estamos esforzándonos por expandirnos. Si quieres que %s esté en tu área tan pronto como sea posible, comunícate con nosotros.",
  "已经累计为您节省了%s配送费": "Has ahorrado %s en costos de entrega.",
  "%s致力于通过无密码和现金支付流程为我们的客户提供简单安全的付款体验。": "%s permite opciones de pago fuera de línea y sin contraseña seguras y fáciles de usar.",
  "用户兑换促销码的次数限制是多少？": "¿Cuántas veces puede un usuario canjear un código promocional?",
  "配送费受多种因素影响，包括您和餐厅之前的距离，配送的最终地址和该地区的供需情况。": "Se calcula con base en la distancia entre el restaurante y la ubicación de entrega y puede variar según el incremento de la demanda.",
  "免配送费权益": "Membresía de entrega gratuita",
  "%s平台配送费收取规则：": "Reglas de cobro de la tarifa de entrega de la plataforma de %s:",
  "不可以。一个账号内的%s券仅可用于支付该账号内的%s订单。": "Lamentablemente, no. Los cupones de %s de una cuenta solamente pueden usarse para pagar pedidos realizados desde esa misma cuenta.",
  "部分餐品缺失- 表示骑手已经给您交餐，表示骑手已经给您交餐，但您有部分餐品没有收到。": "Faltaba parte del pedido: el socio repartidor entregó el pedido, pero uno o más platos no fueron entregados.",
  "如果对费用提出争议，将如何解决？": "Si hay un conflicto con una tarifa, ¿cómo se resolverá?",
  "（必填）请填写您的申诉原因": "Por favor, especifica el motivo para solicitar una revisión (obligatorio).",
  "2、部分时间段下单暂不参加赔付；": "2. Los pedidos realizados durante determinadas horas no recibirán compensación;",
  "很抱歉给你造成了不好的体验，请选择您遇到的问题：": "Lamentamos las molestias ocasionadas. Por favor, selecciona el problema que sucedió: faltaba parte del pedido:",
  "请通知我": "Notificarme",
  "送您1张%s优惠券；": "Te daremos un cupón de %s.",
  "恭喜你！兑换成功！": "¡Felicidades! Se canjeó el cupón.",
  "用户绑定了银行卡或使用现金支付；": "El usuario debe vincular una tarjeta a su cuenta o pagar en efectivo.",
  "您的订单为商家配送，您可点击下方的联系商家按钮与商家进行问题协商。": "Esta entrega está a cargo de la tienda. Para obtener más información sobre el estado del pedido, pulsa el botón de contactar a la tienda a continuación.",
  "同一个促销码可以重复兑换么？": "¿Se puede canjear varias veces el mismo código promocional?",
  "%s券抵用餐费部分可以开发票吗？": "¿Es posible obtener un recibo oficial que corresponda a la parte de la tarifa que se descuenta a partir del cupón de %s?",
  "已过期券": "Cupones expirados",
  "有效期至": "Válido hasta",
  "他们已加入%s": "Se han unido a %s.",
  "卡": "Tarjeta",
  "用户支付外卖订单时使用%s券并支付成功；": "Al realizar el pago de un pedido, el usuario debe utilizar el cupón de %s y completar el pago correctamente.",
  "周四": "Jueves",
  "一个外卖订单最多只能使用一张%s平台券。如：品类券与配送费券不可同时使用。": "Puede utilizarse un cupón de la plataforma de %s por pedido. Por ejemplo, no puede utilizarse un cupón de categoría y un cupón de tarifa de entrega en el mismo pedido.",
  "促销码有有效日期嘛？": "¿Los códigos promocionales tienen una fecha de vencimiento?",
  "你可以在个人中心的“优惠券”或“钱包”-“优惠券”中兑换它。": "Puede canjearlo en Cuenta > Cupones o Billetera > Cupones.",
  "%s平台处理": "Deja a %s a cargo.",
  "由配送商家决定配送金额。": "La tienda determina la cantidad de entrega.",
  "晚间：%s-%s": "Durante la noche: %s a %s",
  "配送费详情": "Detalles de la tarifa de entrega",
  "将赔付给您1张%s折扣券": "Obtienes un cupón del %s de descuento.",
  "送您%s张免配送费券；": "Te daremos %s cupón para un envío gratis.",
  "正在寻找骑手": "Buscando un socio repartidor",
  "反馈部分餐品缺失": "Por favor, menciona qué artículo falta.",
  "3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。": "3. Si pagaste en efectivo por tu pedido y eres elegible para un reembolso, tu reembolso se enviará en forma de cupón y se agregará a tu Billetera.",
  "优惠券使用规则": "Términos de uso del cupón",
  "验证成功": "Verificación exitosa",
  "%s券是%s发放给用户，用户在支付%s订单时，用于抵扣订单金额的电子券。": "Un cupón de %s es un cupón electrónico que %s emite a los usuarios. Se puede usarlo para descontar el monto de un pedido al momento de pagar pedidos en %s.",
  "提交成功": "Enviado",
  "用户在“钱包”中有可使用的%s券；": "En la Billetera del usuario, debe haber un cupón de %s disponible para su uso.",
  "延迟送达%s分钟以上": "Demora mayor a %s minutos",
  "您的小费将全部支付给骑手": "El socio repartidor recibirá el importe total de la propina.",
  "让 %s 进入我的地区": "Traer %s a mi área.",
  "在支付方式中添加您的银行卡，成功绑定后即可开通免密支付。": "Los pagos sin contraseña se habilitarán una vez que agregues una tarjeta válida de un banco en la sección de agregar un método de pago.",
  "兑换失败": "No se puede canjear.",
  "我们正在努力拓展中。您可以访问周边热门商圈看看美食": "Estamos esforzándonos por ampliar nuestra cobertura. Echa un vistazo a la comida disponible en los distritos de tiendas populares cercanas.",
  "满意": "Buena",
  "再等等": "Esperar",
  "骑手正在配送中": "El socio repartidor está en camino.",
  "周六": "Sábado",
  "描述": "Descripción",
  "5、每位用户每天最多赔付6张优惠券；": "5. Se limita a 6 cupones por usuario al día;",
  "2.点击选择“浏览器”，进行下载": "2. Pulsa y selecciona “navegador” para descargar.",
  "%s将不定时地通过在本页面发布更新版本的方式修改使用规则。请用户定期访问本页面并查看最新版本。这些规则对用户均具有约束力。": "Ocasionalmente, es posible que %s modifique los términos de uso, los cuales se publicarán en una edición actualizada de esta página. Por eso, es necesario que los usuarios consulten esta página con frecuencia para ver la última edición. Estos términos son vinculantes para todos los usuarios.",
  "无效券": "Cupones inutilizables",
  "/公里": "/km",
  "当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "Si un pedido no se entrega en el tiempo de entrega estimado (salvo los pedidos cancelados), %s te dará uno o más cupones para remediar las cosas. Una vez que se complete tu pedido, puedes ver los cupones en ",
  "出生日期": "Fecha de nacimiento",
  "餐费": "Tarifa de comida",
  "完成注册登录，以便开通服务后通知您": "Completa el registro y el inicio de sesión para notificarte cuando el servicio esté disponible.",
  "不使用优惠券": "No usar cupón",
  "请上传商品和小票照片": "Sube fotos del artículo y el recibo del pedido.",
  "商家优惠": "Promociones de la tienda",
  "你好": "Hola",
  "CPF": "CPF",
  "附近商家正在筹备中": "Tiendas cercanas que están preparándose.",
  "失败": "Fallar",
  "验证失败": "Error de verificación",
  "不可以。用户选择使用%s券支付订单时将优先使用%s券抵扣订单金额。券金额高于订单金额的，差额部分不可提现、退款、找零、买卖、兑现或转赠他人；订单金额高于券金额的，差额部分由用户支付。": "Lamentablemente, no. Cuando se usa un cupón de %s, el valor del cupón se restará del monto total del pedido. Si el valor del cupón excede el total del pedido, la diferencia no se puede retirar, reembolsar, recibir como cambio, vender, convertir o transferir a otro usuario. Si el total del pedido supera el valor del cupón, el usuario deberá pagar la diferencia.",
  "不可以，兑换出的优惠券可以根据优惠券的使用规则，抵扣部分订单费用。": "No. El cupón canjeado mediante el código promocional puede utilizarse para descontar parte de la tarifa del pedido de acuerdo con los términos de uso del cupón.",
  "(1) 您要求更换收获地址、您提交的地址错误、联系不上您或无人收货、拒收等导致订单超时送达或无法送达的；": "(1) Si has solicitado un cambio de dirección de entrega, la dirección que has proporcionado es incorrecta, no podemos contactarte, no hay nadie para recibir la entrega o si rechazas la entrega y cualquiera de los casos anteriores resulta en una demora o imposibilidad de realizar la entrega;",
  "立即兑换": "Canjear ahora",
  "用户在进行订单支付时网络信号中断或不稳定，可稍后到网络信号稳定的地方再进行支付；": "Al realizar el pago de un pedido, es posible que la señal de internet del usuario haya disminuido o sea inestable. Intenta ir a un lugar que tenga mejor señal y vuelve a realizar el pago.",
  "服务费+里程费": "Cargo por servicio + tarifa por distancia",
  "什么是%s券的“有效期”？": "¿Cuál es el periodo de vigencia de un cupón de %s?",
  "目前我们支持信用卡，借记卡的线上支付，以及现金和pos机的线下支付。": "Actualmente aceptamos pagos en línea con tarjeta de crédito o débito, además de pagos fuera de línea con efectivo y terminales punto de venta.",
  "OFF": " ",
  "下单时使用%s券，订单取消后，外卖券还会返还吗？": "Si cancelo un pedido en el cual utilicé un cupón de %s, ¿se me devolverá el cupón?",
  "暂无优惠券": "Actualmente no hay cupones",
  "1.点击右上角菜单": "1. Pulsa el menú en la parte superior derecha",
  "联系骑手": "Contactar al socio repartidor",
  "去看看已开通地区": "Echa un vistazo a las áreas disponibles.",
  "在哪里可以兑换促销码？": "¿Dónde puedo canjear mi código promocional?",
  "关闭": "Cerrada",
  "下载": "Descargar",
  "用户通过在线支付、现金支付的方式支付外卖订单，且用户领券时输入的手机号与实际下订单时使用账号登记的手机号为同一号码；": "El usuario debe pagar por sus pedidos en efectivo o de forma electrónica. El número de teléfono ingresado por el usuario cuando canjee el cupón debe ser el mismo que el utilizado para registrar la cuenta con la cual se realizó el pedido.",
  "更多问题": "Más preguntas",
  "pos机支付": "Pagar con máquina terminal punto de venta",
  "随时随地享美食": "Tu comida cuando quieras, donde quieras.",
  "感谢你的支持，有新商家上线会第一时间通知你": "Muchas gracias por tu apoyo, te avisaremos inmediatamente cuando los nuevos restaurantes estén disponibles.",
  "周日": "Domingo",
  "去周边看看": "Echa un vistazo a los lugares cercanos.",
  "请选择有问题的商品": "Seleccionar el artículo",
  "将赔付给您%s张免配送费券": "Obtienes %s cupón para un envío gratis.",
  "保持联系！": "Nos mantenemos en contacto.",
  "处理结果将短信通知您": "Los resultados te llegarán mediante un SMS.",
  "周三": "Miércoles",
  "新用户优惠券的使用要求是什么？": "¿Cuáles son los requisitos para usar un premio de bienvenida?",
  "周一": "Lunes",
  "商家配送": "Entrega directa",
  "订单实际送达时间晚于预计送达时间%s分钟以上，": "Si tu pedido llega %s minutos después del tiempo de entrega estimado,",
  "取消订单": "Cancelar pedido",
  "您可以使用信用卡或储蓄卡通过pos机支付订单，请确保您的卡是可用的。": "Puedes usar una tarjeta de débito o crédito para pagar el pedido en un terminal punto de venta. Asegúrate de que tu tarjeta sea válida.",
  "成功": "Éxito",
  "您对配送服务的评价": "Calificaste esta entrega.",
  "输入兑换码": "Ingresa un código promocional",
  "展开": "Ver más (y) ver menos",
  "评价本次服务": "Califica tu experiencia",
  "兑换失败，请再试一次": "Error. Vuelve a intentarlo.",
  "商家已开始制作美食，拒绝了您的取消订单申请": "Tu solicitud de cancelación fue rechazada por la tienda porque tu pedido ya está siendo preparado.",
  "已收到你的申请": "Solicitud recibida",
  "7、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "7. Anota el tiempo de entrega informado por tu socio repartidor. Si un socio repartidor reclama que entregó un pedido antes de que lo hayas recibido, llama al Soporte DiDi de %s al %s para presentar un reclamo.",
  "整个订单都没收到- 表示骑手没有为您配送订单，您没有收到任何餐品。": "No recibí el pedido completo: el socio repartidor no entregó nada del pedido.",
  "对处理结果不满意？": "¿Insatisfecho?",
  "抱歉，你所在的区域不支持配送": "Disculpa, la zona donde estás por ahora no está disponible para realizar el envío.",
  "2、超时赔付服务由平台为用户提供，用户没有支付任何费用，若订单取消则服务取消。": "2. Te brindamos los cupones sin costo alguno. Sin embargo, si cancelas un pedido, ya no podrás recibir más cupones.",
  "隐藏": "Ocultar",
  "更多详情": "Más detalles",
  "app内可以支持哪些支付方式？": "¿Qué métodos de pago permite la aplicación?",
  "订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": "Si tu pedido llega entre %s y %s minutos después del tiempo de entrega estimado, ",
  "促销码是由系统生成的，可以通过促销码兑换优惠券。": "Los códigos promocionales son generados por el sistema y pueden usarse para canjear cupones de descuento.",
  "有效期至%s": "Válido hasta %s",
  "%s将不定期推出多样的优惠活动，如：新老用户满减优惠券、通过分享获得红包优惠券等，具体活动均将在本平台第一时间推送，或以短信形式联系您，您可随时关注相关活动。": "Ocasionalmente, %s lanzará varias promociones de descuento, por ejemplo, montos de descuento de gastos fijos para usuarios nuevos o antiguos, otorgamiento de cupones por compartir contenido, etc. Recibirás una notificación (por esta plataforma o por SMS) lo antes posible con información sobre eventos específicos para que puedas mantenerte actualizado acerca de las noticias relacionadas con eventos.",
  "配送费计费规则": "Reglas de facturación de la tarifa de entrega",
  "已经收到你的申请": "Solicitud recibida",
  "仅限新用户使用": "Sólo para nuevos usuarios",
  "送您1张%s折扣券；": "Te daremos un cupón del %s de descuento.",
  "退款渠道": "Método de reembolso",
  "配送费": "Tarifa de entrega",
  "联系客服": "Contáctanos",
  "联系客服进行申诉": "Contacta a Soporte DiDi",
  "确认": "Confirmar",
  "4月7号起，滴滴外卖将在大阪开始试运营啦！您可以在滴滴外卖App上找到您喜欢吃的各式当地美食。我们的服务上线大促活动即将开始，快点击下方按钮，不错过我们的优惠活动！": "¡A partir del 7 de abril, Didi Takeaway comenzará su operación de prueba en Osaka! Puedes encontrar todo tipo de alimentos locales que te gusten en la aplicación de DiDi Takeaway. Nuestro evento de promoción de lanzamiento de servicio está por comenzar. ¡Haz clic en el botón a continuación y no te pierdas nuestra oferta especial!",
  "当前区域暂未开通服务": "No hay servicio disponible a tu alrededor en este momento",
  "超时赔付规则说明": "Cupón por entrega demorada",
  "如果用户对使用规则或在使用%s软件和/或相关服务过程中有任何疑问或需要任何帮助，请及时与%s客服联系，": "Si un usuario tiene alguna duda o necesita ayuda en relación con los términos de uso, el uso del software de %s o los servicios relacionados, comunícate con el servicio de Soporte DiDi %s inmediatamente.",
  "支付信息": "Información de pago",
  "平台配送": "Plataforma de entrega",
  "您为什么取消？": "¿Cuál es el motivo de cancelación?",
  "一个外卖订单最多能够使用几张%s券？": "¿Cuántos cupones de %s pueden usarse por pedido?",
  "(3) 其他因您的原因导致骑手超时送达或无法送达的。": "(3) Cualquier otro factor atribuible directamente a ti que ocasione una demora o impida la entrega por el socio repartidor.",
  "小费说明": "Acerca de la propina para los socios repartidores",
  "更多地区入驻中……": "Más áreas que se están uniendo...",
  "配送费收取标准": "Tarifa de entrega estándar de recolección",
  "%s~%s公里之间配送费": "Tarifa de entrega entre %s-%s km",
  "用户的外卖订单符合%s券券面注明的使用条件，如：餐品价格加餐具费满足该外卖优惠券所属品类、满减金额及有效使用时间限制；": "El pedido del usuario debe cumplir los términos de uso especificados en el cupón de %s. Por ejemplo, los requisitos del cupón de %s relacionados con las tarifas del utensilio y el precio del artículo deben cumplir la categoría, el límite de gasto y el periodo de vigencia.",
  "超出%s公里部分收取标准": "Recolección estándar de más de %s km",
  "可能的原因和解决办法如下：": "A continuación, se describen algunas causas y soluciones posibles:",
  "促销码分为可以兑换一次的和可以兑换多次的，实际可兑换次数以促销码活动规则为准。": "Algunos códigos promocionales sólo se pueden canjear una vez, mientras que otros se pueden canjear varias veces. Para averiguar la cantidad de canjes permitidos, consulte las reglas del evento del código promocional.",
  "免费取消订单": "La primera cancelación es sin cargo",
  "可抵扣%s": "Aplicable a %s",
  "骑手没有配送我的订单": "El socio repartidor no entregó mi pedido",
  "仅限%s": "Sólo %s",
  "3、您因超时赔付服务获得的优惠券，将发放至您的%s账户，请在": "3. Los cupones por entregas demoradas se agregarán a tu perfil de %s una vez que se complete el pedido. Puedes ver tus cupones en ",
  "我的优惠": "Mis cupones",
  "您可以点击下方按钮和骑手联系交餐。": "Puedes ponerte en contacto con el socio repartidor por tu cuenta o dejar que nosotros nos encarguemos.",
  "您总是能在点餐之前确认该订单的配送费。": "El costo de entrega siempre se muestra antes de hacer el pedido.",
  "特别提示: ": "Nota especial:",
  "POS": "Terminal punto de venta",
  "骑手已到达": "El socio repartidor llegó.",
  "大阪，出发！": "¡Osaka, bienvenida!",
  "等待商家接单": "En espera de que la tienda acepte.",
  "%s可用": "Aplicable en %s",
  "商家正在入驻中": "Más restaurantes en camino",
  "1、超时赔付服务是%s为您免费提供的服务，延迟送达时间及赔付优惠券金额如下：": "1. Para agradecer tu paciencia al esperar tu pedido demorado y para mejorar tu experiencia al usar la aplicación, te otorgaremos uno o más cupones según el tiempo de la demora:",
  "上报问题": "Informar un problema",
  "您的订单完成已经超过24小时，无法自助申请，如您有问题可联系客服，给您带来不便深表歉意。": "Como tu pedido finalizó hace más de 24 horas, ya no es posible realizar una solicitud de reembolso.",
  "商家拒绝取消申请": "La tienda rechazó tu solicitud de cancelación.",
  "联系商家": "Comunícate con la tienda.",
  "一个用户一天内使用的%s券数量之和不得超过两张，各优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。同一个账号、手机号、支付账号、设备以及其他相同或相似身份信息，均视为同一用户。": "Un usuario puede usar un máximo de dos cupones de %s al día. Consulte los términos detallados de la promoción específica de %s para averiguar si se puede usar cupones específicos en artículos que ya tienen un descuento de la tienda o en descuentos en gastos fijos. Si alguno de los siguientes datos de la cuenta (nombre de la cuenta, número de teléfono, cuenta de pago, dispositivo) y otra información idéntica o similar coincide con la de otra cuenta, se considerará que ambas pertenecen a un usuario.",
  "1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费不退还。 2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。": "1. Para los reembolsos parciales, el monto del reembolso corresponde al monto total de los artículos de tu pedido para los que solicitaste un reembolso, menos cualquier descuento de cupón prorrateado. La tarifa de entrega no se reembolsa. 2. Para los reembolsos totales, se reembolsará el monto total del pago. Si usaste cupones en tu pedido, estos serán devueltos a tu Billetera.",
  "感谢你的支持，区域开通后会第一时间通知你": "Muchas gracias por tu apoyo, te avisaremos inmediatamente cuando lancemos la nueva gama de servicios.",
  "在什么条件下可以使用%s券？": "¿Cuáles son las condiciones de uso de los cupones de %s?",
  "您对配送服务满意吗？": "¿Cómo calificarías esta entrega?",
  "温馨提示": "Recordatorio"
}