module.exports = {
  "FoodC_statement__1_eIic": "(1) Luego de que el repartidor recolecte el paquete, se recomienda que el destinatario esté atento a su teléfono y presente en el lugar de destino para encontrarse con el repartidor a tiempo.",
  "FoodC_invoicing_jCYw_YcPH": "Una vez emitido, se enviará un recibo digital a tu correo electrónico. Por favor, facilita la siguiente información:",
  "FoodC_cancel_Cancellation_Policy_rnAp": "Política de cancelación",
  "FoodC_home_The_user_ElUo": "Vincular una tarjeta o pagar en efectivo.",
  "FoodC_cancel__free_BQFd": "El usuario puede cancelar sin cargos hasta {{free_time}} min después de que el socio repartidor haya aceptado el pedido.",
  "FoodC_invoicing_HPuN_MOyc": "Correo electrónico",
  "FoodC_home_The_entrega_uOPx": "Completar el pago correctamente y usar el cupón de descuento para tu pedido.",
  "FoodC_statement__4_YGAs": "(4) Además de las tarifas asociadas a los pedidos con pago en efectivo, no se debe realizar algún otro pago al repartidor.",
  "FoodC_statement__2_ZtHa": "(2) Al entregarle el paquete al repartidor, se recomienda verificar la identidad del repartidor, ya que el remitente asumirá toda la responsabilidad si el paquete se entrega a alguien que no es el repartidor correspondiente.",
  "FoodC_invoicing_MIEP_sDYJ": "Ingresa el número de identificación fiscal",
  "FoodC_home_DiDi_zojp": "DiDi",
  "FoodC_invoicing_epVj_kTpl": "Nombre del recibo",
  "FoodC_home_Orders_are_EEjN": "Hacer un pedido de {{entrega_name}} en una ciudad o región en la que el servicio esté disponible.",
  "FoodC_cancel_Free_order_WqpG": "El usuario puede cancelar el pedido sin cargo alguno.",
  "FoodC_home_1_The_SWqc": "1. Debes usar los cupones de descuento de {{entrega_name}} conforme a los Términos y Condiciones. Está prohibido usar los cupones de {{entrega_name}} de forma maliciosa para cometer fraude u otros actos ilegales para obtener dinero en efectivo, puntos o regalos. Está prohibida la venta o el intercambio de cupones de {{entrega_name}}, así como su uso para otras actividades lucrativas. Si {{brand_name}} identifica que incumpliste los Términos y Condiciones, {{entrega_name}} podrá rechazar el uso de tus cupones de descuento de {{entrega_name}} y/o tomar medidas como la suspensión temporaria o el bloqueo permanente de tu cuenta o cuenta asociada y de tu capacidad de acceder a los servicios de {{brand_name}}. También nos reservamos el derecho de tomar acciones legales en tu contra por los medios legales correspondientes.",
  "FoodC_statement__3_gBJT": "(3) Una vez que el repartidor haya recolectado el paquete, no se reembolsará la tarifa del pedido si lo cancelas.",
  "FoodC_home_The_following_vTNS": "R: Al usar cupones de descuento de {{entrega_name}} debes cumplir con las siguientes condiciones:",
  "FoodC_statement__2_Rrwp": "(2) Luego de que el repartidor haya recolectado el paquete, tanto el remitente como el destinatario recibirán un código de entrega. Si el destinatario no recibe el código por mensaje de texto, el remitente puede compartir el estado de la entrega y el código desde la página de detalles del pedido o contactar a Soporte DiDi para confirmar el código. Para garantizar una entrega segura del paquete, se recomienda no compartir el código con el repartidor o con terceros hasta que se entregue el paquete.",
  "FoodC_statement_2_How_LmUD": "2. ¿Cómo se usa {{entrega_name}}?",
  "FoodC_invoicing_QueK_zCcn": "Recibo emitido",
  "FoodC_cancel_Courier_has_LQtM": "Pedido aceptado por el socio repartidor",
  "FoodC_home_The_user_vjgt": "Es posible que estés usando una versión desactualizada de la aplicación de {{brand_name}}. Actualiza {{brand_name}} a la versión más reciente para intentarlo de nuevo.",
  "FoodOpD_status_Store_OCbV": "Tienda",
  "FoodC_home_The_user_lsyI": "Respetar nuestros Términos y Condiciones al usar un cupón de descuento de {{entrega_name}} y debemos determinar que tu pedido no sea fraudulento al usarlo.",
  "FoodC_statement__3_PxyO": "(3) Luego de que el repartidor haya recolectado el paquete, si la entrega no puede completarse por un motivo relacionado con el destinatario (ya sea porque el destinatario rechace la recepción del paquete, que no se pueda contactar al destinatario, que la dirección del destinatario no sea correcta, u otros motivos), la responsabilidad del paquete será nuevamente del remitente y deberá pagar las tarifas de servicio que correspondan.",
  "FoodC_statement_Animals_people_IccN": "Animales, personas, armas o explosivos;",
  "FoodC_home_2_brand_vxUa": "2. Los Términos y Condiciones de {{brand_name}} se revisan cada tanto, y se publicarán las actualizaciones en esta página. Debes visitar esta página de forma regular para consultar si hay actualizaciones. Estas reglas son vinculantes para los usuarios.",
  "FoodC_cancel_When_the_PAQq": "Si hay múltiples intentos de cancelación de tu cuenta, no podrás cancelar este pedido directamente, pero puedes contactar a Soporte DiDi para obtener ayuda.",
  "FoodC_statement__4_Nghy": "(4) Si el paquete no se puede entregar porque el repartidor no puede contactar al remitente o al destinatario, porque el lugar de recolección/destino es incorrecto o por algún otro motivo, la tarifa del pedido no se reembolsará si se cancela el pedido. Te recomendamos contactar al repartidor para acordar una hora y un lugar para la devolución o entrega del paquete.",
  "FoodC_invoicing_yJyj_kAmJ": "Se envió el recibo a tu correo electrónico",
  "FoodC_statement_Currency_or_drqX": "Dinero o títulos de crédito;",
  "FoodC_home_What_is_tlHh": "P: ¿Qué son los cupones de descuento de {{entrega_name}}?",
  "FoodC_home_The_user_jRpX": "Tu pedido debe cumplir con las condiciones para el cupón de descuento.",
  "FoodC_statement_Jewelry_vtUc": "Joyas;",
  "FoodC_statement_Psychotropic_drugs_XUfp": "Drogas psicoactivas o narcóticos;",
  "FoodC_home_What_is_NZVx": "P: ¿Cuál es la vigencia de un cupón de {{entrega_name}}?",
  "FoodC_statement_Weight_The_kJIL": "Peso: el peso máximo permitido para un paquete es de {{weight_limit}} kg",
  "FoodC_statement_4_Rules_XuZc": "4. Instrucciones para la recolección de paquetes",
  "FoodC_home_How_do_xoCZ": "P: ¿Cómo obtengo cupones de descuento de {{entrega_name}}?",
  "FoodC_cancel_Reason_for_vfFe": "¿Cuál es el motivo de cancelación?",
  "FoodC_home1_DiDi_Delivery_jard": "DiDi Entrega",
  "FoodC_home_Can_you_qvdx": "P: ¿Puede usarse un cupón de descuento de {{entrega_name}} de una cuenta para pagar un pedido realizado desde otra?",
  "FoodC_home_A_entrega_sgzW": "R: Se puede usar un solo cupón de descuento {{entrega_name}} por cada pedido de {{entrega_name}}.",
  "FoodC_home_Can_the_Jjzi": "P: ¿Puedo obtener un recibo del total que cubre el cupón de descuento de {{entrega_name}} de la tarifa de entrega?",
  "FoodC_home__entrega_HFmI": "R: {{entrega_name}} lanza promociones cada cierto tiempo, como descuentos por llegar a un monto mínimo de gastos para usuarios nuevos y existentes, y cupones de recompensa por compartir cierto contenido. Te notificaremos las promociones específicas lo antes posible a través de la aplicación o por mensaje de texto.",
  "FoodC_cancel__free_pPaN": "El usuario puede cancelar pagando una tarifa por cancelación de {{cancellation_fee}} hasta {{free_time}} min después de que el socio repartidor haya aceptado el pedido.",
  "FoodC_cancel_Order_accepted_DmOH": "Esperando a que el socio repartidor acepte",
  "FoodC_cancel_Wait_uNLX": "Regresar",
  "FoodC_statement_5_Rules_eBJs": "5. Instrucciones para la confirmación de entrega",
  "FoodC_home_What_are_DhsJ": "P: ¿En qué circunstancias es posible que un cupón de descuento de {{entrega_name}} ya no pueda usarse y cómo se puede solucionar?",
  "FoodC_home_Because_the_geRF": "R: Debido a que no pagas la parte que cubre el cupón de descuento de {{entrega_name}} del total, no puedes obtener un recibo por eso.",
  "FoodC_statement_Volume_The_wFKH": "Dimensiones: el largo, el ancho y la altura de un paquete no pueden ser mayores a {{length_limit}} cm x {{width_limit}} cm x {{height_limit}} cm",
  "FoodC_home__entrega_VWeG": "R: Los cupones de descuento de {{entrega_name}} son recibos electrónicos emitidos por {{entrega_name}} con el fin de obtener un descuento en el monto de los pedidos solicitados para {{entrega_name}}.",
  "FoodC_statement_Drugs_controlled_drMh": "Drogas controladas o restringidas;",
  "FoodOpC_Notifications_Full_service_HBkd": "Todas las ciudades",
  "FoodC_home_Can_entrega_mdgU": "P: ¿Se puede recibir cambio al usar cupones de descuento  {{entrega_name}} o canjearlos por dinero?",
  "FoodC_home_Users_who_Xjqh": "Revisa con cuidado que el pedido cumpla con las condiciones del cupón de descuento de {{entrega_name}}.",
  "FoodC_statement_1_What_SFNM": "1. ¿Qué es {{entrega_name}}?",
  "FoodC_statement_Laboratory_samples_AmoJ": "Muestras de laboratorio;",
  "FoodC_home_A_new_TGvz": "R: Para utilizar un descuento de usuario nuevo, un usuario debe estar usando {{entrega_name}} por primera vez y completar un pedido correctamente.",
  "Groceries_payment_AeNZ_HMSX": "Continuar",
  "FoodC_cancel_Special_circumstances_aOpL": "Otros casos",
  "FoodC_invoicing_DqEG_YcKx": "Ingresa el correo electrónico",
  "FoodC_home_Terms_of_ZXoU": "Términos y Condiciones de uso y Aviso de privacidad",
  "FoodC_home_How_many_mego": "P: ¿Cuántos cupones de descuento {{entrega_name}} puedo usar por cada pedido de {{entrega_name}}?",
  "FoodC_home_How_many_hRpj": "P: ¿Cuántos cupones de {{entrega_name}} puede usar un usuario por día?",
  "Gattaran_coupon_Store_Coupon_xLBA": "Cupón de la tienda",
  "FoodC_statement__1_wZhz": "(1) Si el pedido no es aceptado por un repartidor luego del tiempo de espera especificado, se puede cancelar el pedido sin cargo alguno.",
  "FoodC_cancel_Courier_picked_obrP": "Pedido recolectado por el socio repartidor",
  "Entrega_name_99_Flash_maAd": "99Entrega",
  "FoodC_cancel_Cancel_the_MyBx": "Estado",
  "FoodC_statement__5_eCLd": "(5) Si el pedido se cancela debido a un accidente, una falla del vehículo o por algún otro motivo que tenga que ver con el repartidor dirigiéndose a recolectar el paquete, se asignará otro repartidor automáticamente para que realice la recolección.",
  "FoodC_home_The_sum_zzUZ": "R: Cada usuario puede usar hasta tres cupones de descuento {{entrega_name}} por día. Un usuario se identifica por la cuenta, el número de teléfono, el método de pago, el dispositivo y otros identificadores similares, por lo que no se pueden usar los mismos más de una vez.",
  "FoodC_invoicing_xzCZ_wjMI": "Información no válida. Revísala y vuelve a ingresarla.",
  "FoodC_home_Special_note_LSrQ": "Importante:",
  "FoodC_home__Validity_FDCw": "R: Los cupones de descuento de {{entrega_name}} tienen una vigencia específica y sólo se pueden utilizar durante ese periodo. Una vez finalizado ese plazo, automáticamente dejará de ser válido.",
  "FoodC_home_The_user_GKZd": "Pagar el pedido a través de la aplicación o en efectivo. El número de teléfono que ingresaste al usar el cupón en un pedido debe coincidir con el número vinculado a tu cuenta.",
  "FoodC_coupon_AcjA_mqeV": "Cupón del 100 % de descuento en la tarifa de entrega",
  "FoodC_statement__2_mmNa": "(2) El remitente debe asegurarse de que el paquete cumpla con los siguientes requisitos para su entrega:",
  "FoodC_invoicing_jOXr_YSAW": "En proceso de emisión",
  "FoodC_home_Possible_causes_wopU": "R: Estos son algunos de los problemas comunes y sus soluciones:",
  "FoodC_home_If_the_SPtR": "R: Si usas un cupón de descuento de {{entrega_name}} y no completas el pago para el momento en el que se canceló el pedido, te devolveremos el cupón a tu cuenta luego de cancelado el pedido. Si el pago ya se completó antes de que se haya cancelado el pedido, no se podrá devolver el cupón a tu cuenta, y el pago del pedido se reembolsará al método de pago original dentro de los siguientes treinta días.",
  "FoodC_statement_If_you_Jbkm": "Si un paquete está incompleto, se perdió, está dañado o se entregó el paquete incorrecto, contacta a Soporte DiDi lo antes posible.",
  "FoodC_statement_3_Flash_IEYW": "3. Política de cancelación",
  "FoodC_home_No_entrega_GnGI": "R: Lamentablemente, no. Los cupones de descuento de {{entrega_name}} de una cuenta sólo pueden usarse para pagar solicitudes de servicio de {{entrega_name}} de esa cuenta.",
  "FoodC_invoicing_YDSi_TMLw": "Número de identificación fiscal",
  "FoodC_cancel_Users_initiate_suVB": "El usuario puede enviar una solicitud de cancelación, la cual no puede eliminarse una vez enviada. La cancelación se completará una vez que el repartidor haya devuelto el paquete. El pago en efectivo de este pedido no se reembolsará.",
  "FoodC_statement_pornography_WPQI": "Material pornográfico;",
  "FoodC_statement_Value_Items_svYm": "Valor declarado: el valor del paquete no puede ser mayor a {{value_limit}}",
  "FoodC_invoicing_AxaA_RodV": "Confirmar",
  "FoodC_home_3_If_BGBo": "3. Si tienes alguna pregunta relacionada con los Términos y Condiciones o con la aplicación y los servicios de {{brand_name}}, contacta a nuestro equipo de Soporte DiDi.",
  "FoodC_statement__4_bDLh": "(4) El destinatario debe abrir el paquete inmediatamente después de realizada la entrega para verificar el contenido.",
  "FoodC_invoicing_okhb_GoXI": "Entendido",
  "FoodC_invoicing_SvTe_iYZQ": "Ingresa un nombre para el recibo",
  "FoodC_home_No_When_xNrI": "R: Lamentablemente, no. Al usar un cupón de descuento de {{entrega_name}}, el valor del cupón de {{entrega_name}} se restará del monto total del pedido. Si el valor del cupón excede el total del pedido, la diferencia no se puede retirar, reembolsar, recibir como cambio, vender, convertir o transferir a otro usuario. Si el total del pedido supera el valor del cupón, el usuario deberá pagar la diferencia.",
  "FoodC_statement__2_Gymp": "(2) Existe un periodo para poder cancelar un pedido sin cargo alguno, luego de que un repartidor lo acepte y antes de que recolecte el paquete. Una vez pasado ese periodo, se aplicará una tarifa por cancelación si cancelas.",
  "FoodC_statement__1_hBNr": "(1) Luego de que el repartidor acepte un pedido, se recomienda que el remitente deba estar atento a su teléfono y estar presente en el lugar de recolección para encontrarse con el repartidor a tiempo.",
  "FoodC_statement__1_ZXJA": "(1) Para enviar un paquete, el remitente simplemente debe ingresar en la app la dirección de recolección y de destino, su información de contacto y la del destinatario y los detalles sobre el paquete.",
  "FoodC_invoicing_ubrt_obAj": "Recibo de la tarifa de servicio",
  "FoodC_statement_6_Lost_VmQE": "6. Seguimiento de paquetes perdidos o dañados",
  "FoodC_home_When_the_qNRR": "Si la conexión de red es inestable, es posible que no se procese el pago. Intenta moverte y buscar un lugar con mejor señal para intentarlo de nuevo.",
  "FoodC_home_What_are_TUik": "P: ¿Cuáles son los requisitos para usar un cupón de descuento de usuario nuevo?",
  "FoodC_statement_Items_prohibited_vdcx": "Cualquier otro artículo prohibido por las leyes y regulaciones correspondientes.",
  "FoodC_home_Under_what_udiG": "P: ¿Cuáles son las condiciones para usar cupones de descuento de {{entrega_name}}?",
  "FoodC_home_The_user_DZGn": "Tener un cupón de descuento de {{entrega_name}} que puedas usar en la sección de billetera de la aplicación.",
  "FoodC_statement__6_cuGI": "(6) Si luego de la recolección, el repartidor nota que el paquete a entregar no cumple con los requisitos o con la política de contenidos de paquetes, puede cancelar el pedido y no entregar el paquete.",
  "FoodC_statement_Currently_DiDi_jUEl": "{{entrega_name}} es un servicio de recolección y entrega de bienes que te permite realizar un pedido a través de la app para que un repartidor recolecte un paquete y lo entregue a una ubicación específica o a un destinatario cercano. Luego de que un repartidor acepte el pedido, recolectará el paquete en la dirección indicada y lo entregará en el destino o al destinatario que especificaste.",
  "FoodC_statement__3_JHtK": "(3) El solicitante/remitente, es responsable de cubrir los costos asociados al pedido. Para los pedidos con pago con tarjeta, los pagos deben completarse en el momento en que se realiza el pedido. Para los pedidos con pago en efectivo, el pago debe entregarse al repartidor cuando recolecte el paquete. El destinatario no es responsable de pagar cargos o tarifas del pedido.",
  "FoodC_cancel_When_an_XxLF": "Para pagos a través de la aplicación, la tarifa por cancelación no será mayor que el costo de la tarifa de entrega.",
  "FoodC_statement_Dangerous_materials_tLOa": "Materiales o desechos peligrosos (líquidos o sólidos inflamables; gases comprimidos almacenados en forma licuada, no licuada o disuelta; sustancias tóxicas, infecciosas, radiactivas o corrosivas);",
  "FoodC_statement_Type_You_sJki": "Contenido del paquete: no se puede usar {{entrega_name}} para entregar productos prohibidos, entre los cuales están los siguientes:",
  "FoodC_statement__5_ZbOp": "(5) Si el paquete se perdió, está dañado, incompleto o se entregó el paquete incorrecto, se recomienda contactar primero al repartidor sobre la situación. Si no se puede llegar a un acuerdo, contacta a Soporte DiDi para obtener ayuda.",
  "FoodC_home_When_placing_UpoJ": "P: Si cancelo un pedido en el que usé un cupón de descuento de {{entrega_name}}, ¿me devolverán el cupón de {{entrega_name}}?",
  "中查看": " "
}