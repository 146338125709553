module.exports = {
  "FoodC_statement__1_eIic": "(1) Depois que o item for coletado pelo entregador parceiro, o destinatário deve estar disponível para atender o telefone e estar presente no local de entrega para receber o entregador;",
  "FoodC_cancel_Cancellation_Policy_rnAp": "Política de cancelamento",
  "FoodC_home_The_user_ElUo": "Você deve vincular um cartão bancário ou pagar em dinheiro;",
  "FoodC_demand__Do_CKdn": "* Continuar fazendo a compra com todos os itens, exceto os que estão em falta.",
  "FoodC_demand_Courier_Purchasing_eTAr": "Compra feita pelo entregador parceiro",
  "FoodC_cancel__free_BQFd": "O cliente pode cancelar gratuitamente até {{free_time}} minutos após o entregador parceiro aceitar o pedido",
  "FoodC_store_Store_Coupon_szoR": "Cupons da loja",
  "FoodC_home_The_entrega_uOPx": "Você deve concluir o pagamento usando um cupom para o seu pedido.",
  "FoodC_statement__4_YGAs": "(4) Além das taxas associadas aos pedidos pagos em dinheiro, você não precisa pagar nenhuma outra taxa ao entregador parceiro.",
  "FoodC_sales_Confirmation_mTGd": "Entendi",
  "FoodC_2__sybX": "Não há mais cupons restantes hoje. Tente novamente na próxima vez",
  "FoodC_statement__2_ZtHa": "(2) Ao entregar o item para o entregador parceiro, o remetente deve conferir o nome do entregador. O remetente é responsável caso o item seja entregue a alguém que não seja o entregador especificado;",
  "FoodC_demand__1_jqYB": "(1) Basta inserir o endereço de entrega, o nome do destinatário e os itens que deseja e, em seguida, finalizar a compra. Um entregador parceiro comprará os itens do estabelecimento escolhido ou de outro estabelecimento nas proximidades:",
  "FoodC_education_1_Customers_Gpbb": "1. O(a) entregador(a) parceiro(a) entregará seu pedido com base na opção selecionada:",
  "FoodC_title_Upload_Image_VSGW": "Instruções para fotos",
  "FoodC_education__1_mJTG": "(1) Se o estabelecimento já tiver confirmado e aceitado o pedido, você poderá usar o recurso “Cancelar pedido” para entrar em contato com o estabelecimento e solicitar o cancelamento. Se a loja não aceitar a solicitação porque já preparou o pedido ou por outro motivo razoável, você não poderá cancelar o pedido gratuitamente. Caso insista em cancelar ou se recuse a aceitar o pedido, no todo ou em parte, você deverá pagar todas as taxas pertinentes ao pedido.",
  "FoodC_order__2_UYRU": "(2) O total do seu pedido será mostrado no check-out. Se o valor pago pelo entregador parceiro for significativamente menor do que o total do pedido, você receberá um reembolso parcial automaticamente. Se o preço real for maior do que o valor pago, não haverá cobrança adicional",
  "FoodC_sales_Confirmation_jtNg": "OK",
  "FoodC_feedback_I_received_UNiy": "Recebi o pedido de outra pessoa",
  "FoodC_home_DiDi_zojp": "99",
  "FoodC_sales_3_Display_GYtM": "3. Você optou por pagar este pedido em dinheiro. Se a solicitação de reembolso for aprovada, o valor será adicionado à sua Carteira.",
  "FoodC_demand__2_ALrB": "(2) O valor total do seu pedido será mostrado na finalização da compra. Se um valor diferente for cobrado do entregador parceiro, você não terá nenhuma cobrança a mais (ou haverá o reembolso da diferença).",
  "FoodC_home_Orders_are_EEjN": "Você deve fazer um pedido {{entrega_name}} em uma cidade e/ou região onde o serviço esteja disponível;",
  "FoodC_cancel_Free_order_WqpG": "O cliente pode cancelar o pedido gratuitamente",
  "FoodC_education_3_If_wduh": "3. Se um conflito relacionado à entrega não for causado pelo(a) entregador(a) parceiro(a), como um problema do produto ou o mau tempo, assumiremos a responsabilidade",
  "FoodC_home_1_The_SWqc": "1. Você deve usar os cupons {{entrega_name}} respeitando todos os fins legítimos e legais. Observe que é proibido usar os cupons {{entrega_name}} de forma maliciosa para cometer fraudes e outros atos ilegais. Também não é permitido vender ou trocar cupons {{entrega_name}} ou usar os cupons para outras atividades lucrativas. Caso a {{brand_name}} verifique violações de leis, dos Termos de Uso e das regras mencionadas acima, a {{entrega_name}} poderá recusar o uso de cupons {{entrega_name}} e/ou tomar as medidas necessárias, como suspender temporária ou permanentemente seu perfil, quaisquer outros perfis associados, bem como o acesso ao aplicativo e aos serviços {{brand_name}}. Também nos reservamos o direito de tomar as devidas medidas legais pelos meios legais cabíveis.",
  "FoodC_seals_Outdoor_packaging_MGso": "A embalagem do item estava intacta?",
  "FoodC_demand_2_How_DjaQ": "2. Como funciona o Compra feita pelo entregador parceiro?",
  "FoodC_c_Please_try_cHSE": "Tente novamente mais tarde",
  "FoodC_statement__3_gBJT": "(3) Depois que o item for coletado pelo entregador parceiro, a taxa do pedido não será reembolsada se você cancelar;",
  "FoodC_home_The_following_vTNS": "R: Ao usar cupons {{entrega_name}}, você deve atender às seguintes condições:",
  "FoodC_RCOUPON_Coupon_reduction_PqSi": "Cupom de restaurantes",
  "FoodC_statement__2_Rrwp": "(2) Depois que o item for coletado pelo entregador parceiro, tanto o remetente quanto o destinatário receberão um código de entrega; se o destinatário não receber o código de entrega via SMS, o remetente poderá compartilhar o status e o código de entrega com o destinatário na página “Detalhes do pedido” ou entrar em contato com o Atendimento ao Cliente para confirmar o código de entrega. Para a segurança da sua entrega, não compartilhe o código de entrega com o entregador parceiro ou com qualquer outra pessoa até que o item seja recebido pelo destinatário.",
  "FoodC_demand_3_What_zsYM": "3. E se um item que eu quiser estiver em falta?",
  "FoodC_education_In_order_SRIb": "Esta política de cancelamento foi elaborada para ajudar todos os usuários a terem uma experiência justa e confiável ao usarem o aplicativo {{brand_name}}.",
  "FoodC_feedback_2_The_ntBT": "2. Para reembolso total, seu pagamento será reembolsado integralmente. Caso tenha utilizado algum cupom em seu pedido, ele será devolvido à sua Carteira.",
  "FoodC_statement_2_How_LmUD": "2. Como eu uso {{entrega_name}}?",
  "FoodC_sales_If_the_TsPm": "Para nos ajudar a verificar o problema com o estabelecimento e o entregador parceiro, informe se o item estava em uma embalagem lacrável, ou se foi lacrado de outra forma (por exemplo, grampeado).",
  "FoodC_cancel_Courier_has_LQtM": "Pedido aceito pelo entregador parceiro",
  "FoodC_education__2_uVUK": "(2) O estabelecimento ao qual você fez o pedido está fechado, um item está esgotado, ocorreu um acidente ou o pedido foi cancelado pelo estabelecimento ou pelo(a) entregador(a) parceiro(a) por outro motivo. Nesse caso, você receberá um reembolso total.",
  "soda-d-i18n---没有更多了": "Sem mais resultados",
  "FoodC_demand__You_yjru": "* Pague no aplicativo: você pode efetuar o pagamento no aplicativo ao fazer um pedido e o entregador parceiro comprará os itens solicitados e os entregará diretamente na sua casa.",
  "FoodC_home_The_user_vjgt": "Você pode estar usando uma versão desatualizada do aplicativo {{brand_name}}. Atualize o {{brand_name}} para a versão mais recente e tente novamente.",
  "FoodOpD_status_Store_OCbV": "Estabelecimento",
  "FoodC_home_The_user_lsyI": "Você deve respeitar as regras dos Termos de Uso ao usar um cupom {{entrega_name}}, inclusive que o seu pedido não seja considerado fraudulento ao usar o cupom.",
  "FoodD_one_Please_try_VyNK": "Tente novamente mais tarde",
  "FoodC_demand__If_PNsp": "* Cancelar o pedido totalmente se o item estiver em falta.",
  "FoodC_statement__3_PxyO": "(3) Depois que o item for coletado pelo entregador parceiro, se a entrega não puder ser concluída por algum motivo relacionado ao destinatário (incluindo, entre outros, destinatário se recusar a receber o item, destinatário não poder ser contatado ou o endereço incorreto), o item será devolvido ao remetente e quaisquer taxas de serviços decorrentes disso serão custeadas pelo remetente.",
  "FoodC_statement_Animals_people_IccN": "Animais, pessoas, armas ou explosivos;",
  "FoodC_home_2_brand_vxUa": "2. Os Termos de Uso da {{brand_name}} podem ser atualizados e suas atualizações podem ser avisadas pelos meios de comunicação oficiais. Lembre-se de acessar regularmente os Termos de Uso e esta página para verificar se há atualizações.",
  "FoodC_saved_ZHnz_OjnQ": "Preço original",
  "FoodC_Translation__brand_LAYp": "Cupons {{brand_name}}",
  "FoodC_cancel_When_the_PAQq": "Se forem detectadas várias tentativas de cancelamento envolvendo seu perfil, não será possível cancelar o pedido diretamente, mas você poderá entrar em contato com o Atendimento ao Cliente para obter ajuda.",
  "FoodC_demand__4_HJNa": "(4) Medidas podem ser tomadas em caso de quaisquer violações.",
  "sailing.c-api-intl.[提单页]_[平台券]": "Cupons da 99Food",
  "FoodC_demand__3_tQmA": "(3) A definição real dos preços varia dependendo da área.",
  "FoodC_statement__4_Nghy": "(4) Se o item não puder ser entregue porque o entregador parceiro não conseguiu entrar em contato com o remetente/destinatário, porque o local de coleta/entrega está incorreto ou outro motivo, a taxa do pedido não será reembolsada caso você decida cancelar. Neste caso, você terá que entrar em contato com o entregador parceiro para combinar um novo horário e local para devolver ou entregar o item;",
  "FoodC_education_3_Customers_Zoec": "3. Mantenha seu telefone acessível durante a entrega para garantir que o(a) entregador(a) parceiro(a) consiga entrar em contato se necessário.",
  "FoodC_update_The_expected_ZzFg": "O horário estimado da entrega tem como base o último horário estimado de entrega.",
  "FoodC_statement_Currency_or_drqX": "Dinheiro em espécie ou títulos de crédito;",
  "FoodC_demand__In_XsxW": "* Se houver uma contestação envolvendo um item em falta, a 99Food usará esta política para determinar o responsável.",
  "FoodC_education__1_vwgm": "(1) Entregar na porta",
  "FoodC_page_Contact_customer_rJbM": "Entre em contato com o Atendimento ao Cliente",
  "FoodC_home_What_is_tlHh": "P: O que são cupons {{entrega_name}}?",
  "FoodC_home_The_user_jRpX": "Seu pedido deve atender às condições de uso do cupom.",
  "FoodC_education__1_qvVC": "(1) Você não retira seu pedido dentro do prazo",
  "FoodC_statement_Jewelry_vtUc": "Joias;",
  "FoodC_saved_AlLL_CHtd": "Preço pago",
  "FoodC_statement_Psychotropic_drugs_XUfp": "Drogas psicoativas ou narcóticos;",
  "FoodC_home_What_is_NZVx": "P: Existe um “período de validade” para um cupom {{entrega_name}}?",
  "FoodC_statement_Weight_The_kJIL": "Peso: o peso máximo permitido de um item é de {{weight_limit}} kg",
  "FoodC_education_Meal_picking_ziun": "Regras de entrega",
  "FoodC_saved_jKhv_Cwtv": "Pacotes de cupons",
  "FoodC_perception__XmZq": "Voltar para o início",
  "FoodC_demand__1_EzSJ": "(1) Você pode cancelar seu pedido gratuitamente caso o entregador parceiro ainda não tenha comprado nenhum item.",
  "FoodC_statement_4_Rules_XuZc": "4. Regras para coleta de itens",
  "FoodC_home_How_do_xoCZ": "P: Como consigo um cupom {{entrega_name}}?",
  "FoodC_education_5_Cash_zBuY": "5. Ao fazer um pedido pago em dinheiro, verifique se você tem a quantia necessária para fazer o pagamento.",
  "FoodC_cancel_Reason_for_vfFe": "Motivo do cancelamento",
  "FoodC_home1_DiDi_Delivery_jard": "DiDi Entrega",
  "FoodC_home_Can_you_qvdx": "P: Os cupons {{entrega_name}} de um perfil podem ser usados para pagar um pedido em outro perfil?",
  "FoodC_home_A_entrega_sgzW": "R: Apenas um cupom {{entrega_name}} pode ser usado por pedido no {{entrega_name}}.",
  "FoodC_home_Can_the_Jjzi": "P: Posso receber um recibo oficial do total da taxa de entrega compensado pelo cupom {{entrega_name}}?",
  "sailing.c-api-intl.[提单页]_[商家券]": "Cupons da loja",
  "FoodC_home__entrega_HFmI": "R: O {{entrega_name}} poderá lançar ofertas de tempos em tempos, como descontos para usuários e cupons de recompensa. Você receberá notificações sobre ofertas específicas no aplicativo ou via SMS.",
  "FoodC_feedback_Order_issues_wWOH": "Problemas no pedido",
  "FoodC_demand_4_Courier_ReDA": "4. Política de cancelamento o Compra feita pelo entregador parceiro",
  "FoodC_saved_UjVo_BAJJ": "Não há mais dados",
  "FoodC_cancel__free_pPaN": "Caso tenham se passado mais de {{free_time}} minutos após a aceitação do entregador parceiro, o cliente ainda poderá cancelar o pedido, e pagará uma taxa de cancelamento de {{cancellation_fee}}.",
  "FoodC_cancel_Order_accepted_DmOH": "Aguardando confirmação do entregador parceiro",
  "FoodC_cancel_Wait_uNLX": "Agora não",
  "FoodC_feedback_3_When_OcDb": "3. Você optou por pagar este pedido em dinheiro. Se a solicitação de reembolso for aprovada, o valor será devolvido como um cupom adicionado à sua Carteira.",
  "FoodC_statement_5_Rules_eBJs": "5. Regras para confirmação de entrega",
  "FoodC_home_What_are_DhsJ": "P: Em que circunstâncias um cupom {{entrega_name}} pode se tornar inutilizável e como é possível resolver o problema?",
  "FoodC_home_Because_the_geRF": "R: Como você não está efetuando o pagamento total de um pedido compensado por um cupom {{entrega_name}}, não podemos fornecer um recibo oficial da parte coberta pelo cupom {{entrega_name}}, mas somente o recibo do valor efetivamente pago por você.",
  "FoodC_description_Partial_meal_JWNU": "Parte do pedido estava faltando:",
  "FoodC_statement_Volume_The_wFKH": "Dimensões: comprimento, largura e altura de um item não pode exceder {{length_limit}} cm x {{width_limit}} cm x {{height_limit}} cm",
  "FoodC_Translation__unk_axrk": "Cupom {{brand_name}} resgatado!",
  "FoodC_demand__3_hmpb": "(3) Política de reembolso para itens em falta:",
  "FoodC_home__entrega_VWeG": "R: Os cupons {{entrega_name}} são vouchers eletrônicos emitidos pelo {{entrega_name}} para oferecer descontos aos usuários no valor dos pedidos feitos no {{entrega_name}}.",
  "FoodC_statement_Drugs_controlled_drMh": "Medicamentos controlados ou restritos;",
  "FoodOpC_Notifications_Full_service_HBkd": "Todas as cidades",
  "FoodC_home_Can_entrega_mdgU": "P: Posso receber troco ao usar os cupons {{entrega_name}} ou trocá-los por dinheiro?",
  "FoodC_home_Users_who_Xjqh": "Verifique cuidadosamente se o seu pedido cumpre todas as condições do cupom {{entrega_name}};",
  "FoodC_statement_1_What_SFNM": "1. O que é o {{entrega_name}}?",
  "FoodC_page__iyoe": "Mostrar menos",
  "FoodC_education_1_If_iWDm": "1. Se você não conseguir cumprir os requisitos acima, poderá ser responsabilizado no caso de um problema relacionado à entrega. Isso inclui, entre outras, as situações em que:",
  "FoodC_statement_Laboratory_samples_AmoJ": "Amostras de laboratório;",
  "FoodC_home_A_new_TGvz": "R: Para se qualificar para um desconto de novo cliente, o cliente deve estar usando o {{entrega_name}} pela primeira vez e concluir um pedido com sucesso.",
  "FoodC_saved_FWIR_qHYd": "A {{app_name}} não cobra taxas de serviço",
  "FoodC_demand_5_there_jISx": "5. Tenho uma dúvida sobre as informações do estabelecimento. O que devo fazer?",
  "FoodC_page__tlAr": "Ofertas do estabelecimento",
  "FoodC_issues_Contact_customer_Anvy": "Entrar em contato com o Atendimento ao Cliente",
  "FoodC_cancel_Special_circumstances_aOpL": "Circunstâncias especiais",
  "FoodC_home_Terms_of_ZXoU": "Termos de Uso",
  "FoodC_home_How_many_mego": "P: Quantos cupons {{entrega_name}} posso usar por pedido no {{entrega_name}}?",
  "FoodC_sales_Remember_my_kvqE": "Lembrar desta escolha e usá-la como padrão para reembolso no futuro.",
  "FoodC_home_How_many_hRpj": "P: Quantos cupons da {{entrega_name}} o cliente pode usar por dia?",
  "Gattaran_coupon_Store_Coupon_xLBA": "Cupom do estabelecimento",
  "FoodC_education_Principles_of_RKEk": "Regras para resolver conflitos relacionados à entrega",
  "FoodD_one_Try_again_qZka": "Tentar novamente",
  "FoodD_one_Service_not_eFvs": "Serviço sem conexão",
  "FoodC_feedback_It_was_ZrMP": "O pedido inteiro estava errado",
  "FoodC_statement__1_wZhz": "(1) Se o seu pedido não for aceito pelo entregador parceiro após o tempo de espera especificado, você poderá cancelar o pedido gratuitamente;",
  "FoodC_cancel_Courier_picked_obrP": "Pedido coletado pelo entregador parceiro",
  "FoodC_name_DiDi_Flash_TAPX": "99Entrega",
  "Entrega_name_99_Flash_maAd": "99Entrega",
  "FoodC_education_Basic_requirements_hIbv": "Regras básicas de entrega para clientes",
  "FoodC_cancel_Cancel_the_MyBx": "Status",
  "FoodC_demand__2_eSAk": "(2) Se um item estiver em falta, você pode:",
  "FoodC_description_Indicates_that_MWBQ": "O entregador parceiro não entregou o pedido, logo, você não recebeu nenhum item.",
  "FoodC_statement__5_eCLd": "(5) Se o seu pedido for cancelado devido a um acidente, problema no veículo ou outro motivo envolvendo o entregador parceiro no caminho para coletar o item, outro entregador parceiro será designado automaticamente para coletar o item para você;",
  "FoodC_home_The_sum_zzUZ": "R: Cada cliente pode usar até três cupons {{entrega_name}} por dia por perfil. Usar o mesmo perfil, número de telefone, forma de pagamento, dispositivo ou qualquer outro identificador semelhante é considerado uso no mesmo perfil.",
  "FoodC_description_Indicates_that_fnET": "O entregador parceiro entregou o pedido, porém um ou mais itens não foram recebidos.",
  "FoodC_feedback_1_When_buWL": "1. Para reembolso parcial, o valor será o total dos itens solicitados menos os descontos rateados. A taxa de entrega e a gorjeta não são reembolsáveis.",
  "FoodC_demand__If_qODk": "* Se optar por comprar os itens restantes disponíveis, você receberá um reembolso automático pelos itens em falta caso tenha pago no aplicativo. Para pedidos pagos em dinheiro, você não precisará pagar pelos itens em falta.",
  "FoodC_demand_If_you_SpKh": "Se você tiver alguma dúvida sobre as informações de um estabelecimento, entre em contato com o Atendimento ao Cliente: {{Customer_Service_Contact_Number}}",
  "FoodC_c_Unable_to_cPrS": "Serviço indisponível",
  "FoodC_home_Special_note_LSrQ": "Importante:",
  "FoodC_home__Validity_FDCw": "R: Os cupons {{entrega_name}} têm um “período de validade” especificado. Eles podem ser usados apenas durante o período especificado; após essa data, eles são automaticamente invalidados.",
  "FoodC_sales_Supplementary_information_ihyk": "Detalhes adicionais",
  "FoodC_home_The_user_GKZd": "Você deve pagar o pedido através do aplicativo ou em dinheiro. O número de celular inserido ao usar o cupom no pedido deve corresponder ao número vinculado ao seu perfil.",
  "FoodC_demand__3_cTVC": "(3) Você receberá um reembolso se a loja onde fez o pedido estiver fechada, um item estiver esgotado, tiver ocorrido um acidente ou o pedido foi cancelado pela loja ou pelo parceiro por outro motivo.",
  "FoodC_saved_Euxs_oQHV": "Desde 01/01/2020, você economizou um total de",
  "FoodC_page_Sorry_the_tNDc": "Não foi possível processar sua solicitação devido a atividades incomuns. Para assistência, entre em contato com o Atendimento ao Cliente.",
  "FoodC_education_In_the_nIgD": "Você será responsabilizado por um cancelamento nas seguintes situações:",
  "FoodC_demand__Let_qvly": "* Pedir para o entregador parceiro entrar em contato com você e selecionar uma das opções acima.",
  "FoodC_statement__2_mmNa": "(2) O remetente deve se certificar de que o item atende aos seguintes requisitos para que seja adequado para entrega:",
  "FoodC_education__2_KHhl": "(2) Se você não chegar ao local da entrega dentro do prazo especificado, informar incorretamente o endereço de entrega ou o número de telefone, recusar uma ligação do(a) entregador(a) parceiro(a) ou, ainda, recusar a entrega, no todo ou em parte, sem um motivo válido, será necessário pagar o pedido.",
  "FoodC_education_If_you_RTIV": "Se você pagar por um pedido e depois tiver que cancelar, serão aplicadas as regras a seguir:",
  "FoodC_education_2_Receiving_gFZl": "2. Verifique se o endereço de entrega e a forma de contato fornecidos estão corretos.",
  "FoodC_education__3_TDsa": "(3) Você se recusa a aceitar o pedido, no todo ou em parte, sem uma justificativa",
  "FoodC_education_2_If_SoSk": "2. Se um conflito relacionado à entrega ocorrer porque o(a) entregador(a) parceiro(a) não seguiu nossas regras, poderemos responsabilizá-lo(a)",
  "FoodC_education__unk_aOCD": "{{delivery_type}} Regras do aplicativo",
  "FoodC_demand_Purchasing_services_jshb": "Com essa opção, o entregador parceiro pode comprar vários itens para você - como pizza, hambúrgueres, chá ou café - em lojas próximas (a disponibilidade dos itens pode variar) e entregá-los em sua casa.",
  "Global_Driver_Multiplier_new_interaction_Current_activities_vSRD": "Este evento ainda não começou",
  "sailing.c-api-intl.[商家营销标签分类]_[菜品活动]": "Desconto em itens",
  "FoodC_store_Store_voucher_NnMx": "Cupom do estabelecimento resgatado!",
  "FoodC_education__2_jwSo": "(2) Deixar na porta (com uma mensagem)",
  "sailing.c-api-intl.[商家营销标签分类]_[满X减Y]": "Gaste X Economize Y",
  "FoodC_home_Possible_causes_wopU": "R: Veja abaixo algumas situações comuns e suas soluções.",
  "GDriver_5_Event_ended_gNeH": "Campanha encerrada. Tente na próxima!",
  "FoodC_c_Try_again_lIZQ": "Tentar novamente",
  "FoodB_optimization_See_more_rbCl": "Ver mais",
  "FoodC_page__Zjra": "Cupons",
  "FoodC_home_If_the_SPtR": "R: Se você usar um cupom {{entrega_name}} e não tiver concluído o pagamento até o momento do cancelamento do pedido, o cupom será devolvido ao seu perfil após o cancelamento. Se o pagamento já tiver sido concluído no momento do cancelamento, o cupom não será devolvido ao seu perfil e o pagamento do pedido será reembolsado na forma do pagamento original em até trinta dias.",
  "FoodC_Translation_Sign_in_pljT": "Inicie a sessão",
  "FoodC_statement_If_you_Jbkm": "Se você perceber que o item foi perdido ou danificado ou que ele está incompleto, ou caso o item errado tenha sido entregue, entre em contato com o canal de atendimento.",
  "FoodC_statement_3_Flash_IEYW": "3. Política de cancelamento",
  "FoodC_home_No_entrega_GnGI": "R: Não. Cupons {{entrega_name}} de um perfil só podem ser usados para pagar pedidos {{entrega_name}} desse mesmo perfil.",
  "FoodC_education_4_Customers_laxR": "4. Retire seu pedido em até 10 minutos após a chegada do(a) entregador(a) parceiro(a) ao local de entrega.",
  "FoodC_cancel_Users_initiate_suVB": "O cliente poderá fazer uma solicitação de cancelamento, mas ela não poderá ser retirada. O cancelamento será concluído assim que o entregador parceiro devolver o item. O pagamento em dinheiro para este pedido não será devolvido.",
  "FoodC_demand__1_LhGa": "(1) No momento do pedido, será perguntado o que você deseja fazer se um item estiver em falta, e o entregador parceiro seguirá suas instruções.",
  "FoodC_statement_pornography_WPQI": "Material pornográfico;",
  "soda-b-homepage-i18n---登录": "Entrar",
  "FoodC_statement_Value_Items_svYm": "Valor declarado: o valor do item não pode exceder {{value_limit}}",
  "sailing.act-api-intl.去使用": "Usar agora",
  "FoodC_title_Please_choose_yekD": "Selecione qualquer item para reportar",
  "FoodC_saved_nDkp_KPcN": "Descontos e ofertas",
  "FoodC_sales_Condition_of_lnwg": "A embalagem estava lacrada? (opcional)",
  "FoodC_Translation_Successful_exchange_BVbd": "Cupons {{coupon_name}} no carrinho.",
  "FoodC_home_3_If_BGBo": "3. Se você tiver dúvidas sobre os Termos de Uso ou o aplicativo {{brand_name}} e seus serviços, entre em contato com nossa equipe de Atendimento ao Cliente.",
  "FoodC_statement__4_bDLh": "(4) O destinatário deve conferir o item imediatamente ao recebê-lo para verificar suas condições e quantidade, se aplicável.",
  "FoodC_demand_1_What_aoLM": "1. O que é a Compra feita pelo entregador parceiro?",
  "FoodC_demand__You_bJcT": "* Pague em dinheiro: depois que o pedido for feito, o entregador parceiro comprará os itens solicitados e você pagará a ele em dinheiro quando a entrega chegar.",
  "FoodC_issues_Sorry_for_aHCW": "Estamos comprometidos em fornecer a melhor experiência possível. Se precisar de ajuda, fale conosco.",
  "FoodC_education__1_IIfh": "(1) Você cancela antes de o estabelecimento ter aceitado o pedido usando o recurso “Cancelar pedido”, na página Detalhes do pedido.",
  "FoodC_reasons_Enter_a_Pnzg": "Descreva o problema em detalhes",
  "FoodC_sales_How_do_ylCn": "Observe se o item foi lacrado",
  "FoodC_demand__2_TZJq": "(2) Se o entregador parceiro já tiver comprado um item e não estiver muito atrasado, você ainda precisará pagar pelo pedido caso decida cancelar.",
  "FoodC_home_No_When_xNrI": "R: Não. Ao usar um cupom {{entrega_name}}, o valor do cupom {{entrega_name}} será subtraído primeiro do total do pedido. Se o valor do cupom exceder o total do pedido, a diferença não poderá ser resgatada, reembolsada, recebida como troco, vendida, convertida ou repassada a outro usuário. Se o total do pedido exceder o valor do cupom, a diferença será paga por você.",
  "FoodC_education__4_QYXm": "(4) Você optou por dinheiro como forma de pagamento e se recusa a pagar na hora de retirar o pedido",
  "FoodC_statement__2_Gymp": "(2) Um período de cancelamento gratuito é fornecido após o entregador parceiro aceitar seu pedido e antes que o item seja coletado por ele; depois que esse período tiver decorrido, uma taxa de cancelamento será aplicada caso você decida cancelar;",
  "FoodC_education_In_the_MKeB": "Você não será responsabilizado por um cancelamento nas seguintes situações:",
  "FoodC_statement__1_hBNr": "(1) Depois que o entregador parceiro aceitar um pedido, o remetente deverá estar disponível para atender o telefone e estar presente no local de coleta para receber o entregador;",
  "FoodC_education__3_ltNM": "(3) Outros fatores fora do seu controle que causam o cancelamento do pedido, como quando não conseguimos encontrar um(a) entregador(a) parceiro(a) para entregá-lo ou outro problema da nossa parte.",
  "FoodC_statement__1_ZXJA": "(1) Para enviar um item, basta o remetente inserir no aplicativo os endereços de coleta e entrega, as informações de contato do remetente e do destinatário e os detalhes sobre o item;",
  "FoodC_statement_6_Lost_VmQE": "6. Regras para itens perdidos ou danificados",
  "FoodC_home_When_the_qNRR": "Se sua conexão de rede estiver instável, o pagamento pode não ser processado. Tente mudar para um local diferente com um sinal melhor e tente novamente;",
  "FoodC_home_What_are_TUik": "P: Quais são os requisitos para usar um cupom de novo cliente?",
  "FoodC_statement_Items_prohibited_vdcx": "Qualquer outro item proibido pela legislação e pelos Termos de Uso.",
  "FoodC_education_In_order_AZCw": "Estas regras de entrega foram elaboradas para ajudar todos os usuários a terem uma experiência justa e confiável ao usarem o aplicativo {{brand_name}}. No caso de um conflito relacionado à entrega, serão aplicadas as regras a seguir",
  "FoodC_home_Under_what_udiG": "P: Quais são as condições para usar cupons {{entrega_name}}?",
  "FoodC_sales_Not_set_AJmE": "Agora não",
  "FoodC_home_The_user_DZGn": "Você precisa ter um cupom {{entrega_name}} que possa ser usado na sua carteira no aplicativo;",
  "FoodC_education__2_KZTZ": "(2) O(A) entregador(a) parceiro(a) não consegue entrar em contato com você",
  "sailing.c-api-intl.推荐": "Recomendar",
  "FoodC_page__KoEd": "Mostrar mais",
  "FoodC_statement__6_cuGI": "(6) Se o entregador parceiro descobrir no momento da coleta que o item a ser entregue infringe esses requisitos e/ou os Termos de Uso, ele poderá cancelar o pedido e seu item não será entregue.",
  "FoodC_education_Cancellation_Policy_fxrl": "Política de cancelamento",
  "FoodC_statement_Currently_DiDi_jUEl": "{{entrega_name}} é um serviço de intermediação de entregas que permite que o usuário faça pedidos de entregas no aplicativo para que um outro usuário (entregador parceiro) colete e entregue um item a um destinatário específico. Depois que um entregador parceiro aceitar seu pedido, ele coletará o item no endereço especificado e o entregará para o destinatário escolhido.",
  "FoodC_description_The_entire_lTeJ": "O pedido inteiro não foi recebido:",
  "FoodC_statement__3_JHtK": "(3) Como solicitante/remetente, você é responsável por arcar com os custos associados ao pedido. Para pedidos pagos no aplicativo, o pagamento deve ser concluído no momento da realização do pedido; para pedidos pagos em dinheiro, o pagamento deve ser feito ao entregador parceiro no momento da coleta. O destinatário não é responsável por encargos ou taxas;",
  "FoodC_cancel_When_an_XxLF": "Para pagamentos no aplicativo, a taxa de cancelamento não excederá a taxa de entrega",
  "FoodC_statement_Dangerous_materials_tLOa": "Materiais ou resíduos perigosos (líquidos ou sólidos inflamáveis; gases comprimidos armazenados na forma liquefeita, não liquefeita ou dissolvida; substâncias tóxicas, infecciosas, radioativas ou corrosivas);",
  "FoodC_statement_Type_You_sJki": "Sobre o item: o {{entrega_name}} não pode ser usado para entregar itens proibidos, incluindo, entre outros, qualquer um dos seguintes:",
  "FoodC_statement__5_ZbOp": "(5) Se você perceber que o item foi perdido, danificado ou que ele está incompleto, ou caso o item errado tenha sido entregue, combine a compensação com o entregador parceiro. Se não for possível chegar a um acordo, entre em contato com o canal de atendimento.",
  "FoodC_home_When_placing_UpoJ": "P: Se eu cancelar um pedido no qual usei um cupom {{entrega_name}}, o cupom {{entrega_name}} será devolvido?",
  "FoodC_sales_Tips_LCST": "Lembrar opção?",
  "您的订单还未完成，您可以联系骑手确认当前的具体配送位置。": "Você tem um pedido em aberto que ainda não foi entregue. Caso precise verificar a localização atual do pedido, entre em contato com o entregador parceiro.",
  "您所在的地区还未开通服务": "O serviço ainda não está disponível na sua região",
  "可以把促销码换成钱吗？ ": "O código promocional pode ser convertido em dinheiro?",
  "不满意": "Não atende os critérios",
  "用户使用%s券应同时满足以下条件：": "Um cliente que utiliza cupons da %s deve cumprir todas as condições a seguir:",
  "怎样才能获得%s券？": "Como posso conseguir um cupom da %s?",
  "取消订单进度": "Status do cancelamento",
  "4、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "4. Os pedidos atrasados ou não entregues não darão direito a compensação nos seguintes casos:",
  "早间：%s-%s": "Manhã: %s-%s",
  "跳过": "Pular",
  "个人中心的“优惠券”": " Conta > Cupons.",
  "联系商家处理": "Entrar em contato com a loja",
  "用户的%s软件版本较低，可在升级%s软件版本后再进行支付。": "O cliente está usando uma versão muito antiga do software da %s. Tente atualizar para a versão mais recente e efetue o pagamento novamente.",
  "商家正在备餐": "A loja está preparando o pedido",
  "1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "1. A compensação por uma entrega atrasada é nossa maneira de pedir desculpas. Caso seu pedido não seja entregue até o horário estimado (exceto por pedidos cancelados), a %s dará a você um ou mais cupons gratuitos. Você pode vê-los após a conclusão do pedido, acessando",
  "申请信息": "Detalhes da solicitação",
  "返回首页": "Voltar para o início",
  "在极少数情况下，如果您对订单费用产生疑议，可以点击“帮助”联系我们，客服团队会尽快为您解答。": "No raro caso de uma contestação de tarifa, basta tocar em \"Ajuda\" para entrar em contato com a gente. Nossa Central de Atendimento ficará feliz em ajudar a resolver seu problema.",
  "我要注册！": "Eu quero me registrar!",
  "你的位置还不在我们服务范围内。我们很快就会扩大我们的覆盖范围，之后你就可以使用%s啦。请点击下面的按钮，我们会在有服务之后通知你。": "A %s ainda não está disponível na sua região, mas chegaremos já já! Pra ficar por dentro e ser informado é só tocar no botão abaixo. Quero saber em primeira mão!",
  "您对商家服务满意吗？": "Como você avalia este estabelecimento?",
  "现金": "Em dinheiro",
  "用户不符合%s券使用规则中列明的可以使用券的条件，应仔细阅读并保证符合优惠券注明的使用条件；": "O cliente não atende às condições de uso relacionadas nos termos de uso do cupom da %s. Os clientes devem ler os termos de uso com atenção para assegurar que cumprem as condições.",
  "%s券上会注明“有效期”，该优惠券仅能在注明的有效期内使用，过期优惠券将自动失效不可用。": "Os cupons da %s têm um “período de validade” especificado. Esses cupons podem ser usados apenas durante o período de validade especificado; após essa data, eles são automaticamente invalidados.",
  "将赔付给您1张%s优惠券": "Você recebe um cupom de %s de desconto",
  "1.店铺优惠指的是“满X减Y”活动。": "1. O desconto do estabelecimento refere-se às promoções \"Pague X, economize Y\".",
  "满%s可用": "acima de %s",
  "订单": "Pedido",
  "店铺优惠说明": "Detalhes do desconto do estabelecimento",
  "订单取消成功": "Pedido cancelado.",
  "目前没有限制。优惠券有使用限制，您可以查看下方优惠券规则，了解一天可以使用多少优惠券。": "Atualmente, não há restrições. Os cupons têm restrições de uso. Você pode ver as regras abaixo para saber quantos cupons podem ser usados em um mesmo dia.",
  "一个用户一天内最多能够使用几张%s券？": "Quantos cupons da %s o cliente pode usar por dia?",
  "仅限%s使用": "Somente para %s",
  "您对商家服务的评价": "Você avaliou este estabelecimento",
  "恶劣天气及运力紧张情况下，配送费根据实际情况略有浮动。": "As taxas de entrega podem variar dependendo de circunstâncias reais como condições climáticas severas ou capacidade operacional reduzida.",
  "进度详情": "Status da solicitação",
  "平台优惠": "Promoções da plataforma",
  "使用规则": "Termos de uso",
  "2.“满X减Y”和“特价菜”、“优惠套餐”活动优惠可同享。": "2. Os descontos de \"Pague X, economize Y\" podem ser combinados às promoções \"Item promocional\" e \"Combo\".",
  "反馈超时": "Espaço para solicitação expirado",
  "促销码与促销活动相关，有有效期，超过有效期会兑换失败。": "Como os códigos promocionais estão vinculados a ações promocionais, cada um tem uma data de validade. Não serão aceitas tentativas de resgate do cupom depois de sua expiração.",
  "可以用一个账号内的%s券为其他账号内的订单进行支付吗？": "Um cupom da %s de um perfil pode ser usado para pagar um pedido feito em outro perfil?",
  "获得权益": "Entrar",
  "我知道了": "OK",
  "查看已开通的区域": "Ver as regiões com serviço disponível",
  "提交": "Enviar",
  "您的问题我们已经收到，将在48小时内给您反馈，并以短信/邮件通知您，请耐心等待。": "Recebemos sua mensagem e responderemos por SMS ou e-mail dentro de 48 horas. Agradecemos sua paciência.",
  "小费金额 = 餐品总价 * 小费比例，你可以点击去选择你的小费比例。（餐品总价不包含配送费和优惠券）": "Valor da gorjeta: total do pedido × porcentagem da gorjeta (o total do pedido não inclui a taxa de entrega nem cupons)",
  "订单尚未完成": "Há um pedido em aberto",
  "补充更多信息，以便快速处理（选填）": "Forneça outros detalhes para ajudar a resolver o problema mais rapidamente (opcional)",
  "3.同一商家，最多可以有3个“满X比索减Y比索”活动，自动为您选择优惠力度最大的折扣。": "3. Até 3 cupons de desconto \"Pague X, economize Y\" podem ser resgatados por estabelecimento. As promoções de maior desconto são automaticamente utilizadas primeiro.",
  "更多商家即将上线，点击“请通知我”商家上线时我们将第一时间通知你": "Mais restaurantes estão se estabelecendo em breve. Clique em \"Notifique-me\" para receber notificações imediatamente quando novos restaurantes forem.",
  "用户应当出于合法、正当目的以合理方式使用%s券。用户不得单独或与商家恶意串通，利用%s券实施欺诈等非法行为以达到套取现金、获得积分或礼品等目的。且用户不得利用%s券进行售卖、兑换或从事其他营利性活动。如果平台发现用户存在前述违法违规行为，%s有权拒绝该用户使用%s券和/或对该用户账号和/或关联账号采取临时或永久停止平台服务等措施，并保留通过法律途径追究用户法律责任的权利。": "Os clientes devem usar os cupons da %s de forma razoável para fins adequados e legais. Os clientes não devem, individualmente ou em conluio com um estabelecimento, utilizar os cupons da %s para se envolver em fraudes ou outros atos ilegais, a fim de obter dinheiro, pontos, presentes ou outros benefícios. Os clientes também não podem usar os cupons da %s para realizar vendas, trocas ou outras atividades com fins lucrativos. Caso seja descoberto o envolvimento de um cliente em algum dos atos ilegais mencionados acima, a %s se reserva o direito de sustar o uso de cupons da %s e/ou impor um congelamento temporário ou permanente dos serviços da plataforma no perfil do cliente e/ou perfis relacionados. A %s também se reserva o direito de entrar com uma ação judicial contra o cliente.",
  "由于用户未实际支付%s券抵用部分订单金额，因此用户不能获得%s券抵用部分金额的发票。": "Como o cliente não está efetuando o pagamento da parte do valor do pedido coberta pelo cupom da %s, ele não pode obter um recibo oficial desse valor.",
  "本次服务评价成功": "Agradecemos sua resposta",
  "提交申请": "Solicitar cancelamento",
  "取消": "Cancelar",
  "请选择取消原因以便商家更快帮您处理": "Para que a loja processe sua solicitação mais rápido, selecione o motivo do cancelamento.",
  "钱包": "Carteira",
  "怎么样开通免密支付？": "Como faço para ativar os pagamentos sem senha? ",
  "什么是%s券？": "O que é um cupom da %s?",
  "谢谢。我们会及时通知你": "Obrigado! Lhe mantemos informado!",
  "退款金额": "Valor do reembolso",
  "通用支付": "Todos os tipos de pagamento",
  "促销码是字母和数字的组合。": "O código promocional é formado por letras e números.",
  "6、每位用户每天最多赔付6张优惠券；": "6. A compensação é limitada a 6 cupons por cliente, por dia.",
  "感谢您提前注册账号！我们将会及时为您发送最新的滴滴外卖活动信息。我们的服务即将开始，请拭目以待！": "Obrigado por se registrar com antecedência! Enviaremos as atividades mais recentes de Didi para entrega em tempo hábil. Nosso serviço começará em breve. Aguarde e veja!",
  "用户在开通%s服务的城市和/或地区下外卖订单；": "O cliente deve fazer o pedido em uma cidade/região onde os serviços da %s estão disponíveis.",
  "促销码由什么组成？": "Como é formado o código promocional?",
  "能导致%s券不能使用的原因和解决办法是什么？": "Em que circunstâncias um cupom da %s pode se tornar inutilizável e como é possível resolver o problema?",
  "请您上传所收到的全部商品照片、问题商品细节照片、商品小票照片，这将协助我们更快地处理您的问题。": "Para nos ajudar a processar sua solicitação mais rapidamente, envie fotos de: todos os itens que você recebeu; fotos com foco nos itens com problema; recibo dos itens.",
  "更多评价": "Mais avaliações",
  "99Pay-余额": "99Pay - Saldo",
  "详细说明": "Detalhes",
  "好的，我知道了": "OK",
  "4、部分时间段下单暂不参加赔付；": "4. Pedidos realizados durante determinados horários não dão direito a compensação;",
  "您的免配送费权益将于%s过期": "A assinatura de entrega gratuita expirará em %s",
  "提交反馈": "Enviar comentários",
  "帮助中心": "Ajuda",
  "6、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "6. Atente-se ao prazo indicado pelo entregador parceiro. Se um entregador parceiro alegar ter entregue um pedido antes de você recebê-lo, ligue para o Atendimento ao Cliente da %s (%s) para registrar uma reclamação",
  "获得新用户立减活动资格的新用户，是指第一次使用%s并成功完成订单的用户。新用户优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。": "Os clientes que usarem a %s pela primeira vez e conseguirem concluir um pedido estão qualificados para a promoção de desconto instantâneo para clientes novos. Para determinar se a recompensa de boas-vindas pode ser usada ao mesmo tempo para itens já com descontos do estabelecimento ou para itens já com desconto por gasto fixo, consulte os termos detalhados da promoção da %s.",
  "%s公里之内配送费": "Taxa de entrega até %s km",
  "我们很快会扩大服务范围，点击“请通知我”，我们将会在开通时通知你": "Ativaremos mais áreas de serviço em breve, clique em \"Notifique-me\" para receber notificações imediatamente quando o serviço for lançado.",
  "支付配送费": "Pagar taxa de entrega",
  "商家自行配送的配送费收取规则：": "Regras de cobrança da taxa de entrega do estabelecimento:",
  "促销码是什么？": "O que é um código promocional?",
  "仅限支付卡bin为%s可用": "Disponível apenas se o compartimento do cartão for %s",
  "请告诉我们，帮助我们一起改善": "Entre em contato e ajude-nos a melhorar",
  "基础配送费": "Taxa base de entrega",
  "当有配送费活动时，根据活动情况对配送费进行相应的优惠。": "Quando houver uma promoção de taxa de entrega, haverá um desconto de valor determinado na taxa de entrega, com base na promoção.",
  "申请取消原因": "Motivo do cancelamento",
  "打开": "Em",
  "查看评价": "Ver avaliações",
  "%s券可以找零或兑换吗？": "Posso receber troco ao usar os cupons da %s ou trocá-los por dinheiro?",
  "收起": "Ver menos",
  "5、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：": "5. Os pedidos atrasados ou não entregues não darão direito a compensação nos seguintes casos:",
  "优惠券": "Cupons",
  "不可用原因": "Motivo inválido",
  "(2) 因您的配送地址所在地不允许骑手入内，导致超时送达或无法送达的；": "(2) Se o endereço que você fornecer não permitir a entrada de entregadores-parceiros e isso resultar em atraso ou impossibilidade de entrega.",
  "网络不佳，请检查您的网络": "Problemas na conexão, verifique sua conexão de rede",
  "周五": "Sexta-feira",
  "您可以使用现金支付订单，请备好足够的零钱。": "Você pode pagar seu pedido em dinheiro. Separe o valor exato.",
  "周二": "Terça-feira",
  "最高抵扣%s": "Desconto máximo de %s",
  "特殊说明": "Observações especiais",
  "现金支付": "Pagamento em dinheiro",
  "已发起取消，等待商家确认": "Solicitação de cancelamento enviada. Aguardando confirmação da loja.",
  "3、恶劣天气（雨、雪等）暂不参加赔付；": "3. Atrasos causados por mau tempo (chuva, etc.) não dão direito a compensação.",
  "兑换优惠": "Resgatar cupom",
  "现在订餐": "Voltar",
  "如用户使用了%s券，取消订单时尚未完成支付，相关优惠券在用户取消订单后将即时退回账户。如用户取消订单时已完成支付，相关优惠券不再退回账户。订单款项将于订单取消后一周内原路退回支付账户。": "Se o cliente usar um cupom da %s e não tiver concluído o pagamento no momento do cancelamento, o cupom será devolvido à conta do cliente depois que o pedido tiver sido cancelado. Se o pagamento já tiver sido concluído no momento do cancelamento, o cupom não será devolvido à conta do cliente, mas o pagamento do pedido será devolvido em até uma semana à conta utilizada para pagamento.",
  "用户未违反平台外卖券使用规则，且该笔订单不属于平台判定的作弊或类似作弊订单等禁用%s券的情况。": "O cliente não pode violar os termos de uso do cupom da plataforma. O pedido também não pode ser considerado pela plataforma como fraudulento ou se enquadrar em outra condição que proíba o uso de cupons da %s.",
  "我们正在努力拓展范围。如果您希望 %s 的服务尽快到您身边，请告诉我们": "Estamos trabalhando bastante nessa expansão. Se você quiser a %s em sua área o mais breve possível, entre em contato conosco",
  "已经累计为您节省了%s配送费": "Você já economizou %s em taxas de entrega",
  "%s致力于通过无密码和现金支付流程为我们的客户提供简单安全的付款体验。": "A %s oferece opções de pagamento offline e online sem a necessidade de senha e fáceis de usar.",
  "用户兑换促销码的次数限制是多少？": "Quantas vezes o cliente pode resgatar um código promocional?",
  "配送费受多种因素影响，包括您和餐厅之前的距离，配送的最终地址和该地区的供需情况。": "Ela é calculada com base na distância entre o Restaurante e o local de entrega e pode sofrer variações por aumento da demanda.",
  "免配送费权益": "Assinatura de entrega gratuita",
  "%s平台配送费收取规则：": "Regras de cobrança da taxa de entrega da plataforma %s:",
  "不可以。一个账号内的%s券仅可用于支付该账号内的%s订单。": "Não. Os cupons da %s vinculados a um perfil só podem ser usados para pagar pedidos da %s feitos nesse mesmo perfil.",
  "部分餐品缺失- 表示骑手已经给您交餐，表示骑手已经给您交餐，但您有部分餐品没有收到。": "o entregador parceiro entregou o pedido, mas um ou mais itens não foram recebidos. ",
  "如果对费用提出争议，将如何解决？": "Se ocorrer uma contestação de tarifa, como ela será resolvida?",
  "（必填）请填写您的申诉原因": "(Obrigatório) Especifique o motivo da reclamação",
  "2、部分时间段下单暂不参加赔付；": "2. Pedidos realizados durante determinados horários não dão direito a compensação;",
  "很抱歉给你造成了不好的体验，请选择您遇到的问题：": "Lamentamos pela inconveniência. Selecione o problema que você encontrou: Parte do pedido estava faltando: ",
  "请通知我": "Notifique-me",
  "送您1张%s优惠券；": "daremos a você 1 cupom de %s de desconto.",
  "恭喜你！兑换成功！": "Parabéns, o cupom foi resgatado!",
  "用户绑定了银行卡或使用现金支付；": "O cliente deve ter um cartão bancário vinculado a seu perfil ou pagar em dinheiro.",
  "您的订单为商家配送，您可点击下方的联系商家按钮与商家进行问题协商。": "Essa entrega está sendo tratada pelo estabelecimento. Para obter mais informações sobre o status do seu pedido, toque no botão Entrar em contato com a loja.",
  "同一个促销码可以重复兑换么？": "Um mesmo código promocional pode ser resgatado várias vezes?",
  "%s券抵用餐费部分可以开发票吗？": "Posso obter um recibo oficial da parte do valor do pedido coberta por um cupom da %s?",
  "已过期券": "Cupons expirados",
  "有效期至": "Válido até",
  "他们已加入%s": "Eles estão na %s",
  "卡": "Cartão",
  "用户支付外卖订单时使用%s券并支付成功；": "Ao efetuar o pagamento de um pedido, o cliente deve usar o cupom da %s e concluir o pagamento.",
  "周四": "Quinta-feira",
  "一个外卖订单最多只能使用一张%s平台券。如：品类券与配送费券不可同时使用。": "Só é possível usar um cupom da plataforma %s para cada pedido. Por exemplo, um cupom de categoria e um cupom de taxa de entrega não podem ser usados ​​ao mesmo tempo.",
  "促销码有有效日期嘛？": "Os códigos promocionais têm data de validade?",
  "你可以在个人中心的“优惠券”或“钱包”-“优惠券”中兑换它。": "Você pode resgatá-lo em “Perfil” > “Cupons” ou “Carteira” > “Cupons”.",
  "%s平台处理": "Deixar a %s resolver",
  "由配送商家决定配送金额。": "O valor da entrega é determinado pelo estabelecimento.",
  "晚间：%s-%s": "Noite: %s-%s",
  "中查看": " ",
  "配送费详情": "Detalhes da taxa de entrega",
  "将赔付给您1张%s折扣券": "Você recebe um cupom de %s de desconto",
  "送您%s张免配送费券；": "daremos a você %s cupom de entrega gratuita.",
  "正在寻找骑手": "Localizando um entregador parceiro",
  "反馈部分餐品缺失": "Por favor, informe o que está faltando",
  "3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。": "3. Se você pagou seu pedido em dinheiro e está qualificado para receber o reembolso, ele será emitido para você na forma de um cupom adicionado à sua Carteira.",
  "优惠券使用规则": "Termos de uso do cupom",
  "验证成功": "Verificação bem sucedida",
  "%s券是%s发放给用户，用户在支付%s订单时，用于抵扣订单金额的电子券。": "O cupom da %s é um voucher eletrônico emitido pela %s para seus clientes, e pode ser usado para cobrir o valor de pedidos feitos na %s.",
  "提交成功": "Enviado",
  "用户在“钱包”中有可使用的%s券；": "A “Carteira” do cliente deve ter um Cupom da %s disponível para uso.",
  "延迟送达%s分钟以上": "Atraso superior a %s minutos",
  "您的小费将全部支付给骑手": "O entregador parceiro receberá o valor integral da gorjeta",
  "让 %s 进入我的地区": "Trazer a %s para minha região",
  "在支付方式中添加您的银行卡，成功绑定后即可开通免密支付。": "Os pagamentos sem senha serão ativados automaticamente quando você adicionar um cartão válido na opção “Adicionar forma de pagamento”.",
  "兑换失败": "Não foi possível resgatar",
  "我们正在努力拓展中。您可以访问周边热门商圈看看美食": "Estamos trabalhando bastante para expandir nosso alcance. Dê uma olhada nas opções de alimentação nas áreas comerciais próximas",
  "满意": "Satisfeito",
  "再等等": "Aguardar",
  "骑手正在配送中": "Entregador parceiro a caminho",
  "周六": "Sábado",
  "描述": "Descrição",
  "5、每位用户每天最多赔付6张优惠券；": "5. A compensação é limitada a 6 cupons por cliente, por dia.",
  "2.点击选择“浏览器”，进行下载": "2. Toque e selecione “navegador” para fazer download",
  "%s将不定时地通过在本页面发布更新版本的方式修改使用规则。请用户定期访问本页面并查看最新版本。这些规则对用户均具有约束力。": "A %s altera periodicamente os termos de uso postando uma atualização nesta página. Para ver a última atualização, os clientes devem visitar a página periodicamente. Os termos são vinculantes para todos os clientes.",
  "无效券": "Cupons inválidos",
  "/公里": "/km",
  "当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "Se um pedido não for entregue até o horário estimado (exceto por pedidos cancelados), a %s dará a você um ou mais cupons como pedido de desculpas. Após a conclusão do pedido, você pode vê-los acessando ",
  "出生日期": "Data de Nascimento",
  "餐费": "Preço do prato",
  "完成注册登录，以便开通服务后通知您": "Complete o cadastro e acesse seu perfil para podermos notificá-lo quando o serviço estiver disponível.",
  "不使用优惠券": "Não usar o cupom",
  "请上传商品和小票照片": "Envie fotos dos itens e o recibo do pedido",
  "商家优惠": "Promoções do estabelecimento",
  "你好": "Olá,",
  "CPF": "CPF",
  "附近商家正在筹备中": "Estabelecimentos próximos fazendo preparações",
  "失败": "Falhar",
  "验证失败": "Falha na verificação",
  "不可以。用户选择使用%s券支付订单时将优先使用%s券抵扣订单金额。券金额高于订单金额的，差额部分不可提现、退款、找零、买卖、兑现或转赠他人；订单金额高于券金额的，差额部分由用户支付。": "Não. Ao usar um cupom da %s, o valor do cupom será subtraído primeiro do total do pedido. Se o valor do cupom exceder o total do pedido, a diferença não poderá ser resgatada, reembolsada, recebida como troco, vendida, convertida ou repassada a outro cliente. Se o total do pedido exceder o valor do cupom, a diferença será paga pelo cliente.",
  "不可以，兑换出的优惠券可以根据优惠券的使用规则，抵扣部分订单费用。": "Não. O cupom resgatado pelo código promocional pode ser usado para cobrir uma parte da taxa do pedido, de acordo com os termos de uso do cupom.",
  "(1) 您要求更换收获地址、您提交的地址错误、联系不上您或无人收货、拒收等导致订单超时送达或无法送达的；": "(1) Se você solicitar uma mudança no endereço de entrega, se o endereço fornecido estiver incorreto, se não conseguirmos encontrar você, se não houver ninguém para receber o pedido ou se você recusar o recebimento, e qualquer uma dessas situações resultar em atraso ou impossibilidade de entrega.",
  "立即兑换": "Resgatar agora",
  "用户在进行订单支付时网络信号中断或不稳定，可稍后到网络信号稳定的地方再进行支付；": "O sinal de rede do cliente pode ter caído ou ficado instável ao efetuar o pagamento do pedido. Vá para um local com um sinal melhor para efetuar o pagamento novamente.",
  "服务费+里程费": "Cobrança de serviço + taxa por quilometragem",
  "什么是%s券的“有效期”？": "Qual é o “período de validade” de um cupom da %s?",
  "目前我们支持信用卡，借记卡的线上支付，以及现金和pos机的线下支付。": "No momento, aceitamos pagamentos online através de cartões de crédito ou débito, além de opções de pagamento offline, como dinheiro e maquininha. ",
  "OFF": "OFF",
  "下单时使用%s券，订单取消后，外卖券还会返还吗？": "Se eu cancelar um pedido no qual estou usando um cupom da %s, o cupom será devolvido?",
  "暂无优惠券": "Não há cupons no momento",
  "1.点击右上角菜单": "1. Toque no menu no canto superior direito",
  "联系骑手": "Fale com o parceiro",
  "去看看已开通地区": "Verificar áreas disponíveis",
  "在哪里可以兑换促销码？": "Onde posso resgatar meu código promocional?",
  "关闭": "Encerrado",
  "下载": "Baixar",
  "用户通过在线支付、现金支付的方式支付外卖订单，且用户领券时输入的手机号与实际下订单时使用账号登记的手机号为同一号码；": "O cliente deve pagar seus pedidos em dinheiro ou usando métodos de pagamento online. O número de telefone inserido pelo cliente ao resgatar o cupom deve ser o mesmo usado no cadastro do perfil em que o pedido foi feito.",
  "更多问题": "Mais perguntas",
  "pos机支付": "Pagamento na maquininha",
  "随时随地享美食": "Coma bem a qualquer hora, em qualquer lugar",
  "感谢你的支持，有新商家上线会第一时间通知你": "Agradecemos seu apoio. Notificaremos você assim que novo restaurante for instalado.",
  "周日": "Domingo",
  "去周边看看": "Verificar nas proximidades",
  "请选择有问题的商品": "Qual item veio errado?",
  "将赔付给您%s张免配送费券": "Você recebe %s cupom de entrega gratuita",
  "保持联系！": "Manteremos você atualizado",
  "处理结果将短信通知您": "Você receberá uma resposta via SMS",
  "周三": "Quarta-feira",
  "新用户优惠券的使用要求是什么？": "Quais são os requisitos para usar uma recompensa de boas-vindas?",
  "周一": "Segunda-feira",
  "商家配送": "Entrega do estabelecimento",
  "订单实际送达时间晚于预计送达时间%s分钟以上，": "Se o seu pedido for entregue mais de %s minutos após o horário estimado,",
  "取消订单": "Cancelar pedido",
  "您可以使用信用卡或储蓄卡通过pos机支付订单，请确保您的卡是可用的。": "Você pode usar um cartão de crédito ou débito para pagar seu pedido em uma maquininha. Confirme se seu cartão está válido.",
  "成功": "Sucesso",
  "您对配送服务的评价": "Você avaliou esta entrega",
  "输入兑换码": "Insira o código promocional",
  "展开": "Ver mais",
  "评价本次服务": "Avaliar a experiência",
  "兑换失败，请再试一次": "Erro. Tente novamente",
  "商家已开始制作美食，拒绝了您的取消订单申请": "Solicitação de cancelamento recusada pela loja porque o pedido já está sendo preparado. ",
  "已收到你的申请": "Sua inscrição foi recebida.",
  "7、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。": "7. Atente-se ao prazo indicado pelo entregador parceiro. Se um entregador parceiro alegar ter entregue um pedido antes de você recebê-lo, ligue para o Atendimento ao Cliente da %s (%s) para registrar uma reclamação.",
  "整个订单都没收到- 表示骑手没有为您配送订单，您没有收到任何餐品。": "O pedido não foi recebido: o entregador parceiro não entregou nenhum pedido. Para notificar o entregador parceiro para que ele possa concluir a entrega, toque em um dos botões abaixo.",
  "对处理结果不满意？": "Insatisfeito?",
  "抱歉，你所在的区域不支持配送": "Desculpe, a área em que você está não está disponível para o serviço de entrega no momento.",
  "2、超时赔付服务由平台为用户提供，用户没有支付任何费用，若订单取消则服务取消。": "2. Os cupons são oferecidos sem custo adicional. Entretanto, se você cancelar um pedido, não terá direito a recebê-los.",
  "隐藏": "Ocultar",
  "更多详情": "Mais informações",
  "app内可以支持哪些支付方式？": "Quais métodos de pagamento o aplicativo aceita?",
  "订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": "Se o seu pedido for entregue de %s a %s minutos após o horário estimado,",
  "促销码是由系统生成的，可以通过促销码兑换优惠券。": "Os códigos promocionais são gerados pelo sistema. Eles podem ser usados para resgatar cupons de desconto.",
  "有效期至%s": "Válido até: %s",
  "%s将不定期推出多样的优惠活动，如：新老用户满减优惠券、通过分享获得红包优惠券等，具体活动均将在本平台第一时间推送，或以短信形式联系您，您可随时关注相关活动。": "A %s promove periodicamente promoções de desconto, como descontos em gastos fixos para clientes novos/antigos, cupons pelo compartilhamento de conteúdo, etc. Você será notificado sobre ações específicas muito em breve, por esta plataforma ou por mensagem de texto.",
  "配送费计费规则": "Regras de cobrança da taxa de entrega",
  "已经收到你的申请": "Sua inscrição foi recebida",
  "仅限新用户使用": "Somente para novo cliente",
  "送您1张%s折扣券；": "daremos a você 1 cupom de %s de desconto.",
  "退款渠道": "Forma de reembolso",
  "配送费": "Taxa de entrega",
  "联系客服": "Fale conosco",
  "联系客服进行申诉": "Contate o Atendimento ao Cliente",
  "确认": "Confirmar",
  "4月7号起，滴滴外卖将在大阪开始试运营啦！您可以在滴滴外卖App上找到您喜欢吃的各式当地美食。我们的服务上线大促活动即将开始，快点击下方按钮，不错过我们的优惠活动！": "A partir de 7 de abril, Didi Takeaway começará a operação experimental em Osaka! Você pode encontrar todos os tipos de comidas locais que você gosta no aplicativo Didi takeaway. Nosso evento de promoção de lançamento de serviços está prestes a começar. Clique no botão abaixo e não perca nossa oferta especial!",
  "当前区域暂未开通服务": "Nenhum serviço disponível na região atual",
  "超时赔付规则说明": "Compensação por atraso na entrega",
  "如果用户对使用规则或在使用%s软件和/或相关服务过程中有任何疑问或需要任何帮助，请及时与%s客服联系，": "Se o cliente tiver alguma dúvida ou precisar de esclarecimento sobre os termos de uso ou sobre o uso do software da %s e/ou serviços relacionados, entre em contato imediatamente com o Atendimento ao Cliente da %s.",
  "支付信息": "Informação de pagamento",
  "平台配送": "Entrega da plataforma",
  "您为什么取消？": "Por que você cancelou?",
  "一个外卖订单最多能够使用几张%s券？": "Quantos cupons da %s podem ser usados em um único pedido? ",
  "(3) 其他因您的原因导致骑手超时送达或无法送达的。": "(3) Se quaisquer outros fatores atribuíveis diretamente a você causarem um atraso ou impedirem que o entregador parceiro faça a entrega.",
  "小费说明": "Sobre gorjetas para o parceiro",
  "更多地区入驻中……": "Mais áreas sendo incluídas...",
  "配送费收取标准": "Cobrança padrão da taxa de entrega",
  "%s~%s公里之间配送费": "Taxa de entrega entre %s e %s km ",
  "用户的外卖订单符合%s券券面注明的使用条件，如：餐品价格加餐具费满足该外卖优惠券所属品类、满减金额及有效使用时间限制；": "O pedido do cliente deve cumprir os termos de uso especificados no cupom da %s. Por exemplo: o preço do item e as taxas de utensílios devem atender aos requisitos de categoria, limite de gastos e período de validade do cupom da %s.",
  "超出%s公里部分收取标准": "Cobrança padrão acima de %s km",
  "可能的原因和解决办法如下：": "As possíveis causas e soluções são as seguintes:",
  "促销码分为可以兑换一次的和可以兑换多次的，实际可兑换次数以促销码活动规则为准。": "Alguns códigos promocionais podem ser resgatados apenas uma vez, e outros podem ser resgatados várias vezes. Para saber o número de resgates permitidos, consulte as regras da ação do código promocional.",
  "免费取消订单": "O primeiro cancelamento é gratuito",
  "可抵扣%s": "Aplicável para %s",
  "骑手没有配送我的订单": "O entregador parceiro não entregou meu pedido",
  "仅限%s": "%s",
  "3、您因超时赔付服务获得的优惠券，将发放至您的%s账户，请在": "3. Os cupons por entrega atrasada serão adicionados à sua conta da %s após a conclusão do pedido. Você pode vê-los acessando ",
  "我的优惠": "Meus cupons",
  "您可以点击下方按钮和骑手联系交餐。": "Você pode optar por entrar em contato com ele por conta própria ou deixar que a 99Food resolva.",
  "您总是能在点餐之前确认该订单的配送费。": "A taxa de entrega é sempre mostrada antes do pedido.",
  "特别提示: ": "Nota especial:",
  "POS": "Maquininha",
  "骑手已到达": "O entregador parceiro chegou",
  "大阪，出发！": "Osaka, vá!",
  "等待商家接单": "Aguardando confirmação do estabelecimento",
  "%s可用": "Disponível em %s",
  "商家正在入驻中": "Mais restaurantes a caminho",
  "1、超时赔付服务是%s为您免费提供的服务，延迟送达时间及赔付优惠券金额如下：": "1. Compensar você por uma entrega atrasada é a nossa maneira de pedir desculpas. Dependendo do tempo de atraso, emitiremos um ou mais cupons gratuitos para você:",
  "上报问题": "Relatar um problema",
  "您的订单完成已经超过24小时，无法自助申请，如您有问题可联系客服，给您带来不便深表歉意。": "Como seu pedido foi concluído há mais de 24 horas, você não pode mais fazer uma solicitação de reembolso direto. Se houver dúvidas, entre em contato com o Atendimento ao Cliente. Pedimos desculpas por qualquer inconveniente.",
  "商家拒绝取消申请": "Solicitação de cancelamento recusada pela loja",
  "联系商家": "Fale com o estabelecimento",
  "一个用户一天内使用的%s券数量之和不得超过两张，各优惠券是否可与商家的折扣菜/满减优惠同时享受，请用户关注%s各活动的详细规则。同一个账号、手机号、支付账号、设备以及其他相同或相似身份信息，均视为同一用户。": "O cliente pode usar no máximo dois cupons da %s em um só dia. Consulte os termos detalhados da promoção %s para saber se um determinado cupom pode ser usado ​​junto a itens promocionais do estabelecimento ou aos descontos de gastos fixos. Se alguma das informações de perfil a seguir (nome do perfil, número de telefone, conta para pagamento, dispositivo) e outras informações idênticas ou semelhantes corresponderem às de outro perfil, ambas serão atribuídas ao mesmo cliente.",
  "1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费不退还。 2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。": "1. Para reembolsos parciais, o valor será igual ao total dos itens do seu pedido para os quais você solicitou reembolso, menos os descontos de cupom proporcionais. A taxa de entrega não é reembolsável. 2. Para reembolso total, seu pagamento será reembolsado integralmente. Se você usou cupons no seu pedido, eles serão devolvidos à sua Carteira. ",
  "感谢你的支持，区域开通后会第一时间通知你": "Agradecemos seu apoio. Notificaremos você imediatamente assim que o serviço dessa região for lançado.",
  "在什么条件下可以使用%s券？": "Quando posso usar os cupons da %s?",
  "您对配送服务满意吗？": "Como você avalia esta entrega?",
  "温馨提示": "Lembrete"
}