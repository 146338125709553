/**
 * 覆盖翻译平台自动同步的数据，一般用于紧急文案缺失的问题，避免delay
 * 正常情况一定要按标准流程，让产品翻译平台提翻译需求
 *
 * 这里的 UI Key 是人为构造的，与翻译平台自动生成的保持一致便可
 * 为便于检索，可以统一以 _AAAA 后缀结尾
 */
export default {
  
}
